<template>
<div>
    <template v-for="(item, key) in userAccessJson">
        <v-list-group color="primary" :key="'G-' + key" no-action sub-group :value="false">
        <template v-slot:activator>
        <v-list-item-title>{{(rightLabel[key]) ? rightLabel[key].name : key }}</v-list-item-title>
        </template>
        <v-list-item v-for="(right, index) in item" :key="index" link @click="userAccessJson[key][index] = !userAccessJson[key][index]">
            <v-list-item-title v-if="key == 'TypesById' && (getCacheType(index.substring(0,3), index))">
                {{ getCacheType(index.substring(0,3), index).typ_name }}
            </v-list-item-title>
            <v-list-item-title v-else>{{(rightLabel[index]) ? rightLabel[index].name : index }}</v-list-item-title>
            <v-list-item-icon>
                <v-icon v-if="right" color='green'>mdi-check-outline</v-icon>
                <v-icon v-else color='red'>mdi-block-helper</v-icon>
            </v-list-item-icon>
        </v-list-item>
        </v-list-group>
    </template>
</div>
</template>

<script>
import { usrGetters }   from "@/store/user.module"
import { cacheGetters } from "@/store/cache.module"

export default {
    name: 'user_card',
    props: ['userAccessJson'],
    data: function () {
        return {
            saveProgress: false,         // Enregistrement en cours...
            rightLabel: {
                Generic: { name: 'Générique (par défaut)', icon: ''},
                Dashboard: { name: 'Tableau de bord', icon: ''},
                TrackingSheet: { name: 'Site', icon: ''},
                TrackingSheetEcology: { name: 'Site / ecologie', icon: ''},
                TrackingSheetDocuments:  { name: 'Site / Documents', icon: ''},
                TrackingSheetAdvice:     { name: 'Site / Avis', icon: ''},
                TrackingSheetEvent:      { name: 'Site / Évènements', icon: ''},
                TrackingSheetConvention: { name: 'Site / Convention', icon: ''},
                TrackingSheetLanddata:   { name: 'Site / Données foncières', icon: ''},
                TrackingSheetBilling:    { name: 'Site / Facturation', icon: ''},
                Map:                     { name: 'Carte', icon: ''},
                Documents:               { name: 'Documents', icon: ''},
                Billing:                 { name: 'Facturation', icon: ''},
                ReportInternal:          { name: 'Export interne', icon: ''},
                ReportExternal:          { name: 'Export externe', icon: ''},
                
                Report:              { name: 'Exports', icon: ''},
                ReportSite:          { name: 'Export par site', icon: ''},
                ReportSpecification: { name: 'Export cahier des charges', icon: ''},
                ReportSpecie:        { name: 'Export par espèces', icon: ''},
                ReportHabitat:       { name: 'Export habitat', icon: ''},
                ReportEvent:         { name: 'Export événements', icon: ''},
                ReportInvoice:       { name: 'Export Facture', icon: ''},
                ReportObeservatory:  { name: 'Export observatoire env.', icon: ''},
                ReportPraBv:         { name: 'Export Espèce PRA/BV', icon: ''},
                ReportAreaControl:   { name: 'Export Controle surface', icon: ''},

                Users:                   { name: 'Utilisateurs', icon: ''},
                Event:                   { name: 'Evénements', icon: ''},
                GanDocumentPolicy:       { name: 'Foncea Documents', icon: ''},
                GanPlotPolicy:           { name: 'Foncea Parcelles', icon: ''},
                TypePolicy:             { name: 'Gestion des types', icon: ''},
                StatePolicy:             { name: 'Gestion des états', icon: ''},
                TypesById:               { name: 'Droits sur type de fichier', icon: ''},

                before : { name: 'global', icon: ''},
                index  : { name: 'consulter liste', icon: ''},
                show   : { name: 'consulter', icon: ''},
                store  : { name: 'créer', icon: ''},
                update : { name: 'modifier', icon: ''},
                destroy: { name: 'supprimer', icon: ''},
                lastevent: { name: 'Dernier événements', icon: ''},
                lastsheet: { name: 'Dernieres fiches', icon: ''},
            }
        }
    },
    computed: {

    },
    mounted:function (){

    },
    methods: {
        isAuthorized: usrGetters.getScopeRight,
        roleLabels: usrGetters.getRolesLabels,
        getCacheType: cacheGetters.getTypes,
    },
    components: { }
}
</script>