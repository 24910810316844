<template>
<v-container fluid class="pa-0">
    <v-toolbar class="elevation-1" height="48px">
        <v-toolbar-title >Gestion des espèces</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="primary" outlined class="mr-2" to="/compensationsar">
            <v-icon left dark>mdi-eye</v-icon>
            <span class="hidden-sm-and-down">Par petite région agricole</span>
        </v-btn>
        <v-btn color="success" depressed class="white--text" to="/species/0">
            <v-icon left dark>mdi-plus-circle</v-icon>
            <span class="hidden-sm-and-down">Créer</span>
        </v-btn>
    </v-toolbar>
    <v-text-field
        append-icon="mdi-magnify" label="Recherche"
        solo clearable single-line hide-details v-model="search" @blur="saveSearchToCache()" class="ma-4 pa-0">
    </v-text-field>
    <v-card class="ma-4">
    <v-data-table v-bind:headers="headers" :items="items" item-key="sta_id" :search="search"
        items-per-page-text="Lignes par page" :items-per-page-options="pagination.rowsPerItem">
        <template v-slot:[`item.spg_name`]="{ item }">
            <v-chip small outlined label color="blue">{{ item.spg_name }}</v-chip>
        </template>
        <template v-slot:[`item.spe_typ_id`]="{ item }">
            <v-chip small outlined label :color="getCacheType('SPE', item.spe_typ_id).typ_color">{{ getCacheType('SPE', item.spe_typ_id).typ_name }}</v-chip>
        </template>
        <template v-slot:[`item.spe_typ_id_debt`]="{ item }">
            <v-chip small outlined label :color="getCacheType('DEB', item.spe_typ_id_debt).typ_color">{{ getCacheType('DEB', item.spe_typ_id_debt).typ_name }}</v-chip>
        </template>
        <template v-slot:[`item.spe_id`]="{ item }">
            <v-btn outlined small color="grey" class="ml-2 elevation-1" :href="'/#/species/' + item.spe_id + ''">
                    <v-icon left class="mr-2">mdi-pencil</v-icon>Modifier
            </v-btn>
        </template>
        <template slot="pageText" slot-scope="{ pageStart, pageStop }">
        De {{ pageStart }} à {{ pageStop }}
        </template>
    </v-data-table>
    </v-card>
</v-container>
</template>

<script>
import dateLocale from '../services/dateLocale';
import { cacheGetters, cacheMutations } from "../store/cache.module"

export default {
    name: 'species',
    props: [],
    data: function () {
        return {
            saveProgress: false,         // Enregistrement en cours...
            search: '',
            pagination: { totalItems: 0, rowsPerPage: 10, rowsPerItem: [15,25,35,{text:'Tout',value:-1}] },
            headers: [
            { text: 'Nom',                 align: 'left', value: 'spe_name'},
            { text: 'Groupe',              align: 'left', value: 'spg_name'},
            { text: 'Type',                align: 'left', value: 'spe_typ_id'},
            { text: 'Dette',               align: 'left', value: 'spe_typ_id_debt'},
            { text: 'Surface exigible',    align: 'right', value: 'spe_area_due'},
            { text: 'Linéaire exigible',   align: 'right', value: 'spe_linear_due'},
            { text: 'Modification',        align: 'right', value: 'spe_id' }
            ],
            items: [],
            locDateFormat: dateLocale,
            enableAdd: true,
            modalEvent : false,
            cacheTab: this.$storage.localStorage.get( this.$APP_COD + "_cache" )
        }
    },
    computed: {
        pages () {
            if (this.pagination.rowsPerPage == null || this.pagination.totalItems == null ) return 0
            return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
        }
    },
    mounted: function (){
        this.search = cacheGetters.getSearchBarItem('species') || ''
        this.getItems()
    },
    methods: {
        getItems(){
            this.$http.get( '/species?per_page=false' ).then( (response) => {
                this.items = response.data.data
            })
        },
        handlePage(index){
            this.pagination.limit = this.pagination.rowsPerPage * (index - 1)
        },
        getTypeName( typ_id ){
            if( this.cacheTab.types['SPE'] !== undefined && this.cacheTab.types['SPE'][typ_id] !== undefined ){
                return this.cacheTab.types['SPE'][typ_id].typ_name 
            } else {
                return 'non défini'
            }
        },
        saveSearchToCache(){
            cacheMutations.setSearchBarItem('species', this.search)
        },
        getCacheType: cacheGetters.getTypes,
        getCacheState: cacheGetters.getStates
    },
    components: {
        
    }
}
</script>