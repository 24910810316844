<template>
<v-container fluid>
<v-layout wrap>
    <v-flex xs6 style="max-width:100%" v-if="isAuthorized( 'ReportSite', 'show' )">
        <v-card class="elevation-1 ma-2">
            <v-toolbar flat dark color="green" >
                <v-toolbar-title><v-icon>mdi-file-excel</v-icon>Export par site</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-icon>mdi-download</v-icon>
            </v-toolbar>
            <v-container fluid grid-list-xl >
            <v-layout align-center wrap >
                <v-flex xs6>
                    <DatePicker :label="'Date de référence de l\'extraction'" :objectForm.sync="dateReport" fieldName='date_byconvention'></DatePicker>
                </v-flex>
                <v-flex xs6>
                    <v-autocomplete dense clearable class="ml-2 mr-2"
                        v-model="filter.trs_typ_id" :items="typSit" label="Maitrise foncière" 
                        item-text="typ_name" item-value="typ_id" multiple chips >
                        <template v-slot:selection="data">
                            <v-chip :input-value="data.selected" @click:close="removeChip('trs_typ_id', data.item.typ_id)" 
                                    close small class="chip--select-multi" :color="data.item.typ_color" >
                                {{ data.item.typ_name.match(/\b(\w)/g).join('.') }}
                            </v-chip>
                        </template>
                        <template slot="item" slot-scope="data">{{ data.item.typ_name }}</template>
                        <template v-slot:no-data>Aucune donnée</template>
                    </v-autocomplete>
                </v-flex>
                <v-flex xs6>
                    <v-autocomplete dense clearable class="ml-2 mr-2" 
                        v-model="filter.trs_sta_id" :items="staSit" label="Statut"
                        item-text="sta_label" item-value="sta_id" multiple chips >
                        <template v-slot:selection="data">
                            <v-chip :input-value="data.selected" @click:close="removeChip('trs_sta_id', data.item.sta_id)" 
                                    close small class="chip--select-multi" :color="data.item.sta_color" >
                                {{ data.item.sta_label.match(/\b(\w)/g).join('.') }}
                            </v-chip>
                        </template>
                        <template slot="item" slot-scope="data">
                            {{ data.item.sta_label }}
                        </template>
                        <template v-slot:no-data>
                            Aucune donnée
                        </template>
                    </v-autocomplete>
                </v-flex>
            </v-layout>
            </v-container>
            <v-divider class="mt-5"></v-divider>
            <v-card-actions>
                <!-- <v-checkbox v-model="checkState.withConvention" label="Uniquement avec convention" class="mt-0"></v-checkbox> -->
                <v-spacer></v-spacer>
                <v-btn rounded color="success" @click="downloadReport('sheetbyconvention', { dateRef: dateReport.date_byconvention, withConvention: checkState.withConvention, withtype: filter.trs_typ_id.join(), withstate: filter.trs_sta_id.join() }, 'get', 'export_sites')" :disabled="loading.getItem">
                    <v-icon left dark>mdi-content-save</v-icon>
                    <span class="hidden-sm-and-down">Telecharger</span>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-flex>
    <v-flex xs6 style="max-width:100%" v-if="isAuthorized( 'ReportSpecification', 'show' )">
        <v-card class="elevation-1 ma-2">
            <v-toolbar flat dark color="primary" >
                <v-toolbar-title><v-icon>mdi-file-excel</v-icon>Export par cahier des charges</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-icon>mdi-download</v-icon>
            </v-toolbar>
            <v-container fluid grid-list-xl >
            <v-layout align-center wrap >
                <v-flex xs12>
                    
                </v-flex>
            </v-layout>
            </v-container>
            <v-divider class="mt-5"></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn rounded color="success" @click="downloadReport('sheetbyspecification', {}, 'get', 'cahier_des_charges')" :disabled="loading.getItem">
                    <v-icon left dark>mdi-content-save</v-icon>
                    <span class="hidden-sm-and-down">Telecharger</span>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-flex>
    <v-flex xs6 style="max-width:100%" v-if="isAuthorized( 'ReportSpecie', 'show' )">
        <v-card class="elevation-1 ma-2">
            <v-toolbar flat dark color="teal" >
                <v-toolbar-title><v-icon>mdi-file-excel</v-icon>Export par espèces</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-icon>mdi-download</v-icon>
            </v-toolbar>
            <v-container fluid grid-list-xl >
            <v-layout align-center wrap >
                <v-flex xs12>
                    
                </v-flex>
            </v-layout>
            </v-container>
            <v-divider class="mt-5"></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn rounded color="success" @click="downloadReport('sheetbyspecies', {}, 'get', 'Espèces')" :disabled="loading.getItem">
                    <v-icon left dark>mdi-content-save</v-icon>
                    <span class="hidden-sm-and-down">Telecharger</span>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-flex>
<!--    <v-flex xs6 style="max-width:100%">
        <v-card class="elevation-1 ma-2">
            <v-toolbar flat dark color="primary" >
                <v-toolbar-title><v-icon>mdi-file-excel</v-icon>Sites (CODDT/MC2)</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-icon>mdi-download</v-icon>
            </v-toolbar>
            <v-container fluid grid-list-xl >
            <v-layout align-center wrap >
            </v-layout>
            </v-container>
            <v-divider class="mt-5"></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn rounded color="success" @click="downloadSite()" :disabled="loading.getItem">
                    <v-icon left dark>mdi-content-save</v-icon>
                    <span class="hidden-sm-and-down">Telecharger</span>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-flex>
-->
    <v-flex xs6 style="max-width:100%" v-if="isAuthorized( 'ReportEvent', 'show' )">
        <v-card class="elevation-1 ma-2">
            <v-toolbar flat dark color="primary" >
                <v-toolbar-title><v-icon>mdi-file-excel</v-icon>Évènements</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-icon>mdi-download</v-icon>
            </v-toolbar>
            <v-container fluid grid-list-xl >
            <v-layout align-center wrap >
                <v-flex xs12>
                <autoCompMulti :title="'Année'" ressource="events/yearofdates" :listObject.sync="filter.eve_date_start_year"
                    :listParent.sync="autoCompleteSource.was" :fields="{ index: 'eve_date_start_year', text:'eve_date_start_year', search:'eve_date_start_year', multiSelect:true, prefetch:true }"
                    class="ml-2 mr-2">
                </autoCompMulti>
                </v-flex>
                <!-- <v-flex xs12>
                    <v-autocomplete dense clearable class="ml-2 mr-2"
                        v-model="filter.eve_typ_id" :items="typEve" label="Type" 
                        item-text="typ_name" item-value="typ_id" multiple chips >
                        <template v-slot:selection="data">
                            <v-chip :input-value="data.selected" @click:close="removeChip('eve_typ_id', data.item.typ_id)" 
                                    close small class="chip--select-multi" :color="data.item.typ_color" >
                                {{ data.item.typ_name.match(/\b(\w)/g).join('.') }}
                            </v-chip>
                        </template>
                        <template slot="item" slot-scope="data">{{ data.item.typ_name }}</template>
                        <template v-slot:no-data>Aucune donnée</template>
                    </v-autocomplete>
                </v-flex> -->
                <!-- <v-flex xs6>
                    <v-menu ref="date_event_start" v-model="dateMenu.date_event_start"
                        :return-value.sync="dateReport.date_event_start"
                        :close-on-content-click="false" :nudge-right="40" transition="scale-transition" 
                        offset-y min-width="290px" style="z-index:1500">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field 
                                v-bind="attrs" :value="locDateFormat.formatLocale( dateReport.date_event_start, 'fr' )"
                                label="Date de référence de l'extraction" prepend-icon="mdi-calendar-today" readonly v-on="on" >
                            </v-text-field>
                        </template>
                        <v-date-picker locale="fr-fr" no-title scrollable v-model="dateReport.date_event_start">
                            <v-spacer></v-spacer>
                            <v-btn color="primary" @click="dateMenu.date_event_start = false">Annuler</v-btn>
                            <v-btn color="primary" @click="$refs.date_event_start.save( dateReport.date_event_start )">OK</v-btn>
                        </v-date-picker>
                    </v-menu>
                </v-flex>
                <v-flex xs6>
                    <v-menu ref="date_event_end" v-model="dateMenu.date_event_end"
                        :return-value.sync="dateReport.date_event_end"
                        :close-on-content-click="false" :nudge-right="40" transition="scale-transition" 
                        offset-y min-width="290px" style="z-index:1500">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field 
                                v-bind="attrs" :value="locDateFormat.formatLocale( dateReport.date_event_end, 'fr' )"
                                label="Date de référence de l'extraction" prepend-icon="mdi-calendar-today" readonly v-on="on" >
                            </v-text-field>
                        </template>
                        <v-date-picker locale="fr-fr" no-title scrollable v-model="dateReport.date_event_end">
                            <v-spacer></v-spacer>
                            <v-btn color="primary" @click="dateMenu.date_event_end = false">Annuler</v-btn>
                            <v-btn color="primary" @click="$refs.date_event_end.save( dateReport.date_event_end )">OK</v-btn>
                        </v-date-picker>
                    </v-menu>
                </v-flex> -->
            </v-layout>
            </v-container>
            <v-divider class="mt-5"></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn rounded color="success" 
                    @click="downloadReport( 'events', { start: filter.eve_date_start_year, end: filter.eve_date_start_year + '-12-31'}, 'get', 'evenements' )" :disabled="loading.getItem">
                    <v-icon left dark>mdi-content-save</v-icon>
                    <span class="hidden-sm-and-down">Telecharger</span>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-flex>
    <v-flex xs6 style="max-width:100%" v-if="isAuthorized( 'ReportInvoice', 'show' )">
        <v-card class="elevation-1 ma-2">
            <v-toolbar flat dark color="primary" >
                <v-toolbar-title><v-icon>mdi-file-excel</v-icon>Facture / convention</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-icon>mdi-download</v-icon>
            </v-toolbar>
            <v-container fluid grid-list-xl >
            <v-layout align-center wrap >
                    <v-menu ref="date_inv_start" v-model="dateMenu.date_inv_start"
                        :return-value.sync="dateReport.date_inv_start"
                        :close-on-content-click="false" :nudge-right="40" transition="scale-transition" 
                        offset-y min-width="290px" style="z-index:1500">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field 
                                v-bind="attrs" :value="locDateFormat.formatLocale( dateReport.date_inv_start, 'fr' )"
                                label="Date supérieure au :" prepend-icon="mdi-calendar-today" readonly v-on="on" >
                            </v-text-field>
                        </template>
                        <v-date-picker locale="fr-fr" no-title scrollable v-model="dateReport.date_inv_start">
                            <v-spacer></v-spacer>
                            <v-btn color="primary" @click="dateMenu.date_inv_start = false">Annuler</v-btn>
                            <v-btn color="primary" @click="$refs.date_inv_start.save( dateReport.date_inv_start )">OK</v-btn>
                        </v-date-picker>
                    </v-menu>
                    <v-menu ref="date_inv_end" v-model="dateMenu.date_inv_end"
                        :return-value.sync="dateReport.date_inv_end"
                        :close-on-content-click="false" :nudge-right="40" transition="scale-transition" 
                        offset-y min-width="290px" style="z-index:1500">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field 
                                v-bind="attrs" :value="locDateFormat.formatLocale( dateReport.date_inv_end, 'fr' )"
                                label="Date inférieure au :" prepend-icon="mdi-calendar-today" readonly v-on="on" >
                            </v-text-field>
                        </template>
                        <v-date-picker locale="fr-fr" no-title scrollable v-model="dateReport.date_inv_end">
                            <v-spacer></v-spacer>
                            <v-btn color="primary" @click="dateMenu.date_inv_end = false">Annuler</v-btn>
                            <v-btn color="primary" @click="$refs.date_inv_end.save( dateReport.date_inv_end )">OK</v-btn>
                        </v-date-picker>
                    </v-menu>
            </v-layout>
            </v-container>
            <v-divider class="mt-5"></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn rounded color="success" 
                    @click="downloadReport( 'invoicebyconvention', { start: dateReport.date_inv_start, end: dateReport.date_inv_end}, 'get', 'facture_convention' )" :disabled="loading.getItem">
                    <v-icon left dark>mdi-content-save</v-icon>
                    <span class="hidden-sm-and-down">Telecharger</span>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-flex>
    <v-flex xs6 style="max-width:100%" v-if="isAuthorized( 'ReportObeservatory', 'show' )">
        <v-card class="elevation-1 ma-2">
            <v-toolbar flat dark color="purple" >
                <v-toolbar-title><v-icon>mdi-file-excel</v-icon>Observatoire Environnemental</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-icon>mdi-download</v-icon>
            </v-toolbar>
            <v-container fluid grid-list-xl >
            <v-layout align-center wrap >
            <v-btn outlined color="success" @click="modalGrpSpe = true" :disabled="loading.getItem" class="ma-2">
                <v-icon left dark>mdi-tune-vertical</v-icon>
                <span class="hidden-sm-and-down">Configurer les groupes</span>
            </v-btn>
            </v-layout>
            </v-container>
            <v-divider class="mt-5"></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn rounded color="success" @click="downloadObservatory('observatory')" :disabled="loading.getItem">
                    <v-icon left dark>mdi-content-save</v-icon>
                    <span class="hidden-sm-and-down">Telecharger</span>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-flex>
    <v-flex xs6 style="max-width:100%" v-if="isAuthorized( 'ReportPraBv', 'show' )">
        <v-card class="elevation-1 ma-2">
            <v-toolbar flat dark color="deep-orange" >
                <v-toolbar-title><v-icon>mdi-file-excel</v-icon>Espece / PRA / BV</v-toolbar-title>
                <v-spacer></v-spacer>
            </v-toolbar>
            <v-container fluid grid-list-xl >
                <v-layout align-center wrap >
                    <DatePicker :label="'Date'" :objectForm="dateReport" fieldName='date_pra_bv'></DatePicker>
                </v-layout>
            </v-container>
            <v-card-actions>
                <v-checkbox v-model="specieSarDetail" label="Détail groupé" color="primary" value="detail_group" hide-details class="pl-2"></v-checkbox>
                <v-checkbox v-model="specieSarDetail" label="Détail CODDT" color="primary" value="detail_coddt" hide-details class="pl-2"></v-checkbox>
                <v-spacer></v-spacer>
                <v-btn rounded color="success" @click="downloadReport( 'speciewithsar', {datevalid: dateReport.date_pra_bv, detail: specieSarDetail}, 'get', 'espece_pra_bv' )" :disabled="loading.getItem">
                    <v-icon left dark>mdi-content-save</v-icon>
                    <span class="hidden-sm-and-down">Telecharger</span>
                </v-btn>                
            </v-card-actions>
        </v-card>
    </v-flex>
    <v-flex xs6 style="max-width:100%" v-if="isAuthorized( 'ReportAreaControl', 'show' )">
        <v-card class="elevation-1 ma-2">
            <v-toolbar flat dark color="blue-grey" >
                <v-toolbar-title><v-icon>mdi-file-excel</v-icon>Contrôle des surfaces</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-icon>mdi-download</v-icon>
            </v-toolbar>
            <v-container fluid grid-list-xl >
            <v-layout align-center wrap >
            </v-layout>
            </v-container>
            <v-divider class="mt-5"></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn rounded color="success" @click="downloadAreaControl()" :disabled="loading.getItem">
                    <v-icon left dark>mdi-content-save</v-icon>
                    <span class="hidden-sm-and-down">Telecharger</span>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-flex>
    <v-flex xs6 style="max-width:100%" v-if="isAuthorized( 'ReportRenewSpecification', 'show' )">
        <v-card class="elevation-1 ma-2">
            <v-toolbar flat dark color="indigo" >
                <v-toolbar-title><v-icon>mdi-file-excel</v-icon>Dates de renouvellements</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-icon>mdi-download</v-icon>
            </v-toolbar>
            <v-container fluid grid-list-xl >
            <v-layout align-center wrap >
                <v-flex xs6>
                    <v-autocomplete dense clearable class="ml-2 mr-2" 
                        v-model="filter.tcv_deadline" :items="endDate" label="Années"
                        item-text="tcv_deadline_year" item-value="tcv_deadline_year" multiple chips >
                        <template v-slot:selection="data">
                            <v-chip :input-value="data.selected" @click:close="removeChip('tcv_deadline', data.item.tcv_deadline_year)" 
                                    close small class="chip--select-multi" >
                                {{ data.item.tcv_deadline_year }}
                            </v-chip>
                        </template>
                        <template slot="item" slot-scope="data">
                            {{ data.item.tcv_deadline_year }}
                        </template>
                        <template v-slot:no-data>
                            Aucune donnée
                        </template>
                    </v-autocomplete>
                </v-flex>
                <v-flex xs6>
                    <v-checkbox v-model="onlyAquisition" label="Acquisition"></v-checkbox>
                </v-flex>
            </v-layout>
            </v-container>
            <v-divider class="mt-5"></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn rounded color="success" @click="downloadRenewalDates(onlyAquisition)" :disabled="loading.getItem">
                    <v-icon left dark>mdi-content-save</v-icon>
                    <span class="hidden-sm-and-down">Telecharger</span>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-flex>
    <v-flex xs6 style="max-width:100%" v-if="isAuthorized( 'ReportHabitat', 'show' )">
        <v-card class="elevation-1 ma-2">
            <v-toolbar flat dark color="orange" >
                <v-toolbar-title><v-icon>mdi-file-excel</v-icon>Export par habitat</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-icon>mdi-download</v-icon>
            </v-toolbar>
            <v-container fluid grid-list-xl >
            <v-layout align-center wrap >
                <v-flex xs12>
                    
                </v-flex>
            </v-layout>
            </v-container>
            <v-divider class="mt-5"></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn rounded color="success" @click="downloadReport('sheetbyhabitat', {}, 'get', 'habitat')" :disabled="loading.getItem">
                    <v-icon left dark>mdi-content-save</v-icon>
                    <span class="hidden-sm-and-down">Telecharger</span>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-flex>
    <v-flex xs6 style="max-width:100%" v-if="isAuthorized( 'ReportEvent', 'show' )">
        <v-card class="elevation-1 ma-2">
            <v-toolbar flat dark color="primary" >
                <v-toolbar-title><v-icon>mdi-file-excel</v-icon>Contrôles</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-icon>mdi-download</v-icon>
            </v-toolbar>
            <v-container fluid grid-list-xl >
            <v-layout align-center wrap >
                <v-flex xs12>
                <autoCompMulti :title="'Année'" ressource="events/yearofdates" :listObject.sync="filter.eve_date_control_year"
                    :listParent.sync="autoCompleteSource.was" :fields="{ index: 'eve_date_start_year', text:'eve_date_start_year', search:'eve_date_start_year', multiSelect:false, prefetch:true }"
                    class="ml-2 mr-2">
                </autoCompMulti>
                </v-flex>
            </v-layout>
            </v-container>
            <v-divider class="mt-5"></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn rounded color="success" 
                    @click="downloadReport( 'controls', { start: filter.eve_date_control_year + '-01-01', end: filter.eve_date_control_year + '-12-31'}, 'get', 'controles' )" :disabled="loading.getItem">
                    <v-icon left dark>mdi-content-save</v-icon>
                    <span class="hidden-sm-and-down">Telecharger</span>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-flex>
    <!-- <v-flex xs6 style="max-width:100%">
        <v-card class="elevation-1 ma-2">
            <v-toolbar flat dark color="grey" >
                <v-toolbar-title><v-icon>mdi-file-excel</v-icon>Espece / BV</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn rounded color="success" @click="downloadReport( 'speciewithwas' )" :disabled="loading.getItem">
                    <v-icon left dark>mdi-content-save</v-icon>
                    <span class="hidden-sm-and-down">Telecharger</span>
                </v-btn>
            </v-toolbar>
        </v-card>
    </v-flex>
    -->
</v-layout>
<v-dialog v-model="modalGrpSpe" max-width="1000" @keydown.esc="modalGrpSpe = false" :persistent="false">
    <v-card style="z-index:1100;">
    <v-toolbar color="primary" dark class="elevation-2 mb-2" height="50px">
        <v-icon class="mr-3">mdi-chevron-down</v-icon>
        <v-toolbar-title>Configurer les groupes</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon color="" @click.native="modalGrpSpe = false"><v-icon>mdi-close</v-icon></v-btn>
    </v-toolbar>
    <v-card-text class="pa-1">
    <v-layout wrap>
        <v-flex xs8 style="max-width:100%" class="pl-1">
        <v-toolbar color="green" dark class="elevation-2" height="50px">
            <v-icon class="mr-3">mdi-refresh</v-icon>
            <v-toolbar-title>Groupes</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field v-model="searchGroup" hide-details single-line clearable></v-text-field>
            <v-btn icon @click="createGroup()"><v-icon>mdi-plus</v-icon></v-btn>
        </v-toolbar>
        <v-list class="elevation-2" dense :expand="true">
            <draggable class="list-group" :list="speciesGroup" group="group">
            <v-list-group v-for="(item, index) in speciesGroup" :key="item.title" v-model="item.active" no-action class="elevation-1">
                <template v-slot:activator>
                    <v-list-item-content>
                        <v-list-item-title >
                            <b>{{index}} {{item.title}} : ({{ item.items.length }})</b>
                        </v-list-item-title>
                    </v-list-item-content>
                </template>
                <draggable class="list-group" :list="item.items" group="species" :style="(!item.items.length) ? 'height: 35px;' : ''">
                <v-list-item v-for="child in item.items" :key="child.title" style="cursor:pointer;">
                    <v-list-item-content class="mp-0" :style="(child.visibility) ? 'background-color:white;' : 'background-color:#EF9A9A;'">
                    <v-list-item-title v-text="child.spe_name" ></v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action class="ma-0">
                        <v-btn icon small @click="child.visibility = !child.visibility"><v-icon color="grey lighten-1">{{(child.visibility) ? 'mdi-delete' : 'mdi-check'}}</v-icon></v-btn>
                    </v-list-item-action>
                </v-list-item>
                </draggable>
                <v-divider ></v-divider>
            </v-list-group>
            </draggable>
        </v-list>
        </v-flex>
        <v-flex xs3 style="max-width:100%">
            <v-btn color="green" @click="saveSearchToCache()" class="ml-2 mt-2"><v-icon>mdi-save</v-icon>Enregistrer</v-btn>
            <v-btn color="primary" @click="getSpecies()" class="ml-2 mt-2"><v-icon>mdi-refresh</v-icon>Ré-initialiser</v-btn>
        </v-flex>
    </v-layout>
    </v-card-text>
    </v-card>
</v-dialog>
</v-container>
</template>

<script>
import dateLocale from '../services/dateLocale';
import { cacheGetters, cacheMutations } from "../store/cache.module"
import { trsObj }  from '../mixins/trs_obj.js'
import draggable from "vuedraggable"
import { usrGetters }   from "../store/user.module"
import autoCompMulti    from '@/components/Autocomplete_multi.vue'
import DatePicker from '@/components/ui/DatePicker'

export default {
    name: 'site_report',
    props: [], 
    mixins: [ trsObj ],
    components: {
        draggable, autoCompMulti, DatePicker
    },
    data: function () {
        return {
            isAuthorized: usrGetters.getScopeRight,
            modalGrpSpe: false,
            locDateFormat: dateLocale, // Format de date
            loading: { getItem: false },
            typSit: [],
            staSit: [],
            endDate: [],
            filter: {
                trs_sta_id: ['GEN01'], trs_typ_id: [], eve_typ_id: [], tcv_deadline: [], eve_date_start_year: []
            },
            autoComplete: {
                eve_date_start_year: null, 
                eve_date_control_year: null, 
            },
            autoCompleteSource:{
                was: [],
            },
            checkState: {
                withConvention: true,
            },
            dateMenu: { 
                date_byconvention   : null,
                date_byrenewal      : null,
                date_byspecification: null,
                date_event_start    : null,
                date_event_end      : null,
                date_inv_start    : null,
                date_inv_end      : null,
                date_pra_bv      : null,
            },
            dateReport : {
                date_byconvention   : null,
                date_byrenewal      : null,
                date_byspecification: null,
                date_event_start    : null,
                date_event_end      : null,
                date_inv_start    : null,
                date_inv_end      : null,
                date_pra_bv      : null,
            },
            items: [
                { name: 'Identification',  id: 'trs_id' }, 
                { name: 'Code mc2',        id: 'trs_mc2_code' }, 
                { name: 'Coddt' ,          id: 'trs_coddt' }, 
                { name: 'Nom du site' ,    id: 'trs_name' }, 
                { name: 'Ville',           id: 'tow_name'}, 
                { name: 'Surface validée', id: 'trs_area_specification' },
                { name: 'Distance ligne',  id: 'trs_rail_distance' },
            ],
            select: [
                { name: 'Identification',  id: 'trs_id' }, 
                { name: 'Coddt' ,          id: 'trs_coddt' }, 
                { name: 'Surface validée', id: 'trs_area_specification' },
                { name: 'Distance ligne',  id: 'trs_rail_distance' },
            ],
            searchGroup: '',
            speciesGroup: [],
            speciesRequest: [],
            state : cacheGetters.getTypesByTag('EVE', 'PRO'),
            specieSarDetail: '',
            onlyAquisition: false
        }
    },
    beforeMount:function (){
        this.speciesGroup = cacheGetters.getSearchBarItem('reportSpecies') || []
        if( !this.speciesGroup.length > 0 ){
            this.getSpecies()
        }
        this.typSit            = this.getCacheType('SIT') //.filter(item => item.typ_id !== 'SIT04') hide prospection
        this.staSit            = this.getCacheStates('GEN')
        this.typEve            = this.getCacheType('WRK')
        this.$http.get( '/trackingconvention/enddates/' ).then( (response) => { 
            this.endDate = response.data
        })
        this.dateReport.date_byconvention = new Date().toISOString().substr(0, 10)
        this.dateReport.date_pra_bv = new Date().toISOString().substr(0, 10)
    },
    methods: {
        getCacheType   : cacheGetters.getTypes,
        getCacheStates : cacheGetters.getStates,
        downloadReport(report, option, method, fileName){
            console.log(this.dateReport.date_byconvention)
            method               = method || 'get'
            fileName             = fileName || 'rapport'
            this.loading.getItem = true
            let searchCriteria   = this.getTrackingSheetFilter()
            for(let prop in option){
                if( option[prop] !== null && option[prop] !== undefined ){
                    searchCriteria += '&' + prop + '=' + option[prop]
                }
            }
            this.$http.customRequest({ method: method, url: '/report/' + report + '/' + searchCriteria, responseType: 'blob' } )
            .then( (response) => {
                this.$download(response.data, fileName + ".xlsx", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
                this.loading.getItem = false
            }).catch( (error) => {
                this.$root.$toast({ text: 'Erreur lors de l\'extraction !', error: error })
                this.loading.getItem = false
            })
        },
        downloadSite(){
            this.loading.getItem = true
            let searchCriteria = this.getTrackingSheetFilter()
            this.$http.customRequest({ method: 'get', url: '/report/trackingsheet/' + searchCriteria, responseType: 'blob' } )
            .then( (response) => {
                this.$download(response.data, "liste_site.xlsx", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
                this.loading.getItem = false
            }).catch( (error) => {
                this.$root.$toast({ text: 'Erreur lors de l\'extraction !', error: error })
                this.loading.getItem = false
            })
        },
        downloadObservatory(report){
            this.loading.getItem = true
            //let searchCriteria   = this.getTrackingSheetFilter()
            let data = [] //this.speciesGroup
            for (var i = 0; i < this.speciesGroup.length; i++) {
                if( this.speciesGroup[i].items && this.speciesGroup[i].items.length > 0 ){
                    this.speciesGroup[i].items.forEach( specie => {
                        if( specie.visibility ){
                            data.push({
                                spe_spg_id: i, spg_name: this.speciesGroup[i].title, spe_id: specie.spe_id, spe_name: specie.spe_name
                            })
                        }
                    })
                }
            }
            this.$http.customRequest({ method: 'post', url: '/report/' + report + '/', data: { species: data }, responseType: 'blob' } )
            .then( (response) => {
                this.$download(response.data, "rapport.xlsx", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
                this.loading.getItem = false
            }).catch( (error) => {
                this.$root.$toast({ text: 'Erreur lors de l\'extraction !', error: error })
                this.loading.getItem = false
            })
        },
        downloadAreaControl(){
            this.loading.getItem = true

            this.$http.customRequest({ method: 'get', url: '/report/areacontrol/', responseType: 'blob' } )
            .then( (response) => {
                this.$download(response.data, "controle_surfaces.xlsx", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
                this.loading.getItem = false
            }).catch( (error) => {
                this.$root.$toast({ text: 'Erreur lors de l\'extraction !', error: error })
                this.loading.getItem = false
            })
        },
        downloadRenewalDates(onlyAquisition){
            this.loading.getItem = true
            let dates = ( this.filter.tcv_deadline.length ) ? '?tcv_deadline_year=' + this.filter.tcv_deadline.join(',') : ''
            dates += (dates.length) ? '&onlyaquisition=' + onlyAquisition : '?onlyaquisition=' + onlyAquisition
            this.$http.customRequest({ method: 'get', url: '/report/specificationrenewal/' + dates, responseType: 'blob' } )
            .then( (response) => {
                this.$download(response.data, "dates_renouvellement.xlsx", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
                this.loading.getItem = false
            }).catch( (error) => {
                this.$root.$toast({ text: 'Erreur lors de l\'extraction !', error: error })
                this.loading.getItem = false
            })
        },
        createGroup(){
            this.speciesGroup.push( { title: this.searchGroup, active: true, action: false, items: [] } )
            this.searchGroup = ''
            this.$root.$toast({ text: 'Nouveau groupe' +  this.searchGroup + ' ajouté !' })
        },
        getSpecies(){
            this.speciesGroup = []
            let spe_spg_id = 0
            let groupId    = { group: 0, id: -1 }
            this.$http.get( '/species/?include=speciesGroup&per_page=false&order_by=spe_spg_id' ).then( (response) => {
                response.data.data.forEach(element => {
                    element.visibility = true
                    if( element.speciesGroup && element.spe_spg_id !== spe_spg_id ){
                        this.speciesGroup.push({
                            title: element.speciesGroup.data.spg_name, active: false, visibility: element.visibility, items: [ element ]
                        })
                        groupId['id']    = groupId['id'] + 1
                    } else if ( this.speciesGroup[ groupId['id'] ] ){
                        this.speciesGroup[ groupId['id'] ]['items'].push( element )
                    }
                    spe_spg_id = element.spe_spg_id
                })
            })
        },
        saveSearchToCache(){
            cacheMutations.setSearchBarItem('reportSpecies', this.speciesGroup)
            this.$root.$toast({ text: 'Configuration enregistrée' })
        },
        removeChip (fromFilter, id) {
            const index = this.filter[fromFilter].indexOf(id)
            if (index >= 0) this.filter[fromFilter].splice(index, 1)
        },
        //log(evt){
        //    console.log(evt)
        //}
    }
}
</script>