<template>
<v-container fluid class="pa-0">
    <v-toolbar class="elevation-1" height="48px">
        <v-btn icon href="/#/species/"><v-icon>mdi-arrow-left-thick</v-icon></v-btn>
        <v-toolbar-title class="pl-0">Objectif par petite région agricole</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
            <template v-slot:activator="{ on }">
                <v-btn rounded outlined depressed text @click="modalUpload = true" color="orange" class="mr-2 white--text" v-on="on">
                    <v-icon dark>mdi-cloud-upload</v-icon><span class="hidden-sm-and-down"></span>
                </v-btn>
            </template>
            <span>Injecter / modifier des compensations</span>
        </v-tooltip>
        <v-tooltip bottom>
            <template v-slot:activator="{ on }">
                <v-btn rounded outlined depressed text @click="downloadSarSpecies()" color="primary" class="mr-2 white--text" v-on="on">
                    <v-icon dark>mdi-file-excel</v-icon><span class="hidden-sm-and-down"></span>
                </v-btn>
            </template>
            <span>Télécharger la liste des compensations</span>
        </v-tooltip>
        <v-btn color="success" depressed class="white--text" to="/compensationsar/0">
            <v-icon left dark>mdi-plus-circle</v-icon>
            <span class="hidden-sm-and-down">Créer</span>
        </v-btn>
    </v-toolbar>
    <v-text-field
        append-icon="mdi-magnify" label="Recherche"
        solo clearable single-line hide-details v-model="search" @blur="saveSearchToCache()" class="ma-4 pa-0">
    </v-text-field>
    <v-card class="ma-4">
    <v-data-table v-bind:headers="headers" :items="items" item-key="sta_id" :search="search"
        items-per-page-text="Lignes par page" :items-per-page-options="pagination.rowsPerItem">
        <template v-slot:[`item.sar_name`]="{ item }">
            {{ (!item.sar_name) ? item.was_name : item.sar_name }}
        </template>
        <template v-slot:[`item.was_name`]="{ item }">
            <v-chip small outlined label :color="getCacheState('GES', item.csa_sta_id).sta_color">
                {{ getCacheState('GES', item.csa_sta_id).sta_label }}
            </v-chip>
        </template>
        <template v-slot:[`item.spg_name`]="{ item }">
            <v-chip small outlined label color="blue">{{ item.spg_name }}</v-chip>
        </template>
        <template v-slot:[`item.spe_typ_id`]="{ item }">
            <v-chip small outlined label>{{ getTypeName(item.spe_typ_id) }}</v-chip>
        </template>
        <template v-slot:[`item.spe_id`]="{ item }">
            <v-btn outlined small color="grey" class="ml-2 elevation-1" :href="'/#/compensationsar/' + item.csa_id + ''">
                    <v-icon left class="mr-2">mdi-pencil</v-icon>Modifier
            </v-btn>
        </template>
        <template slot="pageText" slot-scope="{ pageStart, pageStop }">
        De {{ pageStart }} à {{ pageStop }}
        </template>
    </v-data-table>
    </v-card>
    <!-- Upload / mise à jour des compensations -->
    <v-dialog v-model="modalUpload" max-width="900" >
        <v-card color="grey lighten-4" flat>
            <v-toolbar color="primary" dark class="elevation-2" height="50px">
                <v-icon class="mr-3">mdi-chevron-down</v-icon>
                <v-toolbar-title>Importer les compensations</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon color="" @click.native="modalUpload = false"><v-icon>mdi-close</v-icon></v-btn>
            </v-toolbar>
            <v-card-text>
                <v-layout row wrap>
                <v-flex xs10 class="pa-2">
                    <v-file-input ref="compensation" color="orange"
                        @change="getFile($event, 'compensation')" label="Selectionner un fichier"
                        v-model="form.file_compensation">
                    </v-file-input>
                </v-flex>
                <v-flex xs2 class="pa-2">
                    <v-btn color="green" class="mt-3" @click="sendCompensationFile('compensation')" :disabled="(!validUplodCompensation || saveProgress)">Envoyer</v-btn>
                </v-flex>
                </v-layout>
            </v-card-text>
        </v-card>
    </v-dialog>
</v-container>
</template>

<script>
import dateLocale from '../services/dateLocale';
import { cacheGetters, cacheMutations } from "../store/cache.module"

export default {
    name: 'speciesbysar',
    props: [],
    data: function () {
        return {
            saveProgress: false,         // Enregistrement en cours...
            search: '',
            pagination: { totalItems: 0, rowsPerPage: 10, rowsPerItem: [15,25,35,{text:'Tout',value:-1}] },
            headers: [
            { text: 'PRA/BV',              align: 'left', value: 'sar_name'},
            { text: 'Type',                align: 'left', value: 'was_name'},
            { text: 'Nom',                 align: 'left', value: 'spe_name'},
            { text: 'Group',               align: 'left', value: 'spg_name'},
            { text: 'Type',                align: 'left', value: 'spe_typ_id'},
            { text: 'Surface exigible',    align: 'right', value: 'csa_area_due'},
            { text: 'Linéaire exigible',   align: 'right', value: 'csa_linear_due'},
            { text: 'Modification',        align: 'right', value: 'spe_id' }
            ],
            items: [],
            locDateFormat: dateLocale,
            enableAdd: true,
            modalEvent : false,
            modalUpload: false,
            cacheTab: this.$storage.localStorage.get( this.$APP_COD + "_cache" ),
            form: { 
                file_compensation: null, file_compensation_name: '', file_compensation_source: '',
                ctt_typ_id: 'CTT01'
            },
        }
    },
    computed: {
        pages () {
            if (this.pagination.rowsPerPage == null || this.pagination.totalItems == null ) return 0
            return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
        },
        validUplodCompensation(){
            if( this.form.file_compensation_name.length > 0 ){
                return true
            } else {
                return false
            }
        }
    },
    mounted: function (){
        this.search = cacheGetters.getSearchBarItem('speciesbysar') || ''
        this.getItems()
    },
    methods: {
        getItems(){
            this.$http.get( '/compensationsar?per_page=false&extended=species' ).then( (response) => {
                this.items = response.data.data
            })
        },
        handlePage(index){
            this.pagination.limit = this.pagination.rowsPerPage * (index - 1)
        },
        downloadSarSpecies(){
            this.$http.customRequest({ method: 'get', url: '/report/compensationsar/?per_page=false&extended=species&order_by=spe_name&order_direction=asc', responseType: 'blob' } )
            .then( (response) => {
                this.$download(response.data, "rapport.xlsx", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
            })
        },
        getFile(file, scope){
            if( file !== undefined && this.form['file_' + scope + '_name'].length == 0 ){
                //let nameTab = file.name.split(".")
                //this.form['file_' + scope + '_name'] = nameTab[0]
                this.form['file_' + scope + '_name'] = file.name
            } else {
                this.form['file_' + scope + '_name'] = ''
            }
        },
        sendCompensationFile(scope) {
            // Enregistrement du formulaire
            this.saveProgress = true
            var formFile = new FormData()
            for (var prop in this.form) {
                if( prop == scope ){
                    formFile.append(prop, JSON.stringify( this.form[prop] ))
                } else {
                    formFile.append(prop, this.form[prop])
                }
            }
            if( this.form['file_' + scope] ) {
                formFile.append('file_' + scope, this.form['file_' + scope], this.form['file_' + scope].name)
            }
            this.$http.post( '/compensationsar/upload/', formFile )
            .then( (response) => {
                this.$root.$toast({ color: 'success', text: 'Données enregistrée !' })
                this.saveProgress = false
                this.modalUpload  = false
                for( let prop in response.data ){
                    if( Array.isArray( response.data[prop].error ) ){
                        this.processInfo = response.data[prop].error
                    }
                }
            }).catch( (error) => {
                this.$root.$toast({ text: 'Erreur lors de l\'enregistrement !', error: error })
                this.saveProgress = false
                this.modalUpload  = false
            })

        },
        getTypeName( typ_id ){
            if( this.cacheTab.types['SPE'] !== undefined && this.cacheTab.types['SPE'][typ_id] !== undefined ){
                return this.cacheTab.types['SPE'][typ_id].typ_name 
            } else {
                return 'non défini'
            }
        },
        saveSearchToCache(){
            cacheMutations.setSearchBarItem('speciesbysar', this.search)
        },
        getCacheState: cacheGetters.getStates
    },
    components: {
        
    }
}
</script>