var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-toolbar',{staticClass:"elevation-1",attrs:{"height":"48px"}},[_c('v-toolbar-title',[_vm._v("Liste des couches SIG")]),_c('v-spacer'),_c('v-btn',{staticClass:"white--text",attrs:{"rounded":"","to":"/layers/ajouter","color":"success","depressed":""}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v("mdi-plus-circle")]),_c('span',{staticClass:"hidden-sm-and-down"},[_vm._v("Ajouter")])],1)],1),_c('v-text-field',{staticClass:"ma-4 pa-0",attrs:{"append-icon":"mdi-magnify","label":"Recherche","solo":"","single-line":"","clearable":"","hide-details":""},on:{"blur":function($event){return _vm.saveSearchToCache()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-card',{staticClass:"ma-4"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"page":_vm.pagination.page,"items-per-page":_vm.pagination.itemsPerPage,"footer-props.items-per-page-text":"Lignes par page","hide-default-footer":""},on:{"update:page":function($event){return _vm.$set(_vm.pagination, "page", $event)},"page-count":function($event){_vm.pagination.pageCount = $event}},scopedSlots:_vm._u([{key:"item.lay_sta_id",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","outlined":"","color":( item.lay_sta_id == 'GEN01' ) ? 'green' : 'grey'}},[_vm._v(" "+_vm._s((_vm.cacheState['GEN'][item.lay_sta_id]) ? _vm.cacheState['GEN'][item.lay_sta_id].sta_label : '-')+" ")])]}},{key:"item.lay_typ_id",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","outlined":"","color":"blue"}},[_vm._v(_vm._s((_vm.cacheType['SPR'][item.lay_typ_id]) ? _vm.cacheType['SPR'][item.lay_typ_id].typ_name : '-'))])]}},{key:"item.lay_updated_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.locDateFormat.toLocale(item.lay_updated_at, { year: 'numeric', month: 'long', day: 'numeric' }))+" ")]}},{key:"item.lay_color",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","color":item.lay_color}})]}},{key:"item.lay_id",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"grey","outlined":"","to":'/layers/' + item.lay_id}},[_vm._v("Modifier")]),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"red","outlined":""},on:{"click":function($event){return _vm.deleteItem(item.lay_id)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('template',{slot:"no-data"},[_c('v-alert',{staticClass:"mt-2",attrs:{"value":true,"color":"info","icon":"mdi-information-outline"}},[_vm._v("Aucune données")])],1)],2)],1),_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{staticClass:"text-left pl-8",attrs:{"xs6":""}},[_c('span',[_vm._v(_vm._s(_vm.pagination.totalItems)+" résultats")])]),_c('v-flex',{staticClass:"text-right pr-0",attrs:{"xs6":""}},[_c('v-pagination',{attrs:{"length":_vm.pagination.pageCount,"total-visible":7},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }