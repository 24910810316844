<template>
<v-container fluid>
<v-layout wrap>
    <v-flex xs6 style="max-width:100%">
        <v-card class="elevation-1 ma-2" tile flat >
        <v-toolbar flat color="#f6f8f8" prominent height="50px">
            <v-toolbar-title>
                <v-btn icon><v-icon>mdi-format-list-checks</v-icon></v-btn>
                Informations globales
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon><v-icon>mdi-restore</v-icon></v-btn>
        </v-toolbar>
        <v-divider></v-divider>
        <v-layout wrap style="background-color:white;" class="pa-2 elevation-1">
            <v-flex xs12>
                    <v-list-item>
                        <v-list-item-content><v-list-item-title>Montant global prévisionnel</v-list-item-title></v-list-item-content>
                        <v-list-item-action><v-list-item-action-text>{{ ( Math.round(this.items[currentYear] * 10 / 10)) }}</v-list-item-action-text></v-list-item-action>
                    </v-list-item>
            </v-flex>
            <v-flex xs7 style="border-right:1px solid #E0E0E0;">
                    <v-list-item>
                        <v-list-item-content><v-list-item-title>Payé année N</v-list-item-title></v-list-item-content>
                        <v-list-item-action><v-list-item-action-text>{{ invoicePaid }}</v-list-item-action-text></v-list-item-action>
                    </v-list-item>
            </v-flex>
            <v-flex xs5 >
                    <v-list-item>
                        <v-list-item-content><v-list-item-title>% paiement</v-list-item-title></v-list-item-content>
                        <v-list-item-action><v-list-item-action-text>
                        {{ Math.round( ( invoicePaid / this.items[currentYear] * 100 ) * 10 / 10) }}
                        </v-list-item-action-text></v-list-item-action>
                    </v-list-item>
            </v-flex>
        </v-layout>
        </v-card>
    </v-flex>
    <v-flex style="max-width:100%">
        <v-card class="elevation-1 ma-2" tile flat >
            <v-toolbar flat color="#f6f8f8" prominent height="50px">
                <v-toolbar-title>
                    <v-btn icon><v-icon>mdi-information-outline</v-icon></v-btn>
                    Alertes facturation
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon>
                    <v-icon>mdi-alert</v-icon>
                </v-btn>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text>
                <v-alert :value="( infoContent['html'] == undefined )" type="info">Aucune alerte.</v-alert>
                <v-textarea solo v-if="( infoContent['html'] !== undefined )" v-model="infoContent.html" disabled></v-textarea>
            </v-card-text>
        </v-card>
    </v-flex>
    <v-flex xs6 style="min-width: 50%; max-width:100%;">
        <v-card class="elevation-1 ma-2" tile flat >
            <v-toolbar flat color="#f6f8f8" prominent height="50px">
                <v-toolbar-title>
                    <v-btn icon><v-icon>mdi-information-outline</v-icon></v-btn>
                    Avancement facturation
                </v-toolbar-title>
                <v-spacer></v-spacer>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text>
                <div class="small" style="min-height:400px;">
                    <apexchart v-if="chartView.area" width="100%" height=440 type="bar" :options="chartOptions" :series="series"></apexchart>
                </div>
            </v-card-text>
            <v-card-actions class="pa-0">
                <v-btn block color="blue-grey lighten-5" light href="/#/invoices">
                    <v-icon>mdi-eye-settings-outline</v-icon>&nbsp; Liste des factures
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-flex>
    <v-flex xs6 style="min-width: 50%; max-width:100%">
        <v-card class="elevation-1 ma-2" tile flat >
            <v-toolbar flat color="#f6f8f8" prominent height="50px">
                <v-toolbar-title>
                    <v-btn icon><v-icon>mdi-information-outline</v-icon></v-btn>
                    Montants
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon>
                    <v-icon>mdi-refresh</v-icon>
                </v-btn>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text style="min-height:440px;">
                <apexchart v-if="chartView.specie" width="100%" height=400 type="bar" :options="chartOptions2" :series="series2"></apexchart>
            </v-card-text>
        </v-card>
    </v-flex>
</v-layout>

</v-container>
</template>

<script>
import dateLocale from '../services/dateLocale'
import VueApexCharts from 'vue-apexcharts'
import apexLocale  from '@/plugins/locales'
import { cacheGetters } from "../store/cache.module"

export default {
    name: 'invoicesdashboard',
    props: [],
    data () {
        return {
            saveProgress: false,
            dialog: false,
            currentYear: new Date().getFullYear(),
            invoicePaid: 0,
            paginevent: { totalItems: 0 },
            alert: [], info : [],
            infoContent: {},
            locDateFormat: dateLocale, // Format de date
            stateItem: cacheGetters.getStates('SIN'),
            modalEvent : false,
            eveInfo: { eve_id: 0, eve_sta_id : 'EVE01', eve_table_id: 0, eve_xxx_id  : '' },
            items: {},
            chartView: { area: true, specie: false, spec: false, areaRenew: false },
            series: [{ name: 'Manquante', data: [44] }, { name: 'Refusée', data: [21] }, { name: 'Validée', data: [31] }],
            chartOptions: {
                chart: {
                    stacked: true, toolbar: { show: true }, zoom: { enabled: true },
                    locales: apexLocale.locales, defaultLocale: "fr"
                },
                responsive: [{
                    breakpoint: 480,
                    options: { legend: { position: 'bottom', offsetX: -10, offsetY: 0 } }
                }],
                plotOptions: { bar: { horizontal: false, }, },
                xaxis: { categories: ['2018'], },
                legend: {
                    position: 'right',
                    offsetY: 40
                },
                fill: {
                    opacity: 1
                }
            },
            series2: [ { name: 'compensation',  data: [] },  ],
            chartOptions2: {
                plotOptions: { bar: { horizontal: false, dataLabels: { position: 'top', }, } },
                dataLabels: {
                        enabled: false,
                        offsetX: -6,
                        style: { fontSize: '12px', colors: ['#fff'] }
                },
                stroke: { show: true, width: 2, colors: ['transparent'] },
                xaxis: { 
                    type: 'category',
                    categories: [],
                    labels: { 
                        hideOverlappingLabels: true,
                        rotate: -90,
                        style: {
                            colors: [],
                            fontSize: '9px',
                            fontFamily: 'Helvetica, Arial, sans-serif',
                            cssClass: 'apexcharts-xaxis-label',
                        },
                    }
                },
            },
        }
    },
    mounted:function (){
        this.fillData()
        this.getInvoices()
        setTimeout( () => {
            this.chartView.area = true
            setTimeout( () => {
                this.chartView.specie = true
                setTimeout( () => {
                    this.chartView.spec = true
                    setTimeout( () => {
                        this.chartView.areaRenew = true
                    }, 1000 )
                }, 1000 )
            }, 1000 )
        }, 1000 )
    },
    methods:{
        getInvoices(){
            this.items = {}
            var currentYearInvoice = {}
            this.stateItem.forEach(element => { 
                currentYearInvoice[element.sta_id] = { label: element.sta_label, amount_ht: 0 }
            })
            this.series = []
            this.series2[0].data = []
            this.chartOptions2.xaxis.categories = []
            var searchCriteria = '?per_page=false&extended=withtrackingsheet&order_by=inv_date&order_direction=asc'
            //searchCriteria    += ( this.filter.inv_sta_id.length > 0 ) ? '&inv_sta_id=' + this.filter.inv_sta_id.join(',') : ''
            this.$http.get( '/invoices/' + searchCriteria )
            .then( (response) => {
                if( Object.prototype.toString.call( response.data.data ) === '[object Array]' ) {
                    response.data.data.forEach(element => {
                        let year = parseInt(element.inv_date)
                        if( this.items[year] == undefined ){
                            this.items[year] = element.inv_amount_ht
                        } else {
                            this.items[year] += element.inv_amount_ht
                        }
                        if( year == this.currentYear && element.inv_date_payment !== null && element.inv_date_payment !== '0000-00-00' ){
                            this.invoicePaid += Math.round( element.inv_amount_ht * 10) / 10
                        }
                        if( year == this.currentYear ){
                            currentYearInvoice[element.inv_sta_id].amount_ht += element.inv_amount_ht
                        }
                    })
                }
                for( let prop in this.items ){
                    this.series2[0].data.push( Math.round( this.items[prop] * 10) / 10 )
                    this.chartOptions2.xaxis.categories.push(prop)
                }
                for( let prop in currentYearInvoice ){
                    let amount = Math.round( currentYearInvoice[prop]['amount_ht'] * 10 / 10 )
                    this.series.push({ name: currentYearInvoice[prop]['label'].substr(0,20), data: [ amount ] })
                }
            })
        },
        openEvent(id, table_id){
            this.eveInfo.eve_id     = id
            this.eveInfo.eve_xxx_id = table_id
            this.switchModal('modalEvent', 1)
        },
        switchModal(name, mode, refresh){
            name    = name || 'modalEvent'
            mode    = mode || 0
            refresh = refresh || false
            this[name] = mode
            if( refresh ){ 
                this.getEvent()
            }
        },
        fillData () {
            this.datacollection = {
            labels: [this.getRandomInt(), this.getRandomInt()],
            datasets: [
                {
                label: 'Donnée 1',
                backgroundColor: '#f87979',
                data: [this.getRandomInt(), this.getRandomInt()]
                }, {
                label: 'Donnée 2',
                backgroundColor: '#009688',
                data: [this.getRandomInt(), this.getRandomInt()]
                }
            ]
            }
        },
        getRandomInt () {
            return Math.floor(Math.random() * (20 - 5 + 1)) + 5
        }
    },
    components: {
        //LineChart
        'apexchart': VueApexCharts
    }
}
</script>

<style>
  .small {
    max-width: 600px;
    /*margin:  150px auto;*/
  }
</style>