<template>
<v-container fluid class="pa-0">
    <v-toolbar class="elevation-1" height="48px">
        <v-toolbar-title >Gestion des conventions</v-toolbar-title>
        <v-spacer></v-spacer>
    </v-toolbar>
    <v-text-field
        append-icon="mdi-magnify" label="Recherche"
        solo clearable single-line hide-details v-model="search" @blur="saveSearchToCache()" class="ma-4 pa-0">
    </v-text-field>
    <v-card class="ma-4">
    <v-data-table v-bind:headers="headers" :items="items" item-key="sta_id" :search="search"
        items-per-page-text="Lignes par page" 
        :items-per-page-options="pagination.rowsPerItem">
        <template slot="pageText" slot-scope="{ pageStart, pageStop }">
        De {{ pageStart }} à {{ pageStop }}
        </template>
    </v-data-table>
    </v-card>
</v-container>
</template>

<script>
import dateLocale from '../services/dateLocale';
import {  cacheMutations } from "../store/cache.module"

export default {
    name: 'states',
    props: [],
    data: function () {
        return {
            saveProgress: false,         // Enregistrement en cours...
            search: '',
            pagination: { totalItems: 0, rowsPerPage: 10, rowsPerItem: [15,25,35,{text:'Tout',value:-1}] },
            headers: [
            { text: 'Id', align: 'left', value: 'tcv_id'},
            { text: 'Nom', align: 'left', value: 'tcv_trs_id'},
            { text: 'Type', value: 'tcv_typ_id_update' },
            { text: 'Statut', value: 'tcv_date' },
            { text: 'Modification', align: 'right', value: 'tcv_update_date' }
            ],
            items: [],
            locDateFormat: dateLocale,
            enableAdd: true,
            modalEvent : false
        }
    },
    computed: {
        pages () {
            if (this.pagination.rowsPerPage == null || this.pagination.totalItems == null ) return 0
            return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
        }
    },
    mounted: function (){
        //this.search = cacheGetters.getSearchBarItem('convention') || ''
        this.getConvention()
    },
    methods: {
        getConvention(){
            this.$http.get( '/trackingconvention?per_page=false' ).then( (response) => {
                this.items = response.data.data
            })  
        },
        saveSearchToCache(){
            cacheMutations.setSearchBarItem('type', this.search)
        },
    },
    components: {
        
    }
}
</script>