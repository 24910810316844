<template>
<v-app id="login" light>
  <v-layout style="margin-top:1em;">
    <v-flex v-if="!splashScreen">
      <v-card class="elevation-1 pa-2 mx-auto" style="max-width: 360px;">
        <v-img :src='images.logoSmall' class="mx-4" alt='logo' max-height="120" aspect-ratio="1"></v-img>
        <v-card-title primary-title>
          <div v-if="oAuth" style="width:100%;">
            <v-form v-model="valid">
              <v-text-field class="pt-1 login-input" @focus="scrollUp()"
                @keyup.enter="passFocus()"
                label="Identifiant" v-model="formData.login"
                prepend-icon="mdi-account"
                :rules="nameRules" required lazy-validation>
              </v-text-field>
              <v-text-field class="pt-1"
                ref="passwordField"
                label="Mot de passe" 
                @focus="scrollUp()"
                @keyup.native.enter="connect()"
                v-model="formData.password"
                :type="'password'"
                prepend-icon="mdi-lock"
                :error-messages="errorMessage"
                :error="badAuth">
              </v-text-field>
              <v-btn :outlined="!formData.persist" :color="(formData.persist) ? 'primary' : 'grey'" 
                class="ml-0 mr-0 pa-1" style="min-width: 32px" @click="formData.persist = !formData.persist">
                <v-icon v-show="formData.persist">mdi-check-bold</v-icon>
              </v-btn>
              <v-btn text color="grey" @click="formData.persist = !formData.persist" class="ml-0">Se souvenir de moi</v-btn>
            </v-form>
          </div>
        </v-card-title>
        <v-card-actions>
          <v-btn class="elevation-1" 
            v-if="!loginControl && oAuth"
            :disabled="(formData.password.length == 0 || formData.login.length == 0)"
            @click="connect()" color="primary" light block>
            Se connecter
          </v-btn>
          <v-progress-linear v-if="loginControl" :indeterminate="true"></v-progress-linear>
          <v-btn v-if="!loginControl && !oAuth" class="elevation-1" 
            :href="samlRedirect" color="primary" light block>
            Cliquez ici pour vous connecter
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
    <v-flex v-if="splashScreen" class="mt-1">
      <v-row align="center" justify="center">
        <v-img :src='images.logoSmall' alt='logo' max-height="150" contain></v-img>
      </v-row>
      <div class="text-center text-h2 font-weight-bold mt-9">Bienvenue sur <br> Compensea</div>
      <v-progress-linear v-if="splashScreen" :indeterminate="true"></v-progress-linear>
    </v-flex>
  </v-layout>
</v-app>
</template>

<script>
import { actions, state } from "../store/auth.module"
import { cacheActions } from "../store/cache.module"

export default {
  name: 'login',
  data () {
    return {
      checkAuth: false,
      badAuth: false,
      errorMessage: [],
      formData : {
        login: '',
        password: '',
        persist: false,
        _path: 'auth',
        _method: 'GET'
      },
      valid: false,
      nameRules: [ (v) => !!v || 'Identifiant obligatoire' ],
      passRules: [ (v) => !!v || 'Pass obligatoire' ],
      images: {
        logoSmall: require('../assets/logo_small.svg')
      },
      oAuth: false,
      splashScreen: false
    }
  },
  computed: {
      loginControl() {
        return state.authenticating
      },
      samlRedirect() {
        const uri = `?RelayState=${encodeURIComponent( process.env.VUE_APP_URL + '#/login' )}`
        let samlIpd = process.env.VUE_APP_SAML_IDP || 'vinci'
        return process.env.VUE_APP_API_URL + 'saml2/' + samlIpd + '/login' + uri
      }
  },
  mounted:function (){
      if( ( this.$route.params.authcode ) && this.$route.params.authcode.length > 3 ){
        actions.login({login: this.formData.login, password: this.$route.params.authcode, grant_type: 'password'}) //authorization_code
      } else if ( process.env.VUE_APP_OAUTH_IDP === 'true' ) {
        this.oAuth = true
      }
  },
  methods: {
    connect() {
      cacheActions.initCacheFromStorage().then( () => { 
        this.onSubmit()
      })
    },
    login(){
      return actions.login()
    },
    async onSubmit() {
      if (this.formData.login != '' && this.formData.password != '') {
        let loginIn
        loginIn = await actions.login({login: this.formData.login, password: this.formData.password}, false)
        this.formData.password = ""
        if( loginIn ){
          this.splashScreen = true
          setTimeout( () => {
            this.$router.push(this.$router.history.current.query.redirect || '/')
          }, 2500)
        } else {
          this.badAuth = true
          this.errorMessage.push('Login ou mot de passe erroné')
          setTimeout( () => {
            this.badAuth = false
            this.errorMessage = []
          }, 2500)
        }
      }
    },
    passFocus(){
      this.$refs.passwordField.focus()
    },
    scrollUp(){
      var el = this.$el.getElementsByClassName("login-input")[0];
      el.scrollIntoView();
    }
  },
  components: { }
}
</script>

<style lang="css">
/* Personnalisation code */
.login-container {
  transform: translate(0, -50px);
  padding-top: 2em;
}
.login-paper {
  width: 24em;
  text-align: center;
  margin-right: auto;
  margin-left: auto;
}
.login-logo {
  margin-bottom: 1.2em;
  padding-top: 0.5em;
}

</style>