import Vue from "vue";

export const scopeCredential = Vue.observable({
    Generic: {
        index:      ['ADMIN_USER', 'SUPER_USER', 'INTER_USER'],
        show:       ['ADMIN_USER', 'SUPER_USER', 'INTER_USER'],
        create:     ['ADMIN_USER', 'SUPER_USER', 'INTER_USER'],
        store:      ['ADMIN_USER', 'SUPER_USER', 'INTER_USER'],
        update:     ['ADMIN_USER', 'SUPER_USER', 'INTER_USER'],
        destroy:     ['ADMIN_USER', 'SUPER_USER', 'INTER_USER'],
        lastsheet:  ['ADMIN_USER', 'SUPER_USER', 'INTER_USER'],
        lastevent:  ['ADMIN_USER', 'SUPER_USER', 'INTER_USER'],
    },
    Billing: {},
    dashboard: {
        read:        ['ADMIN_USER', 'SUPER_USER', 'INTER_USER', 'EXTER_USER'],
        edit:        ['ADMIN_USER', 'SUPER_USER', 'INTER_USER'],
        lastsheet:   ['ADMIN_USER', 'SUPER_USER', 'INTER_USER'],
        lastevent:   ['ADMIN_USER', 'SUPER_USER', 'INTER_USER'],
    },
    event     : {},
    eventedit : {
        read:   ['ADMIN_USER', 'SUPER_USER', 'INTER_USER', 'EXTER_USER'],
        edit:   ['ADMIN_USER', 'SUPER_USER', 'INTER_USER'],
        delete: ['ADMIN_USER', 'SUPER_USER']
    },
    trackingsheet     : {},
    trackingsheetedit : {
        read:   ['ADMIN_USER', 'SUPER_USER', 'INTER_USER', 'EXTER_USER'],
        edit:   ['ADMIN_USER', 'SUPER_USER', 'INTER_USER'],
        delete: ['ADMIN_USER', 'SUPER_USER']
    },
    map : {},
    document : {
        read:   ['ADMIN_USER', 'SUPER_USER', 'INTER_USER', 'EXTER_USER'],
        edit:   ['ADMIN_USER', 'SUPER_USER', 'INTER_USER'],
        delete: ['ADMIN_USER', 'SUPER_USER', 'INTER_USER']
    },
    invoicesdashboard : {
        read: ['ADMIN_USER', 'SUPER_USER', 'INTER_USER']
    },
    invoices : {
        read: ['ADMIN_USER', 'SUPER_USER', 'INTER_USER']
    },
    report : {},
    reportext : {},
    // Params
    sitesedit: {},
    layers: {},
    layeredit: {},
    users: {
        read: ['ADMIN_USER', 'ADMIN_FONCEA_USER', 'SUPER_USER', 'INTER_USER']
    },
    useredit: {
        read: ['ADMIN_USER', 'ADMIN_FONCEA_USER', 'SUPER_USER', 'INTER_USER'],
        edit: ['ADMIN_USER', 'ADMIN_FONCEA_USER', 'SUPER_USER', 'INTER_USER']
    },
    habitat: {
        read: ['ADMIN_USER', 'SUPER_USER', 'INTER_USER'],
        edit: ['ADMIN_USER', 'SUPER_USER', 'INTER_USER']
    },
    habitatedit: {
        read: ['ADMIN_USER', 'SUPER_USER', 'INTER_USER'],
        edit: ['ADMIN_USER', 'SUPER_USER', 'INTER_USER']
    },
    indexation: {
        read: ['ADMIN_USER', 'SUPER_USER', 'INTER_USER'],
        edit: ['ADMIN_USER', 'SUPER_USER', 'INTER_USER']
    },
    indexationedit: {
        read: ['ADMIN_USER', 'SUPER_USER', 'INTER_USER'],
        edit: ['ADMIN_USER', 'SUPER_USER', 'INTER_USER']
    },
    sar: {},
    saredit: {
        read: ['ADMIN_USER', 'SUPER_USER', 'INTER_USER'],
        edit: ['ADMIN_USER', 'SUPER_USER', 'INTER_USER']
    },
    speciesgroup: {},
    speciesgroupedit: {
        read: ['ADMIN_USER', 'SUPER_USER', 'INTER_USER'],
        edit: ['ADMIN_USER', 'SUPER_USER', 'INTER_USER']
    },
    species: {},
    speciesedit: {
        read: ['ADMIN_USER', 'SUPER_USER', 'INTER_USER'],
        edit: ['ADMIN_USER', 'SUPER_USER', 'INTER_USER']
    },
    watershed: {},
    watershededit: {
        read: ['ADMIN_USER', 'SUPER_USER', 'INTER_USER'],
        edit: ['ADMIN_USER', 'SUPER_USER', 'INTER_USER']
    },
    states: {},
    stateedit: {
        read: ['ADMIN_USER', 'SUPER_USER'],
        edit: ['ADMIN_USER', 'SUPER_USER']
    },
    types: {},
    typeedit: {
        read: ['ADMIN_USER', 'SUPER_USER'],
        edit: ['ADMIN_USER', 'SUPER_USER']
    },
    contacts: {},
    contacts_view: {},
    synchro: {
        read: ['ADMIN_USER', 'SUPER_USER'],
        edit: ['ADMIN_USER', 'SUPER_USER']
    },

    foncea     : {
        read:   ['ADMIN_USER', 'ADMIN_FONCEA_USER', 'SUPER_FONCEA_USER', 'MANAGER_FONCEA_USER', 'VIEW_FONCEA_USER'],
        store:  ['ADMIN_USER', 'ADMIN_FONCEA_USER', 'SUPER_FONCEA_USER' ],
        edit:   ['ADMIN_USER', 'ADMIN_FONCEA_USER', 'SUPER_FONCEA_USER', 'MANAGER_FONCEA_USER' ],
        delete: ['ADMIN_USER', 'ADMIN_FONCEA_USER', 'SUPER_FONCEA_USER', 'MANAGER_FONCEA_USER' ],
    },
    fonceaedit : {},
    fonceaplot     : {
        read:   ['ADMIN_USER', 'ADMIN_FONCEA_USER', 'SUPER_FONCEA_USER' ],
        edit:   ['ADMIN_USER', 'ADMIN_FONCEA_USER', 'SUPER_FONCEA_USER' ],
        delete: ['ADMIN_USER', 'ADMIN_FONCEA_USER', 'SUPER_FONCEA_USER' ]
    },
    jobs     : {
        read:   ['ADMIN_USER', 'ADMIN_FONCEA_USER', 'SUPER_FONCEA_USER' ],
        edit:   ['ADMIN_USER', 'ADMIN_FONCEA_USER', 'SUPER_FONCEA_USER' ],
        delete: ['ADMIN_USER', 'ADMIN_FONCEA_USER', 'SUPER_FONCEA_USER' ]
    },
})
export const scopeGetters = {
    getAccess: () => {
      return scopeCredential
    },
}

export const scopeMutations = {
    setAccess( access ) {
        let prop
        for( prop in access ){
            scopeCredential[prop] = access[prop];
        }
    },
}