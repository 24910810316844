import Vue from "vue";
import Storages      from 'js-storage'

export const cacheData = Vue.observable({
  user      : {},
  states    : {},
  types     : {},
  filter    : {},
  searchBar : {},
  bookmark  : {},
  lastSheet : {},
  appModule : 'compensea',
  department    : [],
  watershed     : [],
  sar           : [],
  species       : [],
  speciesgroup  : [],
  trackingSheets: [],
  coderef       : [],
})

export const cacheGetters = {
  getStates: (group, id) => {
    if( group !== undefined && cacheData.states[group] !== undefined ){
      if( id !== undefined ){
        return ( cacheData.states[group][id] !== undefined ) ? cacheData.states[group][id] : { typ_label: 'Non défini' };
      } else {
        let stateType = []
        for( let prop in cacheData.states[group] ){
          stateType.push( cacheData.states[group][prop] )
        }
        return stateType
      }
    } else {
      return cacheData.states
    }
  },
  getTypes: (group, id) => {
    if( group !== undefined && cacheData.types[group] !== undefined ){
      if( id !== undefined ){
        return ( cacheData.types[group][id] !== undefined ) ? cacheData.types[group][id] : { typ_name: 'Non défini', typ_tag: 'ND' };
      } else {
        let tabType = []
        for( let prop in cacheData.types[group] ){
          tabType.push( cacheData.types[group][prop] )
        }
        return tabType
        //return cacheData.types[group]
      }
    } else if( group !== undefined ){
      let tabType = []  
      return tabType
    } else {
      return cacheData.types
    }
  },
  getTypesByTag: (group, tag) => {
    let tabType = []
    if( group.length ){
      for( let prop in cacheData.types[group] ){
        if( cacheData.types[group][prop].typ_tag == tag ){
          tabType.push( prop )
        }
      }
    } else {
      for( let group in cacheData.types ){
        for( let prop in cacheData.types[group] ){
          if( cacheData.types[group][prop].typ_tag == tag ){
            tabType.push( prop )
          }
        }
      }
    }
    return tabType
  },
  getTypesByGroup: (group) => {
    let tabType = []
    let groupTab = group.split(",")
    groupTab.array.forEach(element => {
      for( let prop in cacheData.types[element] ){
          tabType.push( cacheData.types[element][prop] )
      }      
    })
    return tabType
  },
  getUser: () => {
    return cacheData.user
  },
  getUserRole: () => {
    return cacheData.user.usr_role
  },
  getFilter: () => {
    // default value
    if( cacheData.filter.trs_sta_id === undefined ){
      cacheData.filter.trs_sta_id = ['GEN01']
    }
    return cacheData.filter
  },
  getFiltrerFromStorage(){
    if( Storages.localStorage.isSet( Vue.prototype.$APP_COD + "_cache") ){
      let cache = Storages.localStorage.get( Vue.prototype.$APP_COD + "_cache")
      return cache.filter || {}
    }
  },
  getSearchBar: () => {
    return cacheData.searchBar
  },
  getSearchBarItem: (item) => {
    if( cacheData.searchBar !== undefined ){
      return cacheData.searchBar[item]
    } else {
      return ''
    }
  },
  getBookmark: () => {
    return cacheData.bookmark
  },
  getAppModule: () => {
    return cacheData.appModule
  },
  getDepartment: () => {
    return cacheData.department
  },
  getWatershed: () => {
    return cacheData.watershed
  },
  getSar: () => {
    return cacheData.sar
  },
  getSpecies: () => {
    return cacheData.species
  },
  getSpeciesGroup: () => {
    return cacheData.speciesgroup
  },
  getTrackingSheets: () => {
    return cacheData.trackingsheets
  },
  getCoderef: (typIdSit) => {
    if( typIdSit ){
      return cacheData.coderef.filter(type => type.cdr_typ_id_sit === typIdSit)
    }
    return cacheData.coderef
  },
  getLastSheet: () => {
    return cacheData.lastSheet
  },
}

export const cacheActions = {
  async initCacheFromStorage(){
    new Promise((resolve) => {
      if( Storages.localStorage.isSet( Vue.prototype.$APP_COD + "_cache") ){
        let cache = Storages.localStorage.get( Vue.prototype.$APP_COD + "_cache")
        cacheMutations.setStates( cache.states )
        cacheMutations.setTypes( cache.types )
        cacheMutations.setUser( cache.user || {} )
        cacheMutations.setFilter( cache.filter || {} )
        cacheMutations.setBookmark( cache.bookmark || {} )
        cacheMutations.setLastSheet( cache.lastSheet || {} )
      }
      resolve('resolved')
    })
  },
  async storeCacheToStorage(){
    new Promise((resolve) => {
      Storages.localStorage.set( Vue.prototype.$APP_COD + "_cache", cacheData)
      resolve('resolved')
    })
  },
  async deleteCacheStorage(){
    new Promise((resolve) => {
      if( Storages.localStorage.isSet( Vue.prototype.$APP_COD + "_cache") ){
        Storages.localStorage.remove( Vue.prototype.$APP_COD + "_cache")
        
        cacheMutations.setStates( {} )
        cacheMutations.setTypes( {} )
        cacheMutations.setUser( {} )
        cacheMutations.setFilter( {} )
        cacheMutations.setBookmark( {} )
        cacheMutations.setLastSheet( {} )
      }
      resolve('resolved')
    })
  },
}

export const cacheMutations = {
  setStates( states ) {
    cacheData.states = states;
  },
  setTypes( types ) {
    cacheData.types = types;
  },
  setUser( user ) {
    cacheData.user = user;
  },
  setFilter( filter ) {
    cacheData.filter = filter;
  },
  setSearchBar( searchBar ) {
    cacheData.searchBar = searchBar;
  },
  setBookmark( bookmark ) {
    cacheData.bookmark = bookmark;
  },
  setAppModule: (appModule) => {
    cacheData.appModule = appModule
  },
  setSearchBarItem( item, value ) {
    cacheData.searchBar[item] = value;
  },
  setDepartment( department ) {
    cacheData.department = department;
  },
  setWatershed( watershed ) {
    cacheData.watershed = watershed;
  },
  setSar( sar ) {
    cacheData.sar = sar;
  },
  setSpecies( species ) {
    cacheData.species = species;
  },
  setSpeciesGroup( speciesgroup ) {
    cacheData.speciesgroup = speciesgroup;
  },
  setTrackingSheets( trackingSheets ) {
    cacheData.trackingSheets = trackingSheets;
  },
  setCoderef( coderef ) {
    cacheData.coderef = coderef;
  },
  setLastSheet( lastSheet ) {
    cacheData.lastSheet = lastSheet;
  },
}