<template>
<v-container fluid class="pa-0">
    <v-toolbar class="elevation-2" height="48px">
        <v-btn icon href="/#/speciesgroup/"><v-icon>mdi-arrow-left-thick</v-icon></v-btn>
        <v-toolbar-title >Groupe d'espèce</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-chip outlined color="blue" v-if="form.spg_updated_at">
            <v-icon left>mdi-information-outline</v-icon>Modification: {{ locDateFormat.formatLocale(form.spg_updated_at, 'fr') }}
        </v-chip>
    </v-toolbar>
<!-- Formulaire -->
    <v-card fluid class="ma-2 pa-5 elevation-2">
        <v-layout row wrap>
        <v-flex xs12 class="pa-2">
            <v-text-field label="Nom"     v-model="form.spg_name" required ></v-text-field>
        </v-flex>
        <v-flex xs6 class="pa-2">
            <v-text-field label="Ordre"  type="number"  v-model="form.spg_order" ></v-text-field>
        </v-flex>
        </v-layout>
    </v-card>
    <v-toolbar extended flat color="transparent" class="elevation-0" >
        <v-btn color="red" @click.native="deleteItem()"><v-icon left>mdi-delete</v-icon></v-btn>
        <v-spacer></v-spacer>
        <v-btn color="success" class="mr-3" :loading="saveProgress" @click.native="saveItem()" :disabled="saveProgress">Enregistrer</v-btn>
        <v-btn color="primmary" href="/#/speciesgroup/">Retour</v-btn>
    </v-toolbar>
</v-container>
</template>

<script>
import dateLocale from '../services/dateLocale';

export default {
    name: 'speciesgroupedit',
    props: [],
    data: function () {
        return {
            saveProgress: false,         // Enregistrement en cours...
            form:{
                spg_id : 0, spg_name : '', spg_order : 0,
                spg_created_at : null, spg_updated_at : null,
            },
            locDateFormat: dateLocale, // Format de date
        }
    },
    mounted:function (){
        this.getItem()
    },
    methods: {
        getItem() {
            if( parseInt( this.$route.params.spg_id ) > 0 && this.$route.params.spg_id !== undefined ){
                this.$http.get( '/speciesgroup/' + this.$route.params.spg_id )
                .then( (response) => {
                    var prop
                    for( prop in response.data.data ){
                        if( this.form[prop] !== undefined ){
                            this.form[prop] = response.data.data[prop] 
                        }
                    }
                })
                .catch( (error) => {
                    this.$root.$toast({ color: 'warning', text: error })
                })
            }
        },
        saveItem () {
            // Enregistrement du formulaire
            this.saveProgress = true
            if( parseInt( this.$route.params.spg_id ) > 0 ){
                this.$http.put( '/speciesgroup/' + this.$route.params.spg_id, this.form )
                .then( () => {
                    this.$root.$toast({ color: 'success', text: 'Fiche enregistrée !' })
                    this.$router.push({ path: '/speciesgroup' })
                }).catch( (error) => {
                    this.$root.$toast({ text: 'Erreur lors de l\'enregistrement !', error: error })
                    this.saveProgress = false
                });
            } else {
                this.$http.post( '/speciesgroup/', this.form )
                .then( () => {
                    this.$root.$toast({ color: 'success', text: 'Fiche enregistrée !' })
                    this.$router.push({ path: '/speciesgroup' })
                }).catch( (error) => {
                    this.$root.$toast({ text: 'Erreur lors de l\'enregistrement !', error: error })
                });
            }
        },
        deleteItem(){
            this.$root.$confirm('Supprimer', 'Confirmer la suppression de cette fiche', { color: 'red', width: 500 }).then((confirm) => {
                if( confirm ){
                    this.$http.delete( '/speciesgroup/' + this.form.spg_id ).then( () => {
                        this.$root.$toast({ color: 'success', top:false, bottom:true, right:false, left:true, text:'Fiche supprimée !' })
                        this.$router.push({ path: '/speciesgroup' })
                    })
                }
            })
        },
    },
    components: { }
}
</script>