import Vue from "vue";
import { cacheData } from "../store/cache.module"
import { scopeGetters } from "../scopeCredential"

export const usrState = Vue.observable({
  role     : '',
  appMode  : 'compensea',
  genStates: [],
})

export const usrGetters = {
  genState: () => {
    return usrState.genStates
  },
  getRole: () => {
    return usrState.role
  },
  getAppMode: () => {
    return usrState.appMode
  },
  getScopeRight: (scope, item) => {
    let scopeCredential = scopeGetters.getAccess()
    if( scopeCredential[scope] !== undefined && scopeCredential[scope][item] !== undefined ){
      if( scopeCredential[scope][item].indexOf( cacheData.user.usr_role ) > -1 || scopeCredential[scope][item].indexOf( 'AUTHORIZED' ) > -1 ){
        return true
      } else {
        return false
      }
    } else if( scopeCredential['Generic'][item] && (scopeCredential['Generic'][item].indexOf( cacheData.user.usr_role ) > -1 || scopeCredential['Generic'][item].indexOf( 'AUTHORIZED' ) > -1) ){
      return true
    } else {
      return false
    }
  },
  getRolesLabels: (role) => {
    role = role || undefined

    const roleLabels = {
      'ADMIN_USER' : 'Administrateur',
      'BASIC_USER' : 'Utilisateur',
      'SUPER_USER' : 'Superviseur',
      'INTER_USER' : 'Utilisteur interne',
      'EXTER_USER' : 'Utilisateur externe',
      'ADMIN_FONCEA_USER'   : 'FONCEA Administrateur',
      'SUPER_FONCEA_USER'   : 'FONCEA Superviseur',
      'MANAGER_FONCEA_USER' : 'FONCEA Manager',
      'VIEW_FONCEA_USER'    : 'FONCEA Utilisteur',
    }

    if( role !== undefined && roleLabels[role] !== undefined ){
      return roleLabels[role]
    } else {
      return roleLabels
    }
  }
}

export const usrActions = {

}

export const usrMutations = {
  setGenStates( genStates ) {
    usrState.genStates = genStates;
  },
  setAppMode( appMode ) {
    usrState.appMode = appMode;
  },

};