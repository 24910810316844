<template>
<v-card class="ma-0">
    <v-tabs v-model="activeTab" grow light show-arrows="mobile">
        <v-tabs-slider color="green"></v-tabs-slider>
        <!-- <v-tab><v-icon class="mr-1">mdi-file-document-box-check</v-icon><span class="hidden-sm-and-down">Procédures</span></v-tab> -->
        <v-tab class="pl-1 pr-1"><v-icon class="hidden-md-and-up mr-1">mdi-file</v-icon>
            <span class="hidden-sm-and-down">Évènement {{(objEvent.eve_typ_id_rec !== 'REC01') ? 'récurrent' : ''}} [{{eventLabelType[objEvent.eve_table_id]}}]</span>
        </v-tab>
        <v-tab class="pl-1 pr-1" :disabled="!eveId">
            <v-icon class="hidden-md-and-up mr-1" :disabled="true">mdi-file</v-icon>
            <span class="hidden-sm-and-down">
            <v-badge color="green" :content="(doc_ids) ? doc_ids : '0'">Fichiers</v-badge>
            </span>
        </v-tab>
    </v-tabs>
    <v-divider></v-divider>
    <v-card class="ma-0" v-if="activeTab === 0">
        <v-card-text>
        <v-layout row wrap>
            <v-flex xs4 class="pa-2" v-if="objEvent.eve_typ_id_rec !== 'REC01'">
                <DatePicker :label="'Date début de l\'évènement à créer'" :objectForm="virutalEvent" fieldName='eve_date_start' :disabled="!isAuthorized( 'Event', 'update' )"></DatePicker>
            </v-flex>
            <v-flex xs4 class="pa-2" v-if="objEvent.eve_typ_id_rec !== 'REC01'">
                <DatePicker :label="'Date fin de l\'évènement à créer'" :objectForm="virutalEvent" fieldName='eve_date_start' :disabled="!isAuthorized( 'Event', 'update' )"></DatePicker>
            </v-flex>
            <v-flex xs4 class="pa-2" v-if="objEvent.eve_typ_id_rec !== 'REC01'"></v-flex>
            <v-flex xs4 class="pa-2">
                <DatePicker :label="labelDateStart" :objectForm="objEvent" fieldName='eve_date_start' :disabled="!isAuthorized( 'Event', 'update' )"></DatePicker>
            </v-flex>
            <v-flex xs4 class="pa-2" v-if="!isControl">
                <DatePicker :label="labelDateEnd" :objectForm="objEvent" fieldName='eve_date_end' :disabled="!isAuthorized( 'Event', 'update' )"></DatePicker>
            </v-flex>
            <v-flex xs1 class="pa-2" v-if="objEvent.eve_typ_id_rec !== 'REC01'">
                <v-text-field label="nombre" v-model="objEvent.eve_frequency_rec"></v-text-field>
            </v-flex>
            <v-flex xs3 class="pa-2" v-if="objEvent.eve_typ_id_rec !== 'REC01'">
                <v-select :items="getCacheType('REC')" 
                    item-text="typ_name" item-value="typ_id" v-model="objEvent.eve_typ_id_rec" label="récurrence" 
                    :disabled="!isAuthorized( 'Event', 'update' )">   
                </v-select>
            </v-flex>
            <v-flex xs4 class="pa-2" v-if="objEvent.eve_typ_id_rec == 'REC01'">
                <v-switch v-model="isRecurrent" :label="'Évènement récurrent'" @click="objEvent.eve_typ_id_rec = 'REC03'"></v-switch>
            </v-flex>
            <v-flex xs8 class="pa-2">
                <v-text-field prepend-icon='mdi-clipboard-text-outline' label="Titre" v-model="objEvent.eve_title" :disabled="!isAuthorized( 'Event', 'update' )"></v-text-field>
            </v-flex>
            <v-flex xs4 class="pa-2">
                <v-select :items="types" item-text="typ_name" item-value="typ_id" v-model="objEvent.eve_typ_id" label="Statut" single-line :disabled="!isAuthorized( 'Event', 'update' )">   
                </v-select>
            </v-flex>
            <v-flex xs3 class="pa-2" v-if="isAuthorized( 'Event', 'update' )">
                <v-text-field prepend-icon='mdi-qrcode-scan' label="Code" placeholder="E001" v-model="objEvent.eve_code" :disabled="!isAuthorized( 'Event', 'update' )"></v-text-field>
            </v-flex>
            <v-flex xs3 class="pa-2" v-if="isAuthorized( 'Event', 'update' )">
                <v-text-field prepend-icon='mdi-calculator-variant-outline' label="Valeur" v-model="objEvent.eve_value" :disabled="!isAuthorized( 'Event', 'update' )"></v-text-field>
            </v-flex>
            <v-flex xs3 class="pa-2" v-if="!isControl && isAuthorized( 'Event', 'update' )">
                <v-text-field prepend-icon='mdi-currency-eur' label="Coût" v-model="objEvent.eve_cost_value" :disabled="!isAuthorized( 'Event', 'update' )"></v-text-field>
            </v-flex>
            <v-flex xs3 class="pa-2" v-if="!isControl && isAuthorized( 'Event', 'update' )">
                <v-checkbox :true-value="1" :false-value="0" v-model="objEvent.eve_delegation" label="Gestion par LISEA" :disabled="!isAuthorized( 'Event', 'update' )"></v-checkbox>
            </v-flex>
            <v-flex xs3 class="pa-2" v-if="isControl">
                <autoCompMulti v-if="!loading.getItem" :title="labels.convention" :ressource="'trackingconvention'" :listObject.sync="autoComplete.tcv_code"
                    :listParent.sync="autoCompleteSource.tcv_code" 
                    :fields="{ 
                        index: 'tcv_code', text:'tcv_code', search:'tcv_code', multiSelect:false, preprendIcon:'mdi-sim',
                        prefetch: true, params: 'tcv_trs_id=' + xxxId + '&group_by=tcv_code&per_page=false'
                    }">
                </autoCompMulti>
            </v-flex>
            <v-flex xs3 class="pa-2" v-if="isControl && isAuthorized( 'Event', 'update' )">
            <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-bind="attrs" v-on="on" @focus="getSpecification()" prepend-icon='mdi-code-equal' label="Mesure" v-model="objEvent.eve_custom_fields.eve_spf_code" :disabled="!isAuthorized( 'Event', 'update' )"></v-text-field>
                </template>
                <v-list>
                    <v-list-item v-for="(item, index) in specificationItems" :key="index">
                    <v-list-item-title @click="objEvent.eve_custom_fields.eve_spf_code = formatSpfCode(item)" style="cursor: pointer;">
                        {{ formatSpfCode(item) }}
                    </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
                <!-- <v-text-field prepend-icon='mdi-code-equal' label="Mesure" v-model="objEvent.eve_custom_fields.eve_tcv_code" :disabled="!isAuthorized( 'Event', 'update' )"></v-text-field>-->
            </v-flex>
            <v-flex xs12 class="pa-2">
                <v-textarea label="Description" rows="2" v-model="objEvent.eve_description" :disabled="!isAuthorized( 'Event', 'update' )"></v-textarea>
            </v-flex>
            <v-flex xs12 class="pa-2" v-if="isAuthorized( 'Event', 'update' )">
                <v-subheader v-if="isControl" class="pl-0">Conformité</v-subheader>
                <v-slider v-if="isControl" class="mx-3"
                v-model="objEvent.eve_resolve_percent" :step="1"
                thumb-label="always"
                ></v-slider>
                <v-radio-group v-if="!isControl" v-model="objEvent.eve_resolve_percent" row :disabled="!isAuthorized( 'Event', 'update' )">
                    <v-radio label="Non réalisé" :value="0" ></v-radio>
                    <v-radio label="Réalisé" :value="100" ></v-radio>
                </v-radio-group>
            </v-flex>
        </v-layout>
        </v-card-text>
        <v-divider light></v-divider>
        <v-card-actions>
            <v-btn v-if="isAuthorized( 'Event', 'update' )" class="ma-0" color="error" @click.native="deleteEvent()" :disabled="saveProgress">
                <v-icon left>mdi-trash-can</v-icon>Supprimer
            </v-btn>
            <v-btn v-if="objEvent.eve_eve_id" class="ma-0 ml-2" text color="primary" @click.native="getEvent(objEvent.eve_eve_id)" :disabled="saveProgress">
                <v-icon>mdi-origin</v-icon>Origine
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn rounded v-if="isAuthorized( 'Event', 'update' )"
                color="success" 
                :loading="saveProgress" @click.native="saveEvent()" :disabled="saveProgress">
                Enregistrer
            </v-btn>
            <v-btn rounded v-if="isAuthorized( 'Event', 'update' ) && objEvent.eve_typ_id_rec !== 'REC01'"
                color="orange" 
                :loading="saveProgress" @click.native="saveEvent(true)" :disabled="saveProgress">
                Créer l'évènement
            </v-btn>
            <v-btn rounded outlined color="grey" @click="switchModal('modalEvent', 0)">
                Fermer
            </v-btn>
        </v-card-actions>
    </v-card>
    <documentTab v-if="activeTab === 1 && showTab == true" :doc_xxx_id="eveId" :table_id="'eve'" :totalDoc.sync="doc_ids"
        :routeState="{ siteView:false, siteEdit:true, view:false, edit:true, add:true, state: 'modifier' }" style="min-height: 474px;">
    </documentTab>
</v-card>
</template>

<script>
import DatePicker from '@/components/ui/DatePicker'
import autoCompMulti    from './Autocomplete_multi.vue'
import dateLocale from '../services/dateLocale';
import { eveObj } from '../mixins/eve_obj.js'
import { cacheGetters } from "../store/cache.module"
import documentTab  from '../components/Tab_document.vue'
import { usrGetters }   from "../store/user.module"

export default {
    name: 'eventedit',
    props: ['xxxId', 'eveId', 'eveTable', 'eveType', 'switchModal', 'virutalEvent', 'sheetType'],
    mixins: [ eveObj ],
    components: {
        documentTab, autoCompMulti, DatePicker
    },
    data: function () {
        return {
            isAuthorized: usrGetters.getScopeRight,
            loading : { getItem: true },
            dateMenu: { date_start:false, date_end:false },
            saveProgress: false,         // Enregistrement en cours...
            locDateFormat: dateLocale, // Format de date
            states : [],
            types : [],
            dateEve: { start: false, end: false },
            eventType: {
                'trs' : [ 'WRK' ],
                'spf' : [ 'MES' ],
                'inv' : [ 'INE' ],
            },
            eventLabelType: {
                trs: 'Site',
                spf: (this.sheetType == 'SIT02') ? 'Plan de gestion' : 'Cahier des charges',
                tcv: 'Convention'
            },
            doc_ids: 0,
            activeTab: 0,
            showTab: true,
            autoComplete: {
                tcv_code: null,
                spf_id  : null,
            },
            autoCompleteSource:{
                tcv_code: [],
                spf_id  : [],
            },
            labels: {
                convention: (this.sheetType == 'SIT02') ? 'Plan de gestion' : 'Convention'
            },
            specificationItems: []
        }
    },
    computed: {
        isRecurrent: {
            get: function() {
                return (this.objEvent.eve_typ_id_rec !== 'REC01')
            },
            set: function() {
                return true
            }

        },
        isControl(){
            if( this.eveType == 'CTR' ){
                return true
            } else {
                return false
            }
        },
        labelDateStart(){
            let label = (this.isControl) ? 'Date de passage ' : 'Date début '
            if(this.virutalEvent){
                label    += (this.objEvent.eve_typ_id_rec !== 'REC01') ? '(de l\'évènement récurrent)' : ''
                return label
            } else {
                return label
            }
        },
        labelDateEnd(){
            let label = 'Date de fin '
            if(this.virutalEvent){
                label    += (this.objEvent.eve_typ_id_rec !== 'REC01') ? '(de l\'évènement récurrent)' : ''
                return label
            } else {
                return label
            }
        }
    },
    //beforeMount: function(){ },
    mounted:function (){
        if ( this.eveType ){
            this.types = this.types.concat( cacheGetters.getTypes(this.eveType) )
        } else if( this.eventType[this.eveTable] !== undefined ){
            this.eventType[this.eveTable].forEach(element => {
                this.types = this.types.concat( cacheGetters.getTypes(element) )
            });
        } else {
            this.types = this.types.concat( cacheGetters.getTypes('ACT') )
        }
        if( this.eveId !== undefined && this.eveId > 0 ){
            this.getEvent(this.eveId)
        } else {
            this.cleanObjEvent()
            this.objEvent.eve_id         = 0
            this.objEvent.eve_typ_id     = this.types[0].typ_id
            this.objEvent.eve_table_id   = (Array.isArray(this.eveTable)) ? 'trs' : this.eveTable
            this.objEvent.eve_xxx_id     = this.xxxId
            this.objEvent.eve_date_start = this.locDateFormat.getToday('en')

            this.loading.getItem = false
        }
    },
    methods: {
        getEvent(id){
            this.objEvent.eve_id = id
            this.getEventShow().then( () => {
                this.doc_ids = 0
                this.getAttachedDocument()
                this.autoComplete.tcv_code = (this.objEvent.eve_custom_fields) ? this.objEvent.eve_custom_fields.tcv_code : null
                this.loading.getItem = false
            })
        },
        saveEvent(newEvent){
            newEvent = newEvent || false
            this.objEvent.eve_id = (newEvent) ? 0 : this.objEvent.eve_id
            this.objEvent.eve_typ_id_rec = (newEvent) ? 'REC01' : this.objEvent.eve_typ_id_rec
            this.objEvent.eve_custom_fields.tcv_code = (this.autoComplete.tcv_code) ? this.autoComplete.tcv_code : null
            // Virtual event to real
            if( newEvent ){
                this.objEvent.eve_date_start = (this.virutalEvent.eve_date_start) ? this.virutalEvent.eve_date_start : this.objEvent.eve_date_start
                this.objEvent.eve_date_end   = (this.virutalEvent.eve_date_start) ? this.virutalEvent.eve_date_start : this.objEvent.eve_date_end
                this.objEvent.eve_eve_id = this.virutalEvent.eve_id
            }

            if( this.objEvent.eve_id !== undefined && this.objEvent.eve_id > 0 ){
                this.putEventUpdate().then( () => {
                    this.switchModal('modalEvent', 0, true)
                })
            } else {
                this.postEventStore().then( () => {
                    this.switchModal('modalEvent', 0, true)
                })
            }
        },
        deleteEvent(){
            this.$root.$confirm('Supprimer cet évènement', 'Confirmez vous cette action ?', { color: 'red', width: 400 }).then((confirm) => {
                if( confirm && this.objEvent.eve_id > 0 ){
                    this.deleteObjEvent().then( () => {
                        this.switchModal('modalEvent', 0, true)
                    })
                }
            })
        },
        getAttachedDocument(){
            var searchCriteria = '?per_page=false' //page='+ this.pagination.page +'&
            searchCriteria    += '&doc_xxx_id=' +  this.objEvent.eve_id + '&doc_table_id=eve'
            this.$http.get( '/documents/' + searchCriteria ).then( (response) => {
                if( response.data.meta.pagination.total !== undefined && response.data.meta.pagination.total !== '0' ){
                    this.doc_ids  = parseInt(response.data.meta.pagination.total)
                }
            })
        },
        getSpecification(){
            this.$http.get( '/specification/' + '?per_page=false&extended=withcoderef,withevent&spf_trs_id=' + this.xxxId  ).then( (response) => {
                this.specificationItems = response.data.data
            })
        },
        formatSpfCode(item){
            let code = ''
            {{ (item.spf_custom_fields) ? item.spf_custom_fields['spf_code'] + ' (' + item.spf_custom_fields['spf_precision'] + ')' : item.cdr_code }}
            code += (item.spf_custom_fields && item.spf_custom_fields['spf_code']) ? item.spf_custom_fields['spf_code'] : ''
            code += (item.spf_custom_fields && item.spf_custom_fields['spf_precision']) ? ' (' + item.spf_custom_fields['spf_precision'] + ')' : ''
            code += (!code) ? item.cdr_code : ''
            return code
        },
        getCacheType   : cacheGetters.getTypes,
    }
}
</script>