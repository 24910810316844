<template>
<v-container fluid class="pa-0">
    <v-toolbar class="elevation-2" height="48px">
        <v-btn icon href="/#/coderef/"><v-icon>mdi-arrow-left-thick</v-icon></v-btn>
        <v-toolbar-title >Cahier des charges </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-chip outlined color="blue" v-if="form.cdr_updated_at">
            <v-icon left>mdi-information-outline</v-icon>Modification: {{ locDateFormat.formatLocale(form.cdr_updated_at, 'fr') }}
        </v-chip>
    </v-toolbar>
<!-- Formulaire -->
    <v-card fluid class="ma-2 pa-5 elevation-2">
        <v-layout row wrap>
        <v-flex xs3 class="pa-2">
            <v-select 
                item-text="typ_name" item-value="typ_id"
                :items="getCacheType('SIT')" v-model="form.cdr_typ_id_sit" label="Cible" single-line >
            </v-select>
        </v-flex>
        <v-flex xs9 class="pa-2">
            <v-text-field label="Nom"    v-model="form.cdr_description" ></v-text-field>
        </v-flex>
        <v-flex xs12 class="pa-2">
            <v-text-field label="Description"    v-model="form.cdr_comment" ></v-text-field>
        </v-flex>
        <v-flex xs3 class="pa-2">
            <v-text-field label="Code"    v-model="form.cdr_code" ></v-text-field>
        </v-flex>
        <v-flex xs3 class="pa-2">
            <v-text-field label="Code Mce"    v-model="form.cdr_code_mce" ></v-text-field>
        </v-flex>
        <v-flex xs3 class="pa-2">
            <v-select item-text="sta_label" item-value="sta_id" :items="getCacheState('GEN')" v-model="form.cdr_sta_id" label="Statut" single-line ></v-select>
        </v-flex>
        <v-flex xs3 class="pa-2">
            <v-select 
                item-text="typ_name" item-value="typ_id"
                :items="getCacheType('SPM')" v-model="form.cdr_typ_id" label="Type" single-line >
            </v-select>
        </v-flex>
        </v-layout>
    </v-card>
    <div class="ma-4 text-right">
        <v-btn color="success" class="mr-3" :loading="saveProgress" @click.native="saveForm()" :disabled="saveProgress">Enregistrer</v-btn>
        <v-btn color="primmary" href="/#/coderef/">Retour</v-btn>
    </div>
</v-container>
</template>

<script>
import dateLocale from '../services/dateLocale';
import { cacheGetters } from "../store/cache.module"

export default {
    name: 'coderefedit',
    props: [],
    data: function () {
        return {
            saveProgress: false,         // Enregistrement en cours...
            form:{
                cdr_id: 0, 
                cdr_typ_id: 'SPM01', 
                cdr_typ_id_sit: 'SIT01',
                cdr_sta_id: 'GEN01', 
                cdr_code: '', 
                cdr_code_mce: '', 
                cdr_description: '', 
                cdr_comment: '', 
                cdr_updated_at: null,
            },
            locDateFormat: dateLocale, // Format de date
        }
    },
    mounted:function (){
        if( parseInt( this.$route.params.cdr_id ) > 0 && this.$route.params.cdr_id !== undefined ){
            this.$http.get( '/coderef/' + this.$route.params.cdr_id )
            .then( (response) => {
                var prop
                for( prop in response.data.data ){
                    if( this.form[prop] !== undefined ){
                        this.form[prop] = response.data.data[prop] 
                    }
                }
            })
            .catch( (error) => {
                this.$root.$toast({ color: 'warning', text: error })
            })
        }
    },
    methods: {
        saveForm () {
            // Enregistrement du formulaire
            this.saveProgress = true
            if( parseInt( this.$route.params.cdr_id ) > 0 ){
                this.$http.put( '/coderef/' + this.$route.params.cdr_id, this.form )
                .then( () => {
                    this.$root.$toast({ color: 'success', text: 'Fiche enregistrée !' })
                    this.$router.push({ path: '/coderef' })
                }).catch( (error) => {
                    this.$root.$toast({ text: 'Erreur lors de l\'enregistrement !', error: error })
                    this.saveProgress = false
                });
            } else {
                this.$http.post( '/coderef/', this.form )
                .then( () => {
                    this.$root.$toast({ color: 'success', text: 'Fiche enregistrée !' })
                    this.$router.push({ path: '/coderef' })
                }).catch( (error) => {
                    this.$root.$toast({ text: 'Erreur lors de l\'enregistrement !', error: error })
                });
            }
        },
        getCacheType: cacheGetters.getTypes,
        getCacheState: cacheGetters.getStates
    },
    components: { }
}
</script>