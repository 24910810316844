<template>
<v-card>
    <v-toolbar color="primary" dark class="elevation-2" height="50px">
        <v-icon class="mr-3">mdi-chevron-down</v-icon>
        <v-toolbar-title>
            <span v-if="objItem.tcv_code && objItem.tcv_code.length > 0">Edition de facture ( {{ (objItem.inv_typ_id == 'INV01') ? 'Convention' : 'Acquisition'}} : {{objItem.tcv_code}} )</span>
            <span v-else>Création de facture</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon color="" @click.native="switchModal('-', 0)"><v-icon>mdi-close</v-icon></v-btn>
    </v-toolbar>
    <v-card-text v-show="objItem.inv_tcv_id > 0"><v-layout row wrap>
        <v-flex xs6 class="pa-2">
            <auto-multi v-if="!loading.getItem" :title="'Contact'" ressource="contacts" :listObject.sync="autoComplete.inv_ctt_id"
                :listParent.sync="autoCompleteSource.ctt" 
                :fields="{ index: 'ctt_id', text:'ctt_middlename', search:'ctt_middlename', multiSelect:false }">
            </auto-multi>
        </v-flex>
        <v-flex xs6 class="pa-2">
            <auto-multi v-if="!loading.getItem" :title="'Société / Entreprise'" ressource="contacts" :listObject.sync="autoComplete.inv_ctt_id_company"
                :listParent.sync="autoCompleteSource.ctc" 
                :fields="{ index: 'ctt_id', text:'ctt_middlename', search:'ctt_middlename', multiSelect:false, preprendIcon:'mdi-domain' }">
            </auto-multi>
        </v-flex>
        <v-flex xs3 class="pa-2">
            <v-menu ref="datestart" v-model="dateItem.date_start"
                :return-value.sync="objItem.inv_date"
                :close-on-content-click="false" :nudge-right="40" transition="scale-transition" 
                offset-y min-width="290px" style="z-index:1500">
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field 
                        v-bind="attrs" :value="locDateFormat.formatLocale( objItem.inv_date, 'fr' )"
                        label="Date facture" prepend-icon="mdi-calendar-today" readonly v-on="on" >
                    </v-text-field>
                </template>
                <v-date-picker locale="fr-fr" no-title scrollable v-model="objItem.inv_date">
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="dateItem.date_start = false">Annuler</v-btn>
                    <v-btn color="primary" @click="$refs.datestart.save( objItem.inv_date )">OK</v-btn>
                </v-date-picker>
            </v-menu>
        </v-flex>
        <v-flex xs3 class="pa-2">
            <v-menu ref="dateend" v-model="dateItem.date_end"
                :return-value.sync="objItem.inv_date_received"
                :close-on-content-click="false" :nudge-right="40" transition="scale-transition" 
                offset-y min-width="290px" style="z-index:1500">
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field 
                        v-bind="attrs" :value="locDateFormat.formatLocale( objItem.inv_date_received, 'fr' )"
                        label="Date de reception" prepend-icon="mdi-calendar-today" readonly v-on="on" >
                    </v-text-field>
                </template>
                <v-date-picker locale="fr-fr" no-title scrollable v-model="objItem.inv_date_received">
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="dateItem.date_end = false">Annuler</v-btn>
                    <v-btn color="primary" @click="$refs.dateend.save( objItem.inv_date_received )">OK</v-btn>
                </v-date-picker>
            </v-menu>
        </v-flex>
        <v-flex xs3 class="pa-2">
            <v-menu ref="datepay" v-model="dateItem.date_payment"
                :return-value.sync="objItem.inv_date_payment"
                :close-on-content-click="false" :nudge-right="40" transition="scale-transition" 
                offset-y min-width="290px" style="z-index:1500">
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field 
                        v-bind="attrs" :value="locDateFormat.formatLocale( objItem.inv_date_payment, 'fr' )"
                        label="Date paiement" prepend-icon="mdi-calendar-today" readonly v-on="on" >
                    </v-text-field>
                </template>
                <v-date-picker locale="fr-fr" no-title scrollable v-model="objItem.inv_date_payment">
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="dateItem.date_payment = false">Annuler</v-btn>
                    <v-btn color="primary" @click="$refs.datepay.save( objItem.inv_date_payment )">OK</v-btn>
                </v-date-picker>
            </v-menu>
        </v-flex>
        <v-flex xs3 class="pa-2">
            <v-select  :items="stateItem" v-model="objItem.inv_sta_id" label="Etat"
                    item-text="sta_label" item-value="sta_id" >
            </v-select>
        </v-flex>
        <v-flex xs3 class="pa-2">
            <v-text-field label="Montant" v-model="objItem.inv_amount_ht" ></v-text-field>
        </v-flex>
        <v-flex xs3 class="pa-2">
            <v-text-field label="TVA" v-model="inv_tax" ></v-text-field>
        </v-flex>
        <v-flex xs3 class="pa-2">
            <v-text-field label="Montant TVA" v-model="inv_amount_tax" disabled></v-text-field>
        </v-flex>
        <v-flex xs3 class="pa-2">
            <v-text-field label="Montant TTC" v-model="inv_amount_ttc" disabled></v-text-field>
        </v-flex>
        <v-flex xs12 class="pa-2">
            <v-text-field label="Description" v-model="objItem.inv_note"></v-text-field>
        </v-flex>
        <v-flex xs12 class="pa-2">
            <v-text-field label="Lien DEMAT" prepend-inner-icon="mdi-link" v-model="objItem.inv_demat_code"></v-text-field>
        </v-flex>
    </v-layout>
    </v-card-text>
    <!--  Select a convention if not -->
    <v-card-text v-show="objItem.inv_tcv_id == 0">
    <v-layout row wrap>
        <v-flex xs12 class="pa-2">
            <auto-multi v-if="!loading.getItem" :title="'Convention'" ressource="trackingconvention" :listObject.sync="autoComplete.inv_tcv_id"
                :listParent.sync="autoCompleteSource.tcv" 
                :fields="conventionFields">
            </auto-multi>
        </v-flex>
    </v-layout>
    </v-card-text>
    <v-divider light></v-divider>
    <v-card-actions v-show="objItem.inv_tcv_id > 0">
        <v-menu v-if="objItem.inv_id > 0" v-model="optionMenu" offset-y transition="slide-x-transition" bottom right >
            <template v-slot:activator="{ on }">
            <v-btn v-on="on" outlined class="ml-0 pa-0" style="min-width: 5px;" color="grey"><v-icon>mdi-dots-vertical</v-icon></v-btn>
            </template>
            <v-btn class="ma-0" color="error" @click.native="deleteItem()" :disabled="saveProgress">
                <v-icon left>mdi-trash-can</v-icon>Supprimer
            </v-btn>
        </v-menu>
        <v-spacer></v-spacer>
        <v-btn rounded  
            color="success" 
            :loading="saveProgress" @click.native="saveItem()" :disabled="saveProgress">
            Enregistrer
        </v-btn>
        <v-btn rounded outlined color="grey" @click="switchModal('editinvoice', 0)">
            Fermer
        </v-btn>
    </v-card-actions>
    <v-card-actions v-if="autoComplete.inv_tcv_id > 0 && objItem.inv_tcv_id == 0">
        <v-spacer></v-spacer>
        <v-btn rounded  color="success" 
            @click.native="validConvention()">
            OK
        </v-btn>
    </v-card-actions>
</v-card>
</template>

<script>
import dateLocale from '../services/dateLocale';
import autoCompMulti    from './Autocomplete_multi.vue'
import { cacheGetters } from "../store/cache.module"

export default {
    name: 'invoicesedit',
    props: ['invoiceItem', 'switchModal'],
    data: function () {
        return {
            saveProgress: false,         // Enregistrement en cours...
            loading: { getItem: true },
            locDateFormat: dateLocale, // Format de date
            state : {},
            stateItem: cacheGetters.getStates('SIN'),
            dateItem: { date_start:false, date_end:false, date_payment:false },
            optionMenu: false,
            autoComplete: {
                inv_ctt_id: null,
                inv_ctt_id_company: null,
                inv_tcv_id: null
            },
            autoCompleteSource:{
                ctt: [],
                ctc: [],
                tcv: [],
            },
            inv_tax: 20,
            objItem: { 
                trs_coddt : "", tcv_code : 0, spf_typ_id_index : "INR01", 
                inv_id : 0, inv_tcv_id : 0, inv_sta_id : 'SIN01', inv_typ_id : "INV01", 
                inv_ctt_id : null, inv_ctt_id_company : null,
                inv_amount_ht_calc : 0, inv_amount_ht : 0, inv_amount_tax : 0, inv_amount_ttc : 0, inv_note:'', inv_demat_code: '',
                inv_date_received : null, inv_date_payment: null,
                inv_created_at : null, inv_date : null, 
                inv_spf_ids : [], inv_preview: false
            },
            conventionFields: { 
                index: 'tcv_id', text: 'tcv_code,trs_coddt,ctt_middlename_contact', search:'tcv_code', 
                multiSelect:false, autoselectfirst: true, params: 'extended=withcontact,withtrackingsheet'
            }
        }
    },
    computed : {
        inv_amount_tax(){
            let tax = ( this.inv_tax == 0 ) ? 1 : this.inv_tax / 100
            return this.objItem.inv_amount_ht * tax
        },
        inv_amount_ttc(){
            let tax = Number( ( this.inv_tax == 0 ) ? 1 : this.inv_tax / 100 )
            return Number( this.objItem.inv_amount_ht ) + (this.objItem.inv_amount_ht * tax)
        }
    },
    mounted:function (){
        var prop
        for( prop in this.invoiceItem ){
            if( this.objItem[prop] !== undefined ){
                this.objItem[prop] = this.invoiceItem[prop] 
            }
        }

        if( this.objItem.inv_preview !== true && this.objItem.inv_id !== undefined && this.objItem.inv_id > 0 ){
            this.getObjItem()
        } else {
            this.loading.getItem = false
        }
    },
    methods: {
        getObjItem(){
            this.$http.get( '/invoices/' + this.objItem.inv_id + '/?include=contact,company' )
            .then( (response) => {
                this.objItem = response.data.data
                if( this.objItem.contact && this.objItem.inv_ctt_id > 0 ){
                    this.autoCompleteSource.ctt.push(this.objItem.contact.data)
                    this.autoComplete.inv_ctt_id = this.objItem.inv_ctt_id
                }
                if( this.objItem.company && this.objItem.inv_ctt_id_company > 0 ){
                    this.autoCompleteSource.ctc.push(this.objItem.company.data)
                    this.autoComplete.inv_ctt_id_company = this.objItem.inv_ctt_id_company
                }
                this.loading.getItem = false
            })
        },
        saveItem(){
            this.objItem.inv_amount_tax     = this.inv_amount_tax
            this.objItem.inv_amount_ttc     = this.inv_amount_ttc
            this.objItem.inv_ctt_id         = this.autoComplete.inv_ctt_id
            this.objItem.inv_ctt_id_company = this.autoComplete.inv_ctt_id_company
            var prop
            if( this.objItem.inv_preview ){
                for( prop in this.objItem ){
                    this.invoiceItem[prop] = this.objItem[prop]
                }
                this.$emit('update:invoiceItem', this.objItem)
                this.switchModal('-', 0)
            } else if( this.objItem.inv_id > 0 ) {
                this.$http.put( '/invoices/' + this.objItem.inv_id, this.objItem )
                .then( () => {
                    this.$root.$toast({ color: 'success', text: 'Modification enregistrée !' })
                    this.switchModal('-', 0, true)
                })
            } else {
                this.$http.post( '/invoices/', this.objItem )
                .then( () => {
                    this.$root.$toast({ color: 'success', text: 'Modification enregistrée !' })
                    this.switchModal('-', 0, true)
                })
            }
        },
        cancelEdit(){
            this.switchModal('editinvoice', 0)
        },
        deleteItem(){
            this.$root.$confirm('Supprimer la facture (' + this.objItem.inv_id + ')', 'Confirmez vous cette action ?', { color: 'red', width: 400 }).then((confirm) => {
                if( confirm ){
                    this.$http.delete( '/invoices/' + this.objItem.inv_id).then( () => {
                        this.$root.$toast({ color: 'success', text: 'Action enregistrée !' })
                        this.switchModal('-', 0, true)
                    })
                }
            })
        },
        validConvention(){
            this.objItem.inv_tcv_id = this.autoComplete.inv_tcv_id
        }
    },
    components: {
        'auto-multi': autoCompMulti
    }
}
</script>