<template>
<v-container fluid class="pa-0">
    <v-toolbar class="elevation-1" height="48px">
        <v-toolbar-title >Liste des couches SIG</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn rounded to="/layers/ajouter" color="success" depressed class="white--text">
            <v-icon left dark>mdi-plus-circle</v-icon>
            <span class="hidden-sm-and-down">Ajouter</span>
        </v-btn>
    </v-toolbar>
    <v-text-field append-icon="mdi-magnify" label="Recherche"
        solo single-line clearable hide-details v-model="search" @blur="saveSearchToCache()" class="ma-4 pa-0">
    </v-text-field>
    <v-card class="ma-4">
    <v-data-table v-bind:headers="headers" v-bind:items="items" v-bind:search="search"
        :page.sync="pagination.page" :items-per-page="pagination.itemsPerPage" @page-count="pagination.pageCount = $event"
        footer-props.items-per-page-text="Lignes par page" hide-default-footer>
        <template v-slot:[`item.lay_sta_id`]="{ item }">
            <v-chip label outlined :color="( item.lay_sta_id == 'GEN01' ) ? 'green' : 'grey'">
                {{ (cacheState['GEN'][item.lay_sta_id]) ? cacheState['GEN'][item.lay_sta_id].sta_label : '-' }}
            </v-chip>
        </template>
        <template v-slot:[`item.lay_typ_id`]="{ item }">
            <v-chip label outlined color="blue">{{ (cacheType['SPR'][item.lay_typ_id]) ? cacheType['SPR'][item.lay_typ_id].typ_name : '-' }}</v-chip>
        </template>
        <template v-slot:[`item.lay_updated_at`]="{ item }">
        {{ locDateFormat.toLocale(item.lay_updated_at, { year: 'numeric', month: 'long', day: 'numeric' }) }}
        </template>
        <template v-slot:[`item.lay_color`]="{ item }">
            <v-btn small :color="item.lay_color"></v-btn>
        </template>
        <template v-slot:[`item.lay_id`]="{ item }">
            <v-btn color="grey" outlined :to="'/layers/' + item.lay_id">Modifier</v-btn>
            <v-btn class="ml-2" color="red" outlined @click="deleteItem(item.lay_id)">
                <v-icon>mdi-delete</v-icon>
            </v-btn>
        </template>
        <template slot="no-data">
            <v-alert :value="true" color="info" class="mt-2" icon="mdi-information-outline">Aucune données</v-alert>
        </template>
    </v-data-table>
    </v-card>
    <v-layout wrap>
        <v-flex xs6 class="text-left pl-8">
            <span>{{pagination.totalItems}} résultats</span>
        </v-flex>
        <v-flex xs6 class="text-right pr-0">
            <v-pagination v-model="pagination.page" :length="pagination.pageCount" :total-visible="7" ></v-pagination> <!-- :length="pages" @input="handlePage" -->
        </v-flex>
    </v-layout>
</v-container>
</template>

<script>
import dateLocale from '../services/dateLocale'
import { cacheGetters, cacheMutations } from "../store/cache.module"

export default {
    name: 'layers',
    props: [],
    data () {
        return {
            max25chars: (v) => v.length <= 25 || 'Trop de charactères',
            tmp: '',
            search: '',
            pagination: { page: 1, totalItems: 0, itemsPerPage: 12, totalPage: 1, limit: 0, pageCount: 0 },
            headers: [
            { text: 'Nom',   align: 'left',   value: 'lay_name'},
            { text: 'Etat',     align: 'left',     value: 'lay_sta_id'},
            { text: 'Couleur', align: 'left', value: 'lay_color'},
            { text: 'Source',    align: 'left',    value: 'lay_typ_id'},
            { text: 'Modification',    align: 'left',   value: 'lay_updated_at'},
            { text: 'Action',    align: 'left',   value: 'lay_id'},
            ],
            items: [],
            locDateFormat: dateLocale, // Format de date
            cacheType: cacheGetters.getTypes(),
            cacheState: cacheGetters.getStates(),
            enableAdd: true,
            modalEvent : false
        }
    },
    computed: {
        pages () {
            if (this.pagination.totalItems == null || this.pagination.totalItems == null ) return 0
            return Math.ceil(this.pagination.totalItems / this.pagination.itemsPerPage)
        },
    },
    mounted: function (){
        this.search = cacheGetters.getSearchBarItem('layers')
        this.getLayers()
    },
    methods: {
        getLayers(){
            this.items = []
            var searchCriteria = '?page='+ this.pagination.page +'&per_page=false' // + this.pagination.itemsPerPage
            searchCriteria    += '&extended=true' //'&order_by=trs_name&order_direction=desc'
            this.$http.get( '/layers/' + searchCriteria ).then( (response) => {
                if( response.data.meta.pagination.total !== undefined && response.data.meta.pagination.total !== '0' ){
                    this.pagination.totalItems  = parseInt(response.data.meta.pagination.total)
                } else {
                    this.pagination.totalItems = 1
                }
                if( Object.prototype.toString.call( response.data.data ) === '[object Array]' ) {
                    this.items = response.data.data
                }
                //this.items = response.data.data
            })
        },
        saveVersion(){
                this.getEvent()
                this.enableAdd = true
        },
        downloadSite(){
            this.$root.$toast({ color: 'success', text: 'Fonction non disponible !' })
        },
        handlePage(index){
            this.pagination.limit = this.pagination.rowsPerPage * (index - 1)
            //this.pagination.page  = (index - 1)
            this.getLayers()
        },
        saveSearchToCache(){
            cacheMutations.setSearchBarItem('layers', this.search)
        },
        deleteItem(id){
            this.$root.$confirm('Supprimer la couche (' + id + ')', 'Confirmez vous cette action ?', { color: 'red', width: 400 }).then((confirm) => {
                if( confirm ){
                    this.$http.delete( '/layers/' + id).then( () => {
                        this.$root.$toast({ color: 'success', text: 'Action enregistrée !' })
                        this.getLayers()
                        this.switchModal('-', 0, true)
                    })
                }
            })
        },
        getCacheType: cacheGetters.getTypes
    },
    components: {
        
    }
}
</script>

<style lang="css">

</style>