<template>
<v-card>
    <v-card-text>
    <v-layout row wrap>
        <v-flex xs12 class="pa-2">
            <v-text-field label="Nom" v-model="objDocument.doc_name"></v-text-field>
        </v-flex>
        <v-flex xs6 class="pa-2">
            <v-select 
                :items="types" item-text="typ_name" item-value="typ_id" 
                v-model="objDocument.doc_typ_id" label="Type de fichier" >
            </v-select>
        </v-flex>
        <v-flex xs6 class="pa-2">
            <v-select 
                :items="table" item-text="table_name" item-value="table_id" 
                v-model="objDocument.doc_table_id" label="Association" disabled >
            </v-select>
        </v-flex>
        <v-flex xs12 class="pa-2">
            <v-text-field label="Description" v-model="objDocument.doc_description"></v-text-field>
        </v-flex>
        <v-flex xs12 class="pa-2">
            <v-file-input @change="getFileDocument" ref="documentfile" v-model="objDocument.obj_file" show-size label="Fichier"></v-file-input>
        </v-flex>
    </v-layout>
    </v-card-text>
    <v-divider light></v-divider>
    <v-card-actions>
        <v-menu v-if="objDocument.eve_id > 0" v-model="optionMenu" offset-y transition="slide-x-transition" bottom right >
            <template v-slot:activator="{ on }">
            <v-btn v-on="on" outlined class="ml-0 pa-0" style="min-width: 5px;" color="grey"><v-icon>mdi-dots-vertical</v-icon></v-btn>
            </template>
            <v-btn class="ma-0" color="error" @click.native="deleteDocument()" :disabled="saveProgress">
                <v-icon left>mdi-trash-can</v-icon>Supprimer
            </v-btn>
        </v-menu>
        <v-spacer></v-spacer>
        <v-btn v-if="objDocument.doc_sta_id == 'GEN01'" rounded color="success" 
            :loading="saveProgress" @click.native="saveDocument()" :disabled="saveProgress">
            Enregistrer
        </v-btn>
        <!-- <v-btn v-if="(objDocument.doc_id && objDocument.doc_sta_id !== 'GEN01')" rounded color="success" 
            :loading="saveProgress" @click.native="saveDocument('GEN01')" :disabled="saveProgress">
            <v-icon>mdi-delete-off-outline</v-icon> Restaurer
        </v-btn> -->
        <v-btn rounded outlined color="grey" @click="switchModal('modalDocument', 0)">
            Fermer
        </v-btn>
    </v-card-actions>
</v-card>
</template>

<script>
import dateLocale from '../services/dateLocale';
import { docObj } from '../mixins/doc_obj.js'
import { cacheGetters } from "../store/cache.module"

export default {
    name: 'documentedit',
    props: ['xxxId', 'docId', 'docTable', 'docType', 'switchModal', 'withFile'],
    mixins: [ docObj ],
    data: function () {
        return {
            dateMenu: { date_start:false, date_end:false },
            saveProgress: false,         // Enregistrement en cours...
            locDateFormat: dateLocale, // Format de date
            states : [],
            types : [],
            table : [
                { table_name: 'Site',                table_id: 'trs' },
                { table_name: 'Évènement',           table_id: 'eve' },
                { table_name: 'Convention',          table_id: 'tcv' },
                { table_name: 'Cachier des charges', table_id: 'spf' },
            ],
            optionMenu: false,
        }
    },
    mounted:function (){
        this.types = this.types.concat( cacheGetters.getTypes('DOC') )
        if( this.docId !== undefined && this.docId > 0 ){
            this.objDocument.doc_id = this.docId
            this.getDocumentShow()
        } else {
            this.cleanObjDocument()
            this.objDocument.doc_id       = 0
            this.objDocument.doc_typ_id   = this.types[0].typ_id
            this.objDocument.doc_table_id = this.docTable
            this.objDocument.doc_xxx_id   = this.xxxId
            this.objDocument.doc_usr_id_created   = 1
            if( this.withFile != undefined ){
                this.objDocument.obj_file = this.withFile
                this.getFileDocument(this.withFile)
            }
        }
    },
    methods: {
        saveDocument(state){
            this.saveProgress = true
            this.objDocument.doc_sta_id = (state) ? state : this.objDocument.doc_sta_id
            if( this.objDocument.doc_id !== undefined && this.objDocument.doc_id > 0 ){
                this.putDocumentUpdate().then( () => {
                    this.saveProgress = false
                    this.switchModal('modalDocument', 0, true)
                })
            } else {
                this.postDocumentStore().then( () => {
                    this.saveProgress = false
                    this.switchModal('modalDocument', 0, true)
                })
            }
        },
        cancelEdit(){
            console.log('fonction')
        },
        deleteDocument(){
            this.deleteDocumentDocument( () => {
                this.switchModal('modalDocument', 0, true)
            })
        },
        getFileDocument(file){
            if( this.objDocument.doc_name.length == 0 ){
                let nameTab = file.name.split(".")
                this.objDocument.doc_name = nameTab[0]
            }
            this.objDocument.doc_filename = file.name
        },
    },
    components: { }
}
</script>