<template>
<v-container fluid class="pa-0">
    <v-toolbar class="elevation-2" height="48px">
        <v-btn icon href="/#/habitats/"><v-icon>mdi-arrow-left-thick</v-icon></v-btn>
        <v-toolbar-title >Habitat </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-chip outlined color="blue" v-if="form.hab_updated_at">
            <v-icon left>mdi-information-outline</v-icon>Modification: {{ locDateFormat.formatLocale(form.hab_updated_at, 'fr') }}
        </v-chip>
    </v-toolbar>
<!-- Formulaire -->
    <v-card fluid class="ma-2 pa-5 elevation-2">
        <v-layout row wrap>
        <v-flex xs12 class="pa-2">
            <v-text-field label="Nom"    v-model="form.hab_name" ></v-text-field>
        </v-flex>
        <v-flex xs3 class="pa-2">
            <v-checkbox :true-value="1" :false-value="0" v-model="form.hab_simplified" label="Habitat simplifié"></v-checkbox>
        </v-flex>
        <v-flex xs3 class="pa-2">
            <v-checkbox :true-value="1" :false-value="0" v-model="form.hab_site_impact" label="Impact site"></v-checkbox>
        </v-flex>
        </v-layout>
    </v-card>
    <div class="ma-4 text-right">
        <v-btn color="success" class="mr-3" :loading="saveProgress" @click.native="saveForm()" :disabled="saveProgress">Enregistrer</v-btn>
        <v-btn color="primmary" href="/#/habitats/">Retour</v-btn>
    </div>
</v-container>
</template>

<script>
import dateLocale from '../services/dateLocale';

export default {
    name: 'habitatedit',
    props: [],
    data: function () {
        return {
            saveProgress: false,         // Enregistrement en cours...
            form:{
                hab_id : 0, hab_name : '',
                hab_simplified : 1, hab_site_impact : 1, hab_usr_id_updated : 0, hab_updated_at: null
            },
            locDateFormat: dateLocale, // Format de date
        }
    },
    mounted:function (){
        if( parseInt( this.$route.params.hab_id ) > 0 && this.$route.params.hab_id !== undefined ){
            this.$http.get( '/habitats/' + this.$route.params.hab_id )
            .then( (response) => {
                var prop
                for( prop in response.data.data ){
                    if( this.form[prop] !== undefined ){
                        this.form[prop] = response.data.data[prop] 
                    }
                }
            })
            .catch( (error) => {
                this.$root.$toast({ color: 'warning', text: error })
            })
        }
    },
    methods: {
        saveForm () {
            // Enregistrement du formulaire
            this.saveProgress = true
            if( parseInt( this.$route.params.hab_id ) > 0 ){
                this.$http.put( '/habitats/' + this.$route.params.hab_id, this.form )
                .then( () => {
                    this.$root.$toast({ color: 'success', text: 'Fiche enregistrée !' })
                    this.$router.push({ path: '/habitats' })
                }).catch( (error) => {
                    this.$root.$toast({ text: 'Erreur lors de l\'enregistrement !', error: error })
                    this.saveProgress = false
                });
            } else {
                this.$http.post( '/habitats/', this.form )
                .then( () => {
                    this.$root.$toast({ color: 'success', text: 'Fiche enregistrée !' })
                    this.$router.push({ path: '/habitats' })
                }).catch( (error) => {
                    this.$root.$toast({ text: 'Erreur lors de l\'enregistrement !', error: error })
                });
            }
        }
    },
    components: { }
}
</script>