<template>
<v-container fluid>
<v-layout wrap>
    <v-flex xs6 style="max-width:100%">
        <v-card class="elevation-1 ma-2">
            <v-toolbar flat dark color="green" >
                <v-toolbar-title><v-icon>mdi-file-excel</v-icon>Export par convention</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-icon>mdi-download</v-icon>
            </v-toolbar>
            <v-container fluid grid-list-xl >
            <v-layout align-center wrap >
                <v-flex xs12>
                    <v-menu ref="datebyconvention" v-model="dateMenu.date_byconvention"
                        :return-value.sync="dateReport.date_byconvention"
                        :close-on-content-click="false" :nudge-right="40" transition="scale-transition" 
                        offset-y min-width="290px" style="z-index:1500">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field 
                                v-bind="attrs" :value="locDateFormat.formatLocale( dateReport.date_byconvention, 'fr' )"
                                label="Date de référence de l'extraction" prepend-icon="mdi-calendar-today" readonly v-on="on" >
                            </v-text-field>
                        </template>
                        <v-date-picker locale="fr-fr" no-title scrollable v-model="dateReport.date_byconvention">
                            <v-spacer></v-spacer>
                            <v-btn color="primary" @click="dateMenu.date_byconvention = false">Annuler</v-btn>
                            <v-btn color="primary" @click="$refs.datebyconvention.save( dateReport.date_byconvention )">OK</v-btn>
                        </v-date-picker>
                    </v-menu>
                </v-flex>
            </v-layout>
            </v-container>
            <v-divider class="mt-5"></v-divider>
            <v-card-actions>
                <!-- <v-checkbox v-model="checkState.withConvention" label="Uniquement avec convention" class="mt-0"></v-checkbox> -->
                <v-spacer></v-spacer>
                <v-btn rounded color="success" @click="downloadReport('sheetbyconvention', { withConvention: checkState.withConvention })" :disabled="loading.getItem">
                    <v-icon left dark>mdi-content-save</v-icon>
                    <span class="hidden-sm-and-down">Telecharger</span>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-flex>
    <v-flex xs6 style="max-width:100%">
        <v-card class="elevation-1 ma-2">
            <v-toolbar flat dark color="primary" >
                <v-toolbar-title>
                    <v-btn icon><v-icon>mdi-shape-polygon-plus</v-icon></v-btn>
                    Sites
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <!-- <span class="caption">Dernier export :</span> -->
                <v-chip small outlined label light color="white">
                    ---
                </v-chip>
            </v-toolbar>
            <v-container fluid grid-list-xl >
            <v-layout align-center wrap >
                <v-flex xs12><v-select v-model="trsfields" :items="items" attach chips label="Champs" full-width multiple ></v-select></v-flex>
            </v-layout>
            </v-container>
            <v-divider class="mt-5"></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn rounded color="success" :disabled="loading.getItem" @click="downloadReport('trackingsheet', { include: trsfields })">
                    <v-icon left dark>mdi-content-save</v-icon>
                    <span class="hidden-sm-and-down">Telecharger</span>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-flex>
<!--    <v-flex xs6>
        <v-card class="elevation-1 ma-2">
            <v-toolbar flat dark color="orange" >
                <v-toolbar-title>
                    <v-btn icon><v-icon>mdi-shape-polygon-plus</v-icon></v-btn>
                    Par départements
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <span class="caption">Dernier export :</span>
                <v-chip small outlined label light color="white">
                    2019/04/11
                </v-chip>
            </v-toolbar>
            <v-container fluid grid-list-xl >
            <v-layout align-center wrap >
                <v-flex xs12><v-select v-model="value" :items="departement" attach chips label="Départements" full-width multiple ></v-select></v-flex>
                <v-flex xs12><v-select v-model="value" :items="items" attach chips label="Champs" full-width multiple ></v-select></v-flex>
            </v-layout>
            </v-container>
            <v-divider class="mt-5"></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn rounded color="success" disabled>
                    <v-icon left dark>mdi-content-save</v-icon>
                    <span class="hidden-sm-and-down">Telecharger</span>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-flex>
    <v-flex xs6>
        <v-card class="elevation-1 ma-2">
            <v-toolbar flat dark color="green" >
                <v-toolbar-title>
                    <v-btn icon><v-icon>mdi-shape-polygon-plus</v-icon></v-btn>
                    Par bassins versants
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <span class="caption">Dernier export :</span>
                <v-chip small outlined label light color="white">
                    2019/08/01
                </v-chip>
            </v-toolbar>
            <v-container fluid grid-list-xl >
            <v-layout align-center wrap >
                <v-flex xs12><v-select v-model="value" :items="bv" attach chips label="Bassins versants" full-width multiple ></v-select></v-flex>
                <v-flex xs12><v-select v-model="value" :items="items" attach chips label="Champs" full-width multiple ></v-select></v-flex>
            </v-layout>
            </v-container>
            <v-divider class="mt-5"></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn rounded color="success" disabled>
                    <v-icon left dark>mdi-content-save</v-icon>
                    <span class="hidden-sm-and-down">Telecharger</span>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-flex> -->
</v-layout>
</v-container>
</template>

<script>
import dateLocale from '../services/dateLocale';
import { cacheGetters } from "../store/cache.module"
import { trsObj }  from '../mixins/trs_obj.js'

export default {
    name: 'site_report',
    props: [], 
    mixins: [ trsObj ],
    data: function () {
        return {
            locDateFormat: dateLocale, // Format de date
            loading: { getItem: false },
            checkState: {
                withConvention: false,
            },
            dateMenu: { 
                date_byconvention   : null,
                date_byspecification: null,
                date_event_start    : null,
                date_event_end      : null,
                date_inv_start    : null,
                date_inv_end      : null,
            },
            dateReport : {
                date_byconvention   : null,
                date_byspecification: null,
                date_event_start    : null,
                date_event_end      : null,
                date_inv_start    : null,
                date_inv_end      : null,
            },
            repLoading: false,
            repDiag: false,
            state : cacheGetters.getTypesByTag('EVE', 'PRO'),
            items: [
                { text: 'CODDT', value: 'trs_coddt'}, 
                { text: 'Code MC2', value: 'trs_mc2_code' },
                { text: 'Nom site', value: 'trs_name' },
                { text: 'Type de site', value: 'typ_name' },
                { text: 'Commune', value: 'tow_name' },
                { text: 'Bassin versant', value: 'was_name' },
                { text: 'Surface', value: 'trs_area_plot'},
            ],
            trsfields: ["trs_mc2_code", "trs_name", "typ_name", "tow_name", "trs_area_plot"],
            bv: ['Rhône-Méditerranée-Corse','Rhin-Meuse','Loire-Bretagne','Seine-Normandie','Adour-Garonne','Artois-Picardie'],
            departement: ["AIN","AISNE","ALLIER","HAUTES-ALPES","ALPES-DE-HAUTE-PROVENCE","ALPES-MARITIMES"]
        }
    },
    mounted:function (){
       
    },
    methods: {
        downloadReport(report, option){
            let fileName         = ( option && option.filename ) ? option.filename : 'rapport.xlsx'
            this.loading.getItem = true
            let searchCriteria   = this.getTrackingSheetFilter()
            for(let prop in option){
                if( option[prop] !== null && option[prop] !== undefined ){
                    searchCriteria += '&' + prop + '=' + option[prop]
                }
            }
            this.$http.customRequest({ method: 'get', url: '/reportextern/' + report + '/' + searchCriteria, responseType: 'blob' } )
            .then( (response) => {
                this.$download(response.data, fileName, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
                this.loading.getItem = false
            }).catch( (error) => {
                this.$root.$toast({ text: 'Erreur lors de l\'extraction !', error: error })
                this.loading.getItem = false
            })
        }, 
        getReport(){
            this.repLoading = true
            this.$http.customRequest( { method: 'post', url: '/', responseType: 'blob', data: {  _method:'POST', _path:'reportdreal' } } )
            .then( (response) => {
                this.repLoading = false
                this.repDiag = false
                this.$download(response.data, "rapport_1.zip", "application/zip")
            })
        }
    },
    components: { }
}
</script>