//import { scopeCredential } from "./scopeCredential"

const ChMain      = () => import(/* webpackChunkName: "build-glob" */ './layouts/Main.vue') 
const ChDasboard  = () => import(/* webpackChunkName: "build-glob" */ './pages/Dashboard.vue')
const ChListTrs   = () => import(/* webpackChunkName: "build-glob" */ './pages/TrackingSheet.vue')
const ChMap       = () => import(/* webpackChunkName: "build-map" */ './pages/Map.vue')
const ChDocument  = () => import(/* webpackChunkName: "build-doc" */ './pages/Document.vue')
const ChPlanning  = () => import(/* webpackChunkName: "build-foncea" */ './pages/Planning.vue')
const ChViewTrs   = () => import(/* webpackChunkName: "build-glob" */ './components/View_trackingSheet.vue')
const ChSlider    = () => import(/* webpackChunkName: "build-glob" */ './components/Slider.vue')
//const ChPreViewTrs= () => import(/* webpackChunkName: "build-glob" */ './components/View_preview_sheet.vue')
const CttView     = () => import(/* webpackChunkName: "build-glob" */ './pages/Contacts_edit.vue')
const GanDocument = () => import(/* webpackChunkName: "build-foncea" */ './pages/Gandocument.vue')
const GanDownload = () => import(/* webpackChunkName: "build-foncea" */ './pages/Gandownload.vue')
const GanParcelImport = () => import(/* webpackChunkName: "build-foncea" */ './pages/Ganparcel_import.vue')
export default {
menu : [
    { scope: 'Dashboard', right: 'index', 'href': '/dashboard', 'title': 'Tableau de bord', 'icon': 'mdi-view-dashboard', 'role': ['ADMIN_USER', 'SUPER_USER', 'INTER_USER', 'EXTER_USER'], 'appmode': 'compensea' },
    { scope: 'TrackingSheet', right: 'index', 'href': '/trackingsheet', 'title': 'Sites', 'icon': 'mdi-format-list-checks', 'role': ['ADMIN_USER', 'SUPER_USER', 'INTER_USER', 'EXTER_USER'], 'appmode': 'compensea' },
    { scope: 'Map',       right: 'index', 'href': '/map', 'title': 'Carte', 'icon': 'mdi-map', 'role': ['ADMIN_USER', 'SUPER_USER', 'INTER_USER', 'EXTER_USER'], 'appmode': 'compensea' },
    { scope: 'Documents', right: 'index', 'href': '/documents', 'title': 'Documents', 'icon': 'mdi-briefcase', 'role': ['ADMIN_USER', 'SUPER_USER', 'INTER_USER', 'EXTER_USER'], 'appmode': 'compensea' },
    { scope: 'Calendrier', right: 'index', 'href': '/planning', 'title': 'Calendrier', 'icon': 'mdi-briefcase', 'role': ['ADMIN_USER', 'SUPER_USER', 'INTER_USER', 'EXTER_USER'], 'appmode': 'compensea' },
    //{ scope: 'Billing',   right: 'index', 'href': '/invoicesdashboard', 'title': 'Facturation', 'icon': 'mdi-receipt', 'role': ['ADMIN_USER', 'SUPER_USER'], 'appmode': 'compensea',
    //    items: [ 
    //        { scope: 'Billing', right: 'index', 'href': '/invoices', 'title': 'Liste des factures', 'icon': '', 'role': ['ADMIN_USER', 'SUPER_USER', 'INTER_USER'], 'appmode': 'compensea' } 
    //    ]
    //},
    { scope: 'Report', right: 'index', divider: true, 'appmode': 'compensea' },
    { scope: 'Report', right: 'index', 'header': 'Reporting', 'role': ['ADMIN_USER', 'SUPER_USER', 'INTER_USER', 'EXTER_USER'], 'appmode': 'compensea' },
    { scope: 'Report', right: 'index', 'href': '/reports', 'title': 'Exports', 'icon': 'mdi-file-chart', 'role': ['ADMIN_USER', 'SUPER_USER', 'INTER_USER', 'EXTER_USER'], 'appmode': 'compensea' },
    //{ 'href': '/reportsext', 'title': 'Export DREAL', 'icon': 'mdi-database-export', 'role': ['ADMIN_USER', 'SUPER_USER', 'INTER_USER', 'EXTER_USER'], 'appmode': 'compensea' },
    { scope: 'Generic', right: 'index', divider: true, 'appmode': 'compensea' },
    { scope: 'Generic', right: 'index', 'header': 'Paramètres',      'title': 'Paramètres', 'icon': 'mdi-cogs', 'role': ['ADMIN_USER', 'SUPER_USER', 'INTER_USER', 'PARTE_USER'], 'appmode': 'compensea', 
        items: [
            { scope: 'Generic',     right: 'update', 'href': '/layers',       'title': 'Couches SIG', 'icon': 'mdi-layers', 'role': ['ADMIN_USER', 'SUPER_USER'], 'appmode': 'compensea' },
            { scope: 'TypePolicy',  right: 'update', 'href': '/habitats',     'title': 'Habitats', 'icon': 'mdi-home-map-marker', 'role': ['ADMIN_USER', 'SUPER_USER'], 'appmode': 'compensea' },
            { scope: 'TypePolicy',  right: 'update', 'href': '/sar',          'title': 'Région agricole', 'icon': 'mdi-tractor', 'role': ['ADMIN_USER', 'SUPER_USER'], 'appmode': 'compensea' },
            { scope: 'TypePolicy',  right: 'update', 'href': '/speciesgroup', 'title': 'Groupe espèces', 'icon': 'mdi-group', 'role': ['ADMIN_USER', 'SUPER_USER'], 'appmode': 'compensea' },
            { scope: 'Generic',     right: 'update', 'href': '/species',      'title': 'Espèces', 'icon': 'mdi-ladybug', 'role': ['ADMIN_USER', 'SUPER_USER'], 'appmode': 'compensea' },
            { scope: 'TypePolicy',  right: 'update', 'href': '/watershed',    'title': 'Bassin versants', 'icon': 'mdi-waves', 'role': ['ADMIN_USER', 'SUPER_USER'], 'appmode': 'compensea' },
            { scope: 'StatePolicy', right: 'update', 'href': '/states',       'title': 'Gestion états', 'icon': 'mdi-tag-multiple', 'role': ['ADMIN_USER'], 'appmode': 'compensea' },
            { scope: 'TypePolicy',  right: 'update', 'href': '/types',        'title': 'Gestion des types', 'icon': 'mdi-format-list-bulleted-type', 'role': ['ADMIN_USER'], 'appmode': 'compensea' },
            { scope: 'TypePolicy',  right: 'update', 'href': '/contacts',     'title': 'Gestion des contacts', 'icon': 'mdi-account-box-multiple', 'role': ['ADMIN_USER', 'SUPER_USER', 'INTER_USER', 'PARTE_USER'], 'appmode': 'compensea' },
            { scope: 'TypePolicy',  right: 'update', 'href': '/convention',   'title': 'Gestion des convention', 'icon': 'mdi-clipboard-text', 'role': ['ADMIN_USER', 'SUPER_USER', 'INTER_USER'], 'appmode': 'compensea' },
            { scope: 'TypePolicy',  right: 'update', 'href': '/indexation',   'title': 'Gestion des indexations', 'icon': 'mdi-chart-bell-curve-cumulative', 'role': ['ADMIN_USER', 'SUPER_USER', 'INTER_USER'], 'appmode': 'compensea' },
            { scope: 'Generic',     right: 'update', 'href': '/coderef',      'title': 'Type CDC / PDR', 'icon': 'mdi-code-equal', 'role': ['ADMIN_USER', 'SUPER_USER', 'INTER_USER'], 'appmode': 'compensea' },
        ] 
    },
    //FONCEA
    { scope: 'GanDocumentPolicy', right: 'index', 'href': '/foncea', 'title': 'Documents', 'icon': 'mdi-briefcase', 'role': ['ADMIN_USER', 'ADMIN_FONCEA_USER', 'SUPER_FONCEA_USER', 'MANAGER_FONCEA_USER', 'VIEW_FONCEA_USER'], 'appmode': 'foncea' },
    { scope: 'GanPlotPolicy', right: 'index', divider: true, 'appmode': 'foncea' },
    { scope: 'GanPlotPolicy', right: 'update', 'header': 'Outils', 'role': ['ADMIN_USER', 'ADMIN_FONCEA_USER', 'SUPER_FONCEA_USER'], 'appmode': 'foncea' },
    { scope: 'GanPlotPolicy', right: 'update', 'href': '/fonceaplot', 'title': 'Import parcelle', 'icon': 'mdi-file-chart', 'role': ['ADMIN_USER', 'ADMIN_FONCEA_USER', 'SUPER_FONCEA_USER'], 'appmode': 'foncea' },
    //SYSTEM
    { scope: 'Generic', right: 'update', divider: true, 'appmode': 'compensea,foncea' },
    { scope: 'Generic', right: 'update', 'header': 'Systeme', 'role': ['ADMIN_USER', 'SUPER_USER', 'ADMIN_FONCEA_USER' ], 'appmode': 'compensea,foncea' },
    { scope: 'Users',   right: 'update', 'href': '/users', 'title': 'Utilisateurs',   'icon': 'mdi-account', 'role': ['ADMIN_USER', 'SUPER_USER', 'ADMIN_FONCEA_USER' ], 'appmode': 'compensea,foncea' },
    { scope: 'Sychro' , right: 'index', 'href': '/sync',  'title': 'Outils',  'icon': 'mdi-sync', 'role': ['ADMIN_USER', 'SUPER_USER'], 'appmode': 'compensea' },
    //{ 'href': '/actionhistory',  'title': 'Historique',  'icon': 'mdi-clipboard-list-outline', 'role': ['ADMIN_USER', 'SUPER_USER'], 'appmode': 'compensea' },

],
routes : [
    { name:'login', path: '/login',   component: require('./layouts/Login.vue').default, 
        meta: {
            public: true,  // Allow access to even if not logged in
            onlyWhenLoggedOut: true,
            appmode: ['compensea', 'foncea']
        }
    },
    { name:'loginsso', path: '/login/:authcode',   component: require('./layouts/Login.vue').default, 
        meta: {
            public: true,  // Allow access to even if not logged in
            onlyWhenLoggedOut: true,
            appmode: ['compensea', 'foncea']
        }
    },
    { name:'logout', path: '/logout',   component: require('./layouts/Logout.vue').default, meta: { appmode: ['compensea', 'foncea'] } },
    { name: '404', path: '*', component: require('./layouts/404.vue').default, meta: { appmode: ['compensea', 'foncea'] } },
    { name: '401', path: '/401', component: require('./layouts/401.vue').default, meta: { appmode: ['compensea', 'foncea'] } },
    { path: '/', component: ChMain,
        children: [
            //{ name: 'home', path: '/', redirect: '/dashboard' },
            { name: 'dashboard', path: '/dashboard', 
                components: { default: ChDasboard, modal: ChSlider },
                children: [
                  { name: 'dash_slide_prev', path: 'site/:trs_id', meta: { component: 'siteView' }, components: { content: ChViewTrs } },
                  { name: 'dash_slide_edit', path: 'site/:trs_id/modifier', components: { content: ChViewTrs } },
                  { name: 'dash_slide_view', path: 'site/:trs_id/consulter', components: { content: ChViewTrs } }
                ]
            },
            { name: 'trackingsheet', path: '/trackingsheet', 
                components: { default: ChListTrs, modal: ChSlider },
                children: [
                  { name: 'trs_slide_prev', path: 'site/:trs_id', meta: { component: 'siteView' }, components: { content: ChViewTrs } },
                  { name: 'trs_slide_edit', path: 'site/:trs_id/modifier', components: { content: ChViewTrs } },
                  { name: 'trs_slide_view', path: 'site/:trs_id/consulter', components: { content: ChViewTrs } }
                ]
            },
            { name: 'map', path: '/map', 
                components: { default: ChMap, modal: ChSlider },
                children: [
                  { name: 'map_slide_cent', path: 'center/:latlng' },
                  { name: 'map_slide_prev', path: 'site/:trs_id', meta: { component: 'siteView' }, components: { content: ChViewTrs } },
                  { name: 'map_slide_edit', path: 'site/:trs_id/modifier', components: { content: ChViewTrs } },
                  { name: 'map_slide_view', path: 'site/:trs_id/consulter', components: { content: ChViewTrs } }
                ]
            },
            { name: 'document', path: '/documents', 
                components: { default: ChDocument, modal: ChSlider },
                children: [
                  { name: 'doc_slide_prev', path: 'site/:trs_id', meta: { component: 'siteView' }, components: { content: ChViewTrs } },
                  { name: 'doc_slide_edit', path: 'site/:trs_id/modifier', components: { content: ChViewTrs } },
                  { name: 'doc_slide_view', path: 'site/:trs_id/consulter', components: { content: ChViewTrs } }
                ]
            },
            { name: 'planning', path: '/planning', 
                components: { default: ChPlanning, modal: ChSlider },
                children: [
                  { name: 'pla_slide_prev', path: 'site/:trs_id', meta: { component: 'siteView' }, components: { content: ChViewTrs } },
                  { name: 'pla_slide_edit', path: 'site/:trs_id/modifier', components: { content: ChViewTrs } },
                  { name: 'pla_slide_view', path: 'site/:trs_id/consulter', components: { content: ChViewTrs } }
                ]
            },
            //{ name: 'planning',      path: '/planning', component: require('./pages/Planning.vue').default },
            { name: 'invoicesdashboard', path: '/invoicesdashboard', component: require('./pages/Invoices_dashboard.vue').default },
            { name: 'invoices',          path: '/invoices',          component: require('./pages/Invoices.vue').default },

            { name: 'report',        path: '/reports', component: require('./pages/Report_site.vue').default },
            { name: 'reportext',     path: '/reportsext', component: require('./pages/Report_ext.vue').default },
            
            { name: 'sitesedit',     path: '/site/:trs_id', component: require('./pages/Site_edit.vue').default },
            { name: 'layers',        path: '/layers', component: require('./pages/Layer.vue').default },
            { name: 'layeredit',     path: '/layers/:lay_id',  component: require('./pages/Layer_edit.vue').default },
            { name: 'users',         path: '/users', component: require('./pages/Users.vue').default, meta: { appmode: ['compensea', 'foncea'] } },
            { name: 'useredit',      path: '/users/:usr_id',  component: require('./pages/User_edit.vue').default, meta: { appmode: ['compensea', 'foncea'] } },
            { name: 'habitat',       path: '/habitats', component: require('./pages/Habitat.vue').default },
            { name: 'habitatedit',   path: '/habitats/:hab_id', component: require('./pages/Habitat_edit.vue').default },
            { name: 'indexation',    path: '/indexation', component: require('./pages/Indexation.vue').default },
            { name: 'indexationedit',path: '/indexation/:inr_id', component: require('./pages/Indexation_edit.vue').default },
            { name: 'sar',           path: '/sar', component: require('./pages/SmallAgrRegion.vue').default },
            { name: 'saredit',       path: '/sar/:sar_id', component: require('./pages/SmallAgrRegion_edit.vue').default },
            { name: 'speciesgroup',  path: '/speciesgroup', component: require('./pages/SpeciesGroup.vue').default },
            { name: 'speciesgroupedit',path: '/speciesgroup/:spg_id', component: require('./pages/SpeciesGroup_edit.vue').default },
            { name: 'species',       path: '/species', component: require('./pages/Species.vue').default },
            { name: 'speciesedit',   path: '/species/:spe_id', component: require('./pages/Species_edit.vue').default },
            { name: 'speciesbysar',  path: '/compensationsar', component: require('./pages/Speciesbysar.vue').default },
            { name: 'speciesbysaredit',path: '/compensationsar/:csa_id', component: require('./pages/Speciesbysar_edit.vue').default },
            { name: 'watershed',     path: '/watershed', component: require('./pages/Watershed.vue').default },
            { name: 'watershededit', path: '/watershed/:was_id', component: require('./pages/Watershed_edit.vue').default },
            { name: 'states',        path: '/states', component: require('./pages/States.vue').default },
            { name: 'statesedit',    path: '/states/:sta_id',  component: require('./pages/States_edit.vue').default },
            { name: 'types',         path: '/types', component: require('./pages/Types.vue').default },
            { name: 'typesedit',     path: '/types/:typ_id', component: require('./pages/Type_edit.vue').default },
            { name: 'contacts',      path: '/contacts', component: require('./pages/Contacts.vue').default },
            { name: 'contactsedit',  path: '/contacts/:ctt_id', 
                components: { default: CttView, modal: ChSlider },
                children: [
                  { name: 'ctt_slide_prev', path: 'site/:trs_id', meta: { component: 'siteView' }, components: { content: ChViewTrs } },
                  { name: 'ctt_slide_edit', path: 'site/:trs_id/modifier', components: { content: ChViewTrs } },
                  { name: 'ctt_slide_view', path: 'site/:trs_id/consulter', components: { content: ChViewTrs } }
                ]
            },
            { name: 'specificationref',       path: '/coderef', component: require('./pages/Specificationref.vue').default },
            { name: 'specificationrefedit',   path: '/coderef/:cdr_id', component: require('./pages/Specificationref_edit.vue').default },
            { name: 'log',                    path: '/log', component: require('./layouts/403.vue').default },
            //{ name: 'contacts_view', path: '/contacts/:ctt_id', component: require('./pages/Contacts_edit.vue').default },
            { name: 'convention',    path: '/convention', component: require('./pages/Convention.vue').default },

            { name: 'synchro',      path: '/sync', component: require('./pages/SynchroMc2.vue').default, meta: { appmode: ['compensea'] } },
            //{ name: 'actionhistory',path: '/actionhistory', component: require('./pages/History.vue').default, meta: { appmode: ['compensea'] } },
            { name: 'forbidden',    path: '/forbidden', component: require('./layouts/403.vue').default, meta: { appmode: ['compensea', 'foncea'] } },

            { name: 'foncea',       path: '/foncea',    components: { default: GanDocument }, meta: { appmode: ['foncea'] } },
            { name: 'fonceaplot',   path: '/fonceaplot',   components: { default: GanParcelImport }, meta: { appmode: ['foncea'] } },
            { name: 'gandownload',  path: '/gandownload/:id', components: { default: GanDownload }, meta: { appmode: ['compensea', 'foncea'] } },
            
        ]
    }
]


}