var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-toolbar',{staticClass:"elevation-1",attrs:{"height":"48px"}},[_c('v-toolbar-title',[_vm._v("Gestion des indexations")]),_c('v-spacer'),_c('v-btn',{staticClass:"white--text",attrs:{"color":"success","depressed":"","to":"/indexation/0"}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v("mdi-plus-circle")]),_c('span',{staticClass:"hidden-sm-and-down"},[_vm._v("Créer")])],1)],1),_c('v-text-field',{staticClass:"ma-4 pa-0",attrs:{"append-icon":"mdi-magnify","label":"Recherche","solo":"","clearable":"","single-line":"","hide-details":""},on:{"blur":function($event){return _vm.saveSearchToCache()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-card',{staticClass:"ma-4"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"item-key":"sta_id","search":_vm.search,"items-per-page-text":"Lignes par page","items-per-page-options":_vm.pagination.rowsPerItem},scopedSlots:_vm._u([{key:"item.inr_date",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","outlined":""}},[_vm._v(_vm._s(_vm.locDateFormat.formatLocale( item.inr_date, 'fr' )))])]}},{key:"item.inr_typ_id",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","outlined":""}},[_vm._v(_vm._s(_vm.typeInr[item.inr_typ_id.substring(0, 3)][item.inr_typ_id].typ_name))])]}},{key:"item.inr_created_at",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","outlined":""}},[_vm._v(_vm._s(_vm.locDateFormat.formatLocale( item.inr_created_at, 'fr' )))])]}},{key:"item.inr_id",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"ml-2 elevation-1",attrs:{"outlined":"","small":"","color":"grey","href":'/#/indexation/' + item.inr_id + ''}},[_c('v-icon',{staticClass:"mr-2",attrs:{"left":""}},[_vm._v("mdi-pencil")]),_vm._v("Modifier ")],1)]}},{key:"pageText",fn:function(ref){
var pageStart = ref.pageStart;
var pageStop = ref.pageStop;
return [_vm._v(" De "+_vm._s(pageStart)+" à "+_vm._s(pageStop)+" ")]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }