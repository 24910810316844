<template>
<v-container fluid class="pa-0">
    <v-toolbar class="elevation-1" height="48px">
        <v-toolbar-title >Gestion des petites régions agricoles</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="success" depressed class="white--text" to="/sar/0">
            <v-icon left dark>mdi-plus-circle</v-icon>
            <span class="hidden-sm-and-down">Créer</span>
        </v-btn>
    </v-toolbar>
    <v-text-field
        append-icon="mdi-magnify" label="Recherche"
        solo clearable single-line hide-details v-model="search" @blur="saveSearchToCache()" class="ma-4 pa-0">
    </v-text-field>
    <v-card class="ma-4">
    <v-data-table v-bind:headers="headers" :items="items" item-key="sta_id" :search="search"
        items-per-page-text="Lignes par page" :items-per-page-options="pagination.rowsPerItem">
        <template v-slot:[`item.sar_concerned`]="{ item }">
            <v-simple-checkbox :value="(item.sar_concerned) ? true : false" disabled></v-simple-checkbox>
        </template>
        <template v-slot:[`item.sar_order`]="{ item }">
            <v-btn outlined small color="grey" class="ml-2 elevation-1" :href="'/#/sar/' + item.sar_id + ''">
                <v-icon left class="mr-2">mdi-pencil</v-icon>Modifier
            </v-btn>
        </template>
        <template slot="pageText" slot-scope="{ pageStart, pageStop }">
        De {{ pageStart }} à {{ pageStop }}
        </template>
    </v-data-table>
    </v-card>
</v-container>
</template>

<script>
import dateLocale from '../services/dateLocale';
import { cacheGetters, cacheMutations } from "../store/cache.module"

export default {
    name: 'sar',
    props: [],
    data: function () {
        return {
            saveProgress: false,         // Enregistrement en cours...
            search: '',
            pagination: { totalItems: 0, rowsPerPage: 10, rowsPerItem: [15,25,35,{text:'Tout',value:-1}] },
            headers: [
            { text: 'Code',    align: 'left', value: 'sar_code'},
            { text: 'Nom',     value: 'sar_name' },
            { text: 'Concerné', value: 'sar_concerned' },
            { text: 'Modification', align: 'right', value: 'sar_order', filterable: false, sortable:false}
            ],
            items: [

            ],
            locDateFormat: dateLocale,
            enableAdd: true,
            modalEvent : false
        }
    },
    computed: {
        pages () {
            if (this.pagination.rowsPerPage == null || this.pagination.totalItems == null ) return 0
            return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
        }
    },
    mounted: function (){
        this.search = cacheGetters.getSearchBarItem('sar') || ''
        this.getItems()
    },
    methods: {
        getItems(){
            this.$http.get( '/smallagrregion/?per_page=false' ).then( (response) => {
                this.items = response.data.data
            })
        },
        handlePage(index){
            this.pagination.limit = this.pagination.rowsPerPage * (index - 1)
            //this.pagination.page  = (index - 1)
        },
        saveSearchToCache(){
            cacheMutations.setSearchBarItem('sar', this.search)
        },
    },
    components: {
        
    }
}
</script>