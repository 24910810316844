<template>
<v-container fluid class="pa-0">
    <v-toolbar class="elevation-2" height="48px">
        <v-btn icon href="/#/species/"><v-icon>mdi-arrow-left-thick</v-icon></v-btn>
        <v-toolbar-title >Espèce</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-chip outlined color="blue" v-if="form.spe_updated_at">
            <v-icon left>mdi-information-outline</v-icon>Modification: {{ locDateFormat.formatLocale(form.spe_updated_at, 'fr') }}
        </v-chip>
    </v-toolbar>
<!-- Formulaire -->
    <v-card fluid class="ma-2 pa-5 elevation-2">
        <v-layout row wrap>
        <v-flex xs12 class="pa-2">
            <v-text-field label="Nom"     v-model="form.spe_name" ></v-text-field>
        </v-flex>
        <v-flex xs6 class="pa-2">
            <auto-multi v-if="!loading.getItem" :title="'Groupe d\'espèce'" :ressource="false" :listObject.sync="autoComplete.spe_spg_id"
                :listParent.sync="autoCompleteSource.spe" 
                :fields="{ index: 'spg_id', text:'spg_name', search:'spg_name', multiSelect:false, preprendIcon:'mdi-group' }">
            </auto-multi>
        </v-flex>
        <v-flex xs3 class="pa-2">
            <v-select 
                :items="typeSpe" v-model="form.spe_typ_id" label="Type d'espèce"
                item-text="typ_name" item-value="typ_id" >
            </v-select>
        </v-flex>
        <v-flex xs3 class="pa-2">
            <v-select 
                :items="getCacheType('DEB')" v-model="form.spe_typ_id_debt" label="Type de dette"
                item-text="typ_name" item-value="typ_id" >
            </v-select>
        </v-flex>
        <v-flex xs6 class="pa-2">
            <v-text-field label="Superficie exigible"  type="number"  v-model="form.spe_area_due" ></v-text-field>
        </v-flex>
        <v-flex xs6 class="pa-2">
            <v-text-field label="Linéaire exigible"  type="number"  v-model="form.spe_linear_due" ></v-text-field>
        </v-flex>
        </v-layout>
    </v-card>
    <v-toolbar extended flat color="transparent" class="elevation-0" >
        <v-btn color="red" @click.native="deleteItem()"><v-icon left>mdi-delete</v-icon></v-btn>
        <v-spacer></v-spacer>
        <v-btn color="success" class="mr-3" :loading="saveProgress" @click.native="saveItem()" :disabled="!validate">Enregistrer</v-btn>
        <v-btn color="primmary" href="/#/species/">Retour</v-btn>
    </v-toolbar>
</v-container>
</template>

<script>
import dateLocale from '../services/dateLocale';
import { cacheGetters } from "../store/cache.module"
import autoCompMulti    from '../components/Autocomplete_multi.vue'

export default {
    name: 'speciesedit',
    props: [],
    data: function () {
        return {
            locDateFormat: dateLocale, // Format de date
            saveProgress: false,         // Enregistrement en cours...
            loading: { getItem: true },
            typeSpe : cacheGetters.getTypes('SPE'),
            form:{
                spe_id	: 0, spe_spg_id : 0, spe_name : '', spe_typ_id : 'SPE01', spe_typ_id_debt: 'DEB01', spe_area_due : 0,
                spe_linear_due : null, spe_created_at : '', spe_updated_at : '',
            },
            autoComplete: {
                spe_spg_id: null,
            },
            autoCompleteSource:{
                spe: cacheGetters.getSpeciesGroup(),
            },
        }
    },
    computed: {
        validate () {
            if( !this.saveProgress && this.form.spe_name.length > 0 && this.autoComplete.spe_spg_id > 0 ){
                return true
            } else {
                return false
            }
            
        },
    },
    mounted:function (){
        if( parseInt( this.$route.params.spe_id ) > 0 && this.$route.params.spe_id !== undefined ){
            this.getItem()
        } else {
            this.loading.getItem = false
        }
    },
    methods: {
        getItem() {
            this.$http.get( '/species/' + this.$route.params.spe_id )
            .then( (response) => {
                var prop
                for( prop in response.data.data ){
                    if( this.form[prop] !== undefined ){
                        this.form[prop] = response.data.data[prop] 
                    }
                }
                this.autoComplete.spe_spg_id = this.form.spe_spg_id
                this.loading.getItem = false
            })
            .catch( (error) => {
                this.$root.$toast({ color: 'warning', text: error })
            })
        },
        saveItem () {
            // Enregistrement du formulaire
            this.saveProgress    = true
            this.form.spe_spg_id = this.autoComplete.spe_spg_id
            if( parseInt( this.$route.params.spe_id ) > 0 ){
                this.$http.put( '/species/' + this.$route.params.spe_id, this.form )
                .then( () => {
                    this.$root.$toast({ color: 'success', text: 'Fiche enregistrée !' })
                    this.$router.push({ path: '/species' })
                }).catch( (error) => {
                    this.$root.$toast({ text: 'Erreur lors de l\'enregistrement !', error: error })
                    this.saveProgress = false
                });
            } else {
                this.$http.post( '/species/', this.form )
                .then( () => {
                    this.$root.$toast({ color: 'success', text: 'Fiche enregistrée !' })
                    this.$router.push({ path: '/species' })
                }).catch( (error) => {
                    this.$root.$toast({ text: 'Erreur lors de l\'enregistrement !', error: error })
                });
            }
        },
        deleteItem(){
            this.$root.$confirm('Supprimer', 'Confirmer la suppression de cette fiche', { color: 'red', width: 500 }).then((confirm) => {
                if( confirm ){
                    this.$http.delete( '/species/' + this.form.spe_id ).then( () => {
                        this.$root.$toast({ color: 'success', top:false, bottom:true, right:false, left:true, text:'Fiche supprimée !' })
                        this.$router.push({ path: '/species' })
                    })
                }
            })
        },
        getCacheType: cacheGetters.getTypes,
    },
    components: {
        'auto-multi': autoCompMulti
    }
}
</script>