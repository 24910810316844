var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-toolbar',{staticClass:"elevation-1",attrs:{"height":"48px"}},[_c('v-toolbar-title',[_vm._v("Gestion des contacts")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2 white--text",attrs:{"rounded":"","outlined":"","depressed":"","text":"","color":(!_vm.withTrashed) ? 'grey' : 'red'},on:{"click":function($event){_vm.withTrashed = !_vm.withTrashed; _vm.getContact()}}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-delete-restore")]),_c('span',{staticClass:"hidden-sm-and-down"})],1)]}}])},[_c('span',[_vm._v("Afficher les contacts supprimés")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2 white--text",attrs:{"rounded":"","outlined":"","depressed":"","text":"","color":"orange"},on:{"click":function($event){_vm.modalUpload = true}}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-cloud-upload")]),_c('span',{staticClass:"hidden-sm-and-down"})],1)]}}])},[_c('span',[_vm._v("Injecter / modifier des contacts")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2 white--text",attrs:{"rounded":"","outlined":"","depressed":"","text":"","color":"primary"},on:{"click":function($event){return _vm.downloadContact()}}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-file-excel")]),_c('span',{staticClass:"hidden-sm-and-down"})],1)]}}])},[_c('span',[_vm._v("Télécharger la liste des contacts")])]),_c('v-btn',{staticClass:"white--text",attrs:{"color":"success","rounded":"","depressed":"","to":"/contacts/0"}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v("mdi-plus-circle")]),_c('span',{staticClass:"hidden-sm-and-down"},[_vm._v("Créer")])],1)],1),_c('v-text-field',{staticClass:"ma-4 pa-0",attrs:{"append-icon":"mdi-magnify","label":"Recherche","solo":"","clearable":"","single-line":"","hide-details":""},on:{"blur":function($event){return _vm.saveSearchToCache()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-card',{staticClass:"ma-4"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"item-key":"sta_id","search":_vm.search,"items-per-page-text":"Lignes par page","items-per-page-options":_vm.pagination.rowsPerItem},scopedSlots:_vm._u([{key:"item.ctt_middlename",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate",staticStyle:{"max-width":"400px"},attrs:{"title":item.ctt_middlename}},[_vm._v(_vm._s(item.ctt_middlename))])]}},{key:"item.ctt_company_name",fn:function(ref){
var item = ref.item;
return [(item.ctt_company_name)?_c('div',{staticClass:"text-truncate",staticStyle:{"max-width":"300px"},attrs:{"title":item.ctt_company_name}},[_vm._v(" "+_vm._s(item.ctt_company_name)+" ")]):_vm._e()]}},{key:"item.ctt_sta_id",fn:function(ref){
var item = ref.item;
return [((item.ctt_deleted_at))?_c('v-chip',{attrs:{"small":"","outlined":"","label":"","color":"red"}},[_vm._v("Supprimé")]):_c('v-chip',{attrs:{"small":"","outlined":"","label":"","color":_vm.getCacheState('GEN', item.ctt_sta_id).typ_color}},[_vm._v(_vm._s(_vm.getCacheState('GEN', item.ctt_sta_id).sta_label))])]}},{key:"item.ctt_typ_id",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","outlined":"","label":"","color":_vm.getCacheType('CTT', item.ctt_typ_id).typ_color}},[_vm._v(_vm._s(_vm.getCacheType('CTT', item.ctt_typ_id).typ_name))])]}},{key:"item.ctt_phone",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"caption text--primary font-italic"},[_vm._v(_vm._s(_vm.formatPhoneNumber(item.ctt_phone)))]),_c('br'),_c('span',{staticClass:"caption text--primary font-italic"},[_vm._v(_vm._s(_vm.formatPhoneNumber(item.ctt_mobile)))])]}},{key:"item.ctt_email",fn:function(ref){
var item = ref.item;
return [(item.ctt_email)?_c('v-chip',{attrs:{"small":"","outlined":"","label":"","title":item.ctt_email}},[_vm._v(" "+_vm._s((item.ctt_email) ? item.ctt_email.substring(0, 22) : '')+" "+_vm._s((item.ctt_email && item.ctt_email.length > 21) ? '...' : '')+" ")]):_vm._e()]}},{key:"item.ctt_updated_at",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"caption text--primary font-italic"},[_vm._v(_vm._s(_vm.locDateFormat.toLocale(item.ctt_updated_at, { year: 'numeric', month: 'long', day: 'numeric' })))])]}},{key:"item.ctt_id",fn:function(ref){
var item = ref.item;
return [((!item.ctt_deleted_at))?_c('v-btn',{staticClass:"ml-2 elevation-1",attrs:{"outlined":"","small":"","color":"grey","href":'/#/contacts/' + item.ctt_id + ''}},[_c('v-icon',{staticClass:"mr-2",attrs:{"left":""}},[_vm._v("mdi-pencil")]),_vm._v("Editer ")],1):_vm._e()]}},{key:"pageText",fn:function(ref){
var pageStart = ref.pageStart;
var pageStop = ref.pageStop;
return [_vm._v(" De "+_vm._s(pageStart)+" à "+_vm._s(pageStop)+" ")]}}],null,true)})],1),_c('v-dialog',{attrs:{"max-width":"900"},model:{value:(_vm.modalUpload),callback:function ($$v) {_vm.modalUpload=$$v},expression:"modalUpload"}},[_c('v-card',{attrs:{"color":"grey lighten-4","flat":""}},[_c('v-toolbar',{staticClass:"elevation-2",attrs:{"color":"primary","dark":"","height":"50px"}},[_c('v-icon',{staticClass:"mr-3"},[_vm._v("mdi-chevron-down")]),_c('v-toolbar-title',[_vm._v("Importer des contacts")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","color":""},nativeOn:{"click":function($event){_vm.modalUpload = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{staticClass:"pa-2",attrs:{"xs6":""}},[_c('v-file-input',{ref:"contact",attrs:{"color":"orange","label":"Selectionner un fichier"},on:{"change":function($event){return _vm.getFile($event, 'contact')}},model:{value:(_vm.form.file_contact),callback:function ($$v) {_vm.$set(_vm.form, "file_contact", $$v)},expression:"form.file_contact"}})],1),_c('v-flex',{staticClass:"pa-2",attrs:{"xs4":""}},[_c('v-select',{attrs:{"item-text":"typ_name","item-value":"typ_id","items":_vm.getCacheType('CTT'),"label":"Type","single-line":""},model:{value:(_vm.form.ctt_typ_id),callback:function ($$v) {_vm.$set(_vm.form, "ctt_typ_id", $$v)},expression:"form.ctt_typ_id"}})],1),_c('v-flex',{staticClass:"pa-2",attrs:{"xs2":""}},[_c('v-btn',{staticClass:"mt-3",attrs:{"color":"green","disabled":(!_vm.validUplodContact || _vm.saveProgress)},on:{"click":function($event){return _vm.sendContactFile('contact')}}},[_vm._v("Envoyer")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }