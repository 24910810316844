<template>
<v-container fluid class="pa-0">
    <v-toolbar class="elevation-2" height="48px">
        <v-btn icon href="/#/users/"><v-icon>mdi-arrow-left-thick</v-icon></v-btn>
        <v-toolbar-title >Fiche {{item.usr_lastname}} {{item.usr_firstname}}</v-toolbar-title>
        <v-spacer></v-spacer>
    </v-toolbar>
<!-- Formulaire -->
    <v-card fluid class="ma-2 pa-5 elevation-2">
        <v-layout row wrap>
        <v-flex xs4 class="pa-2">
            <v-text-field label="Nom"    v-model="item.usr_lastName" ></v-text-field>
            <v-text-field label="Prénom" v-model="item.usr_firstName"></v-text-field>
            <v-text-field label="Login"  v-model="item.usr_username"></v-text-field>
            <v-text-field label="Mot de passe" type="password" v-model="item.usr_password" loading>
                <template v-slot:progress>
                    <v-progress-linear :value="progress" :color="color" height="3" absolute></v-progress-linear>
                </template>
            </v-text-field>
        </v-flex>
        <v-flex xs4 class="pa-2">
            <v-text-field label="Tel" v-model="item.usr_phone"></v-text-field>
            <v-text-field label="Mobile" v-model="item.usr_mobile"></v-text-field>
            <v-text-field label="Courriel" v-model="item.usr_email"></v-text-field>
            <v-select :items="roleList" item-value="value" v-model="item.usr_role" label="Role" single-line >
            </v-select>
        </v-flex>
        <v-flex xs4 class="pa-2">
            <v-container fluid class="text-right">
            <v-chip outlined color="blue">
                <v-icon left>mdi-information-outline</v-icon>Modification: {{ locDateFormat.formatLocale(item.usr_updated_at, 'fr') }}
            </v-chip>
            </v-container>
        </v-flex>
        </v-layout>
        <v-card-actions>
            <v-btn color="red" v-if="item.usr_uid" @click="deleteUser(item)"><v-icon title="Supprimer">mdi-delete</v-icon></v-btn>
            <v-spacer></v-spacer>
            <v-btn color="success" :loading="saveProgress" @click.native="saveUser()" :disabled="disabledSave">Enregistrer</v-btn>
            <v-btn color="primmary" href="/#/users/">Retour</v-btn>
        </v-card-actions>
    </v-card>

    <v-card fluid class="ma-2 pa-0 elevation-2">
    <v-expansion-panels focusable>
        <v-expansion-panel>
            <!-- <v-expansion-panel-header><span><v-icon>mdi-account-check-outline</v-icon> Droits d'accès</span></v-expansion-panel-header> -->
            <v-expansion-panel-header >
            <v-row no-gutters>
            <v-col cols="4"><span><v-icon>mdi-account-check-outline</v-icon> Droits d'accès</span></v-col>
            <v-col cols="8" class="text--secondary">
                <v-fade-transition leave-absolute>
                    <v-row no-gutters style="width: 100%">
                        <v-col cols="6"><v-btn color="success" small :loading="saveProgress" @click.native="saveAccess()" :disabled="disabledSave">Enregistrer</v-btn></v-col>
                        <v-col cols="6"></v-col>
                    </v-row>
                </v-fade-transition>
            </v-col>
            </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <v-layout row wrap>
                    <v-list dense>
                        <AuthorizationList :userAccessJson="userAccessJson" />
                    </v-list>
                </v-layout>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
    </v-card>
</v-container>
</template>

<script>
import dateLocale from '@/services/dateLocale';
import { usrGetters }   from "@/store/user.module"
import { cacheGetters } from "@/store/cache.module"
import AuthorizationList from "@/components/user/AuthorizationList.vue"

export default {
    name: 'user_card',
    components: {
        AuthorizationList
    },
    props: [],
    data: function () {
        return {
            saveProgress: false,         // Enregistrement en cours...
            item:{
              usr_id: 0, usr_uid:'', usr_username: '', usr_password: '',
              usr_lastName: '', usr_firstName: '',
              usr_phone: '', usr_mobile: '', usr_email: '',
              usr_created_at: '2000-01-01', usr_updated_at: '',
              usr_isActive: 1, usr_sta_id_type: 'USR01', usr_role: 'BASIC_USER',
              useraccess: { data: { uac_access_json: {} } }
            },
            locDateFormat: dateLocale, // Format de date
            userAccessJson: {},
            uac_id: 0,
            rightLabel: {
                Generic: { name: 'Générique (par défaut)', icon: ''},
                Dashboard: { name: 'Tableau de bord', icon: ''},
                TrackingSheet: { name: 'Site', icon: ''},
                TrackingSheetEcology: { name: 'Site / ecologie', icon: ''},
                TrackingSheetDocuments:  { name: 'Site / Documents', icon: ''},
                TrackingSheetAdvice:     { name: 'Site / Avis', icon: ''},
                TrackingSheetEvent:      { name: 'Site / Evènements', icon: ''},
                TrackingSheetConvention: { name: 'Site / Convention', icon: ''},
                TrackingSheetLanddata:   { name: 'Site / Données foncières', icon: ''},
                TrackingSheetBilling:    { name: 'Site / Facturation', icon: ''},
                Map:                     { name: 'Carte', icon: ''},
                Documents:               { name: 'Documents', icon: ''},
                Billing:                 { name: 'Facturation', icon: ''},
                ReportInternal:          { name: 'Export interne', icon: ''},
                ReportExternal:          { name: 'Export externe', icon: ''},
                
                Report:              { name: 'Exports', icon: ''},
                ReportSite:          { name: 'Export par site', icon: ''},
                ReportSpecification: { name: 'Export cahier des charges', icon: ''},
                ReportSpecie:        { name: 'Export par espèces', icon: ''},
                ReportHabitat:       { name: 'Export habitat', icon: ''},
                ReportEvent:         { name: 'Export événements', icon: ''},
                ReportInvoice:       { name: 'Export Facture', icon: ''},
                ReportObeservatory:  { name: 'Export observatoire env.', icon: ''},
                ReportPraBv:         { name: 'Export Espèce PRA/BV', icon: ''},
                ReportAreaControl:   { name: 'Export Controle surface', icon: ''},

                Users:                   { name: 'Utilisateurs', icon: ''},
                StatePolicy:             { name: 'Gestion des états', icon: ''},
                TypesById:               { name: 'Droits sur type de fichier', icon: ''},

                before : { name: 'global', icon: ''},
                index  : { name: 'consulter liste', icon: ''},
                show   : { name: 'consulter', icon: ''},
                store  : { name: 'créer', icon: ''},
                update : { name: 'modifier', icon: ''},
                destroy: { name: 'supprimer', icon: ''},
                lastevent: { name: 'Dernier événements', icon: ''},
                lastsheet: { name: 'Dernieres fiches', icon: ''},
            }
        }
    },
    computed: {
        progress () {
            return Math.min(100, this.item.usr_password.length * 12.5)
        },
        color () {
            return ['error', 'warning', 'success'][Math.floor(this.progress / 50)]
        },
        disabledSave(){
            if(this.saveProgress){
                return true
            }
            if( this.item.usr_email.length > 2 ){
                return false
            } else {
                return true
            }
        },
        roleList(){
            let roleTab   = this.roleLabels()
            let roleAssoc = []
            for( let prop in roleTab){
                roleAssoc.push({ value: prop, text: roleTab[prop] })
            }
            return roleAssoc
        }
    },
    mounted:function (){
        if( parseInt( this.$route.params.usr_id ) > 0 && this.$route.params.usr_id !== undefined ){
            this.$http.get( '/users/' + this.$route.params.usr_id + '/?include=useraccess' ).then( (response) => {
                var prop
                for( prop in response.data.data ){
                    if( this.item[prop] !== undefined ){
                        this.item[prop] = response.data.data[prop] 
                    }
                }
                if( response.data.data.useraccess && response.data.data.useraccess.data ){
                    let uac_access_json  = response.data.data.useraccess.data.uac_access_json
                    this.uac_id          = response.data.data.useraccess.data.uac_id
                    this.userAccessJson  = {}
                    //let lastProp         = ''
                    //let scope            = ''
                    for( prop in uac_access_json ){
                        //if( scope[0] == prop.match(/[A-Z][a-z]+/g)[0] ){
                        //    console.log(prop.match(/[A-Z][a-z]+/g)[0])
                        //} else {
                        //    this.userAccessJson[prop]['children'] = []
                        //    //this.$set(this.userAccessJson, prop, { children: [] })
                        //}
                        this.$set(this.userAccessJson, prop, {})
                        let elem
                        for( elem in uac_access_json[prop] ){
                            //this.userAccess[prop][elem] = false
                            this.$set(this.userAccessJson[prop], elem, false)
                            if( uac_access_json[prop][elem].includes( this.item['usr_role'] ) || uac_access_json[prop][elem].includes('AUTHORIZED') ){
                                this.userAccessJson[prop][elem] = true
                            }                            
                        }
                        //scope = prop.match(/[A-Z][a-z]+/g)
                    }
                }
            })
            .catch( (error) => {
                this.$root.$toast({ color: 'warning', text: error })
            })
        }
    },
    methods: {
        saveUser () {
            // Enregistrement du formulaire
            this.saveProgress = true
            if( parseInt( this.$route.params.usr_id ) > 0 ){
                this.$http.put( '/users/' + this.$route.params.usr_id, this.item )
                .then( () => {
                    this.$root.$toast({ color: 'success', text: 'Fiche enregistrée !' })
                    this.$router.push({ path: '/users' })
                }).catch( (error) => {
                    this.$root.$toast({ text: 'Erreur lors de l\'enregistrement !', error: error })
                    this.saveProgress = false
                })
            } else {
                this.$http.post( '/users/', this.item )
                .then( () => {
                    this.$root.$toast({ color: 'success', text: 'Fiche enregistrée !' })
                    this.$router.push({ path: '/users' })
                }).catch( (error) => {
                    this.$root.$toast({ text: 'Erreur lors de l\'enregistrement !', error: error })
                    this.saveProgress = false
                })
            }
        },
        deleteUser(item){
            this.$root.$confirm('Supprimer l\'utilisateur (' + item.usr_id + ')', 'Confirmez vous cette action ?', { color: 'red', width: 400 }).then((confirm) => {
                if( confirm ){
                    this.saveProgress = true
                    this.$http.delete( '/users/' + item.usr_id).then( () => {
                        this.$root.$toast({ color: 'success', text: 'Utilisateur supprimé !' })
                        this.saveProgress = false
                        this.$router.push({ path: '/users' })
                    }).catch( (error) => {
                        this.$root.$toast({ text: 'Erreur lors de l\'enregistrement !', error: error })
                        this.saveProgress = false
                    })
                }
            })
        },
        saveAccess () {
            // Enregistrement du formulaire
            this.saveProgress = true
            let userAccess    = {
                uac_id          : (this.uac_id) ? this.uac_id : 0,
                uac_sta_id      : 'GEN01',
                uac_usr_id      : this.item.usr_id,
                uac_access_json : {}
            }
            let prop
            for( prop in this.userAccessJson ){
                userAccess.uac_access_json[prop] = {}
                let elem
                for( elem in this.userAccessJson[prop] ){
                    userAccess.uac_access_json[prop][elem] = ['UNAUTHORIZED']
                    if( this.userAccessJson[prop][elem] ){
                        userAccess.uac_access_json[prop][elem] = ['AUTHORIZED']
                    }
                }
            }
            if( parseInt( this.uac_id ) > 0 ){
                this.$http.put( '/useraccess/' + this.uac_id, userAccess )
                .then( () => {
                    this.$root.$toast({ color: 'success', text: 'Fiche enregistrée !' })
                    this.saveProgress = false
                }).catch( (error) => {
                    this.$root.$toast({ text: 'Erreur lors de l\'enregistrement !', error: error })
                    this.saveProgress = false
                })
            } else {
                this.$http.post( '/useraccess/', userAccess )
                .then( () => {
                    this.$root.$toast({ color: 'success', text: 'Fiche enregistrée !' })
                    this.saveProgress = false
                }).catch( (error) => {
                    this.$root.$toast({ text: 'Erreur lors de l\'enregistrement !', error: error })
                    this.saveProgress = false
                })
            }
        },
        isAuthorized: usrGetters.getScopeRight,
        roleLabels: usrGetters.getRolesLabels,
        getCacheType: cacheGetters.getTypes,
    }
}
</script>