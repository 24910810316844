<template>
<v-container fluid class="pa-0">
    <v-toolbar class="elevation-1" height="48px" :color="(viewMode == 'create') ? 'orange' : 'white'">
        <v-toolbar-title >{{(viewMode == 'create') ? 'Liste factures : création' : 'Liste factures'}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <div v-show="isSelected">
            <v-btn small class="ml-2" rounded color="success" dark v-if="viewMode == 'create'" @click.native="storeComputeInvoice()">
                <v-icon title="Enregistrer" small class="mr-2" >mdi-content-save-move</v-icon>Enregistrer
            </v-btn>
            <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" small class="ml-2" outlined rounded color="orange" dark v-if="viewMode == 'view'">
                        <v-icon title="Modifier l'état" small >mdi-pencil</v-icon>Modifier l'état
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item v-for="(item, index) in stateItem" :key="index">
                        <v-btn small class="ml-2" outlined rounded :color="item.sta_color" dark v-if="viewMode == 'view'" @click.native="invoiceAction(item.sta_label, 'Confirmer la modification', item.sta_id)">
                            <v-icon title="Refusé" small >mdi-circle</v-icon> {{item.sta_label}}
                        </v-btn>
                    </v-list-item>
                </v-list>
            </v-menu>
        </div>
        <v-btn v-if="viewMode == 'view'" small class="ml-2" rounded color="success" dark @click.native="editInvoice({})">
            <v-icon title="créer" small class="mr-2" >mdi-pencil</v-icon>Nouvelle
        </v-btn>
        <v-btn v-if="viewMode == 'view'" small class="ml-2" rounded color="success" dark @click="switchModal('taskinvoice', true)">
            <v-icon title="Générer les factures" small >mdi-calculator</v-icon>Génération
        </v-btn>
        <v-btn v-if="viewMode == 'create'" small class="ml-2" rounded outlined color="primary" dark @click="backToView()">
            <v-icon title="Retour" small >mdi-arrow-left</v-icon>Retour
        </v-btn>
    </v-toolbar>
    <v-layout row wrap>
    <v-flex xs12 class="pl-3 pr-3">
        <v-text-field append-icon="mdi-magnify" label="Recherche" solo clearable single-line hide-details v-model="search" class="ma-4 pa-0">
        </v-text-field>
    </v-flex>
    <!-- <v-flex xs2 class="pa-0 mt-3">
        <v-menu ref="datestart" v-model="dateSearch.date_start_menu"
            :return-value.sync="dateSearch.date_start"
            :close-on-content-click="false" :nudge-right="40" transition="scale-transition" 
            offset-y min-width="290px" style="z-index:1500">
            <template v-slot:activator="{ on, attrs }">
                <v-text-field 
                    v-bind="attrs" :value="locDateFormat.formatLocale( dateSearch.date_start, 'fr' )"
                    label="Date début" prepend-icon="mdi-calendar-today" readonly v-on="on" >
                </v-text-field>
            </template>
            <v-date-picker locale="fr-fr" no-title scrollable v-model="dateSearch.date_start">
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="dateSearch.date_start_menu = false">Annuler</v-btn>
                <v-btn color="primary" @click="$refs.datestart.save( dateSearch.date_start )">OK</v-btn>
            </v-date-picker>
        </v-menu>
    </v-flex>
    <v-flex xs2 class="pa-0 mt-3">
        <v-menu ref="dateend" v-model="dateSearch.date_end_menu"
            :return-value.sync="dateSearch.date_end"
            :close-on-content-click="false" :nudge-right="40" transition="scale-transition" 
            offset-y min-width="290px" style="z-index:1500">
            <template v-slot:activator="{ on, attrs }">
                <v-text-field 
                    v-bind="attrs" :value="locDateFormat.formatLocale( dateSearch.date_end, 'fr' )"
                    label="Date fin" prepend-icon="mdi-calendar-today" readonly v-on="on" >
                </v-text-field>
            </template>
            <v-date-picker locale="fr-fr" no-title scrollable v-model="dateSearch.date_start">
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="dateSearch.date_end_menu = false">Annuler</v-btn>
                <v-btn color="primary" @click="$refs.dateend.save( dateSearch.date_end )">OK</v-btn>
            </v-date-picker>
        </v-menu>
    </v-flex> -->
    </v-layout>
    <v-card class="ma-4 mt-0">
    <v-data-table 
        v-model="selected" :headers="headers" :items="items" :search="search" show-select item-key="inv_id"
        :page.sync="pagination.page" :items-per-page="pagination.itemsPerPage" @page-count="pagination.pageCount = $event"
        footer-props.items-per-page-text="Lignes par page" hide-default-footer>
        <template v-slot:top>
            <v-toolbar flat>
                <v-select class="pt-5" color="white" prepend-icon="mdi-filter"
                    :items="stateItem" v-model="filter.inv_sta_id" item-text="sta_label" item-value="sta_id"
                    label="Etat" chips multiple clearable dense @blur="getInvoices()">
                </v-select>
                <v-divider class="mx-4" inset vertical ></v-divider>
                <v-spacer></v-spacer>
            </v-toolbar>
        </template>
        <template v-slot:[`item.trs_coddt`]="{ item }">
            <v-chip label outlined color="blue">{{ item.trs_coddt + ' : ' + item.tcv_code }}</v-chip>
        </template>
        <template v-slot:[`item.inv_typ_id`]="{ item }" :color="( item.inv_typ_id == 'INV01' ) ? 'green' : 'primary'">
            <v-chip label outlined>{{ cacheType['INV'][item.inv_typ_id].typ_name }}</v-chip>
            <v-chip label outlined class="ml-1" color="orange">{{ cacheState['SIN'][item.inv_sta_id].sta_label }}</v-chip>
        </template>
        <template v-slot:[`item.inv_created_at`]    ="{ item }">
            <v-chip label outlined>{{ locDateFormat.formatLocale( item.inv_created_at, 'fr' ) }}</v-chip>
        </template>
        <template v-slot:[`item.inv_date_received`]    ="{ item }">
            <!-- {{item.inv_spf_ids}} -->
            <v-chip v-show="item.inv_date_received !== null" label outlined >{{ locDateFormat.formatLocale( item.inv_date_received, 'fr' ) }}</v-chip>
        </template>
        <template v-slot:[`item.inv_amount_ht_calc`]="{ item }">
            <span class="caption text--disabled font-italic">
                {{ (Math.round(item.inv_amount_ht_calc * 100) / 100).toFixed(2) }}
            </span>
        </template>
        <template v-slot:[`item.inv_amount_ht`]     ="{ item }">{{ (Math.round(item.inv_amount_ht      * 100) / 100).toFixed(2) }}</template>
        <template v-slot:[`item.inv_amount_ttc`]    ="{ item }">{{ (Math.round(item.inv_amount_ttc     * 100) / 100).toFixed(2) }}</template>

        <template v-slot:[`item.actions`]="{ item }">
            <v-icon title="Évènement"  color="primary" v-if="!item.inv_preview" small class="mr-2" @click="openEvent(item)">mdi-calendar</v-icon>
            <v-icon title="Modifier"   color="orange" small class="mr-2" @click="editInvoice(item)">mdi-pencil</v-icon>
            <v-icon title="Supprimer"  color="red" v-if="!item.inv_preview" small @click="deleteInvoice(item)">mdi-delete</v-icon>
            <v-btn  title="Lien demat" v-show="item.inv_demat_code.length > 3" :href="item.inv_demat_code" target="new" @click.stop icon>
                <v-icon color="green">mdi-link</v-icon>
            </v-btn>
        </template>
        <template slot="no-data">
        <v-alert :value="true" color="error" icon="mdi-alert">
            Aucune facture
        </v-alert>
        </template>
    </v-data-table>
    </v-card>
    <v-layout wrap>
        <v-flex xs6 class="text-left pl-8">
            <span v-show="pagination.totalFiltered">{{pagination.totalFiltered}} sur </span>
            <span>{{pagination.totalItems}} résultats</span>
        </v-flex>
        <v-flex xs6 class="text-right pr-0">
            <v-pagination v-model="pagination.page" :length="pagination.pageCount" :total-visible="7" ></v-pagination>
        </v-flex>
    </v-layout>

    <v-dialog v-model="modalInvoice" max-width="900" >
        <task-invoice
            v-if="modalType == 'taskinvoice'" :switchModal="switchModal" :genInvoice="generateInvoice" :genSelector.sync="genSelector">
        </task-invoice>
        <edit-invoice v-if="modalType == 'editinvoice'" :invoiceItem.sync="invoice" :switchModal="switchModal">
        </edit-invoice>
        <list-events
            v-if="modalType == 'listevent'" :switchModal="switchModal" 
            :xxx_id="invoice.inv_id" eve_table_id="inv" title="Liste des évènements">
        </list-events>
    </v-dialog>
</v-container>
</template>

<script>
import dateLocale  from '../services/dateLocale'
import { cacheGetters } from "../store/cache.module"
import taskInvoice from '../components/Task_invoice.vue'
import editInvoice from '../components/Edit_invoice.vue'
import listevents  from '../components/List_events.vue'

export default {
    name: 'invoices',
    props: [],
    data: function () {
      return {
            saveProgress: false,         // Enregistrement en cours...
            viewMode    : 'view', 
            genSelector: {
                date_start: '', date_end: '',
                spf_type: []
            },
            search: '',
            dateSearch: { 
                date_start: '', date_end: '',
                date_start_menu: false, date_end_menu: false
            },
            pagination: { page: 1, totalItems: 0, itemsPerPage: 12, totalPage: 1, limit: 0, pageCount: 0, totalFiltered: 0 },
            selected: [],
            headers: [
                { align: 'left',  value: 'trs_coddt',         text: 'Convention' },
                { align: 'left',  value: 'inv_typ_id',        text: 'Type de facture' },
                { align: 'left',  value: 'inv_created_at',    text: 'Date de création' },
                { align: 'left',  value: 'inv_date_received', text: 'Date reception' },
                { align: 'right', value: 'inv_amount_ht_calc',text: 'Montant calculé (HT)' },
                { align: 'right', value: 'inv_amount_ht',     text: 'Montant HT' },
                { align: 'right', value: 'inv_amount_ttc',    text: 'Montant TTC' },
                { text: 'Actions', value: 'actions', sortable: false },
            ],
            items: [],
            locDateFormat: dateLocale,
            cacheType: cacheGetters.getTypes(),
            cacheState: cacheGetters.getStates(),
            enableAdd: true,
            modalType: '',
            modalInvoice : false,
            invoice: { inv_id: 0 },
            stateItem: cacheGetters.getStates('SIN'),
            filter: { inv_sta_id: [] }
      }
    },
    computed: {
        pages () {
            if (this.pagination.totalItems == null || this.pagination.totalItems == null ) return 0
            return Math.ceil(this.pagination.totalItems / this.pagination.itemsPerPage)
        },
        isSelected(){
            return this.selected.length
        }
    },
    mounted: function (){
        var today = new Date()
        let yyyy = today.getFullYear()
        let mm   = today.getMonth()+1
        if( mm > 6 ){
            this.genSelector.date_start = yyyy + '-07-01'
            this.genSelector.date_end   = yyyy + '-12-31'
            this.dateSearch.date_start  =  yyyy + '-07-01'
            this.dateSearch.date_end    = yyyy + '-12-31'
        } else {
            this.genSelector.date_start = yyyy + '-01-01'
            this.genSelector.date_end   = yyyy + '-06-30'
            this.dateSearch.date_start  = yyyy + '-01-01'
            this.dateSearch.date_end    = yyyy + '-06-30'
        }
        this.getInvoices()
    },
    methods: {
        getInvoices(){
            this.items = []
            var searchCriteria = '?per_page=false&extended=withtrackingsheet&order_by=inv_date&order_direction=asc'
            searchCriteria    += ( this.filter.inv_sta_id.length > 0 ) ? '&inv_sta_id=' + this.filter.inv_sta_id.join(',') : ''
            this.$http.get( '/invoices/' + searchCriteria )
            .then( (response) => {
                if( response.headers.totalrow !== undefined && response.headers.totalrow !== '0' ){
                    this.pagination.totalItems  = parseInt(response.headers.totalrow)
                    this.pagination.totalPage   = Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
                } else {
                    this.pagination.totalItems = 1
                }
                if( Object.prototype.toString.call( response.data.data ) === '[object Array]' ) {
                    this.items = response.data.data
                }
            })
        },
        handlePage(index){
            this.pagination.limit = this.pagination.rowsPerPage * (index - 1)
            this.getInvoices()
        },
        openEvent(item){
            this.invoice.inv_id = item.inv_id
            this.switchModal('listevent', 1)
        },
        switchModal(name, mode, refresh){
            name    = name || 'modalInvoice'
            mode    = mode || 0
            refresh = refresh || false
            this.modalInvoice = mode
            this.modalType    = name
            if( mode == 0 ){
                this.modalType = ''
            }
            if( !mode && refresh ){
                this.getInvoices()
            }
        },
        async invoiceAction( title, message, state ){
            title   = title   || 'Action'
            message = message || 'Confirmez-vous cette action ?'
            state   = state   || 'SIN01'
            this.$root.$confirm(title, message, { color: 'red', width: 400 }).then((confirm) => {
                if( confirm ){
                    this.selected.forEach( (elem) => {
                        this.$http.put( '/invoices/' + elem.inv_id, { inv_id: elem.inv_id, inv_sta_id: state }) //.then( () => {})
                    })
                    this.getInvoices()
                    this.selected = []
                    this.$root.$toast({ color: 'success', text: 'Action enregistrée !' })
                }
            })
        },
        editInvoice(item){
            this.invoice = item
            this.switchModal('editinvoice', true)
        },
        deleteInvoice(item){
            this.$root.$confirm('Supprimer la facture (' + item.inv_id + ')', 'Confirmez vous cette action ?', { color: 'red', width: 400 }).then((confirm) => {
                if( confirm ){
                    this.$http.delete( '/invoices/' + item.inv_id).then( () => {
                        this.$root.$toast({ color: 'success', text: 'Action enregistrée !' })
                        this.getInvoices()
                    })
                }
            })
        },
        generateInvoice( filter ){
            this.viewMode = 'create'
            this.switchModal('-', 0)
            this.items = []
            this.pagination.page = 1
            this.$http.post( '/invoices/compute', filter )
            .then( (response) => {
                if( response.data.meta.pagination.total !== undefined && response.data.meta.pagination.total !== '0' ){
                    this.pagination.totalItems  = parseInt(response.data.meta.pagination.total)
                } else {
                    this.pagination.totalItems = 1
                }
                if( Object.prototype.toString.call( response.data.data ) === '[object Array]' ) {
                    var i = 0;
                    response.data.data.forEach(element => {
                        element.inv_preview = true
                        element.inv_id      = i
                        i = i +1
                    });
                    this.items = response.data.data
                }
            })
        },
        storeComputeInvoice(){
            this.$root.$confirm('Supprimer', 'Confirmer la création des factures selectionnées ?', { color: 'red', width: 400 }).then((confirm) => {
                if( confirm ){
                    this.$http.post( '/invoices/storecompute', { invoices: this.selected } )
                    .then( ( ) => {
                        this.selected = []
                        this.viewMode = 'view'
                        this.$root.$toast({ color: 'success', text: 'Factures enregistrées !' })
                        this.getInvoices()
                    })
                }
            })
        },
        backToView(){
            this.selected = []
            this.viewMode = 'view'
            this.getInvoices()
        }
    },
    components: {
        'task-invoice' : taskInvoice,
        'edit-invoice' : editInvoice,
        'list-events'  : listevents
    }
}
</script>