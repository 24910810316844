<template>
<v-container fluid class="pa-0">
    <v-toolbar class="elevation-2" height="48px">
        <v-btn icon href="/#/indexation/"><v-icon>mdi-arrow-left-thick</v-icon></v-btn>
        <v-toolbar-title >Fiche Indexation / ratio</v-toolbar-title>
        <v-spacer></v-spacer>
    </v-toolbar>
<!-- Formulaire -->
    <v-card fluid class="ma-2 pa-5 elevation-2">
        <v-layout row wrap>
        <v-flex xs6 class="pa-2">
            <v-select 
                prepend-icon="mdi-format-list-bulleted-type"
                 item-text="typ_name" item-value="typ_id"
                :items="getCacheType('INR')" v-model="form.inr_typ_id" label="Statut" single-line >
            </v-select>
        </v-flex>
        <v-flex xs6 class="pa-2">
            <v-text-field prepend-icon="mdi-percent" label="Ratio" v-model="form.inr_rate" ></v-text-field>
            
            <v-menu ref="datestart" v-model="dateMenu.date_start"
                :return-value.sync="form.inr_date"
                :close-on-content-click="false" :nudge-right="40" transition="scale-transition" 
                offset-y min-width="290px" style="z-index:1500">
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field 
                        v-bind="attrs" :value="locDateFormat.formatLocale( form.inr_date, 'fr' )"
                        label="Date début" prepend-icon="mdi-calendar-today" readonly v-on="on" >
                    </v-text-field>
                </template>            
            <v-date-picker locale="fr-fr" no-title scrollable v-model="form.inr_date">
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="dateMenu.date_start = false">Annuler</v-btn>
                <v-btn color="primary" @click="$refs.datestart.save( form.inr_date )">OK</v-btn>
            </v-date-picker>
            </v-menu>
        </v-flex>
        </v-layout>
        <div class="text-right">
            <v-btn color="success" :loading="saveProgress" @click.native="saveForm()" :disabled="saveProgress">
            Enregistrer
            </v-btn>
            <v-btn color="primmary" href="/#/indexation/">
            Retour
            </v-btn>
        </div>
    </v-card>
</v-container>
</template>

<script>
import dateLocale from '../services/dateLocale';
import { cacheGetters } from "../store/cache.module"

export default {
    name: 'user_card',
    props: [],
    data: function () {
        return {
            dateMenu: { date_start:false, date_end:false },
            saveProgress: false,         // Enregistrement en cours...
            form:{
              inr_id:'', inr_typ_id: 'INR01', inr_date: '',
              inr_rate: '',
            },
            locDateFormat: dateLocale, // Format de date
            items: [],
        }
    },
    computed: {

    },
    mounted:function (){
        if( parseInt( this.$route.params.inr_id ) > 0 && this.$route.params.inr_id !== undefined ){
            this.$http.get( '/indexationrate/' + this.$route.params.inr_id )
            .then( (response) => {
                var prop
                for( prop in response.data.data ){
                    if( this.form[prop] !== undefined ){
                        this.form[prop] = response.data.data[prop] 
                    }
                }
            })
            .catch( (error) => {
                this.$root.$toast({ color: 'warning', text: error })
            })
        }
    },
    methods: {
        saveForm () {
            // Enregistrement du formulaire
            this.saveProgress = true
            if( parseInt( this.$route.params.inr_id ) > 0 ){
                this.$http.put( '/indexationrate/' + this.$route.params.inr_id, this.form )
                .then( () => {
                    this.$root.$toast({ color: 'success', text: 'Fiche enregistrée !' })
                    this.$router.push({ path: '/indexation' })
                }).catch( (error) => {
                    this.$root.$toast({ text: 'Erreur lors de l\'enregistrement !', error: error })
                });
            } else {
                this.$http.post( '/indexationrate/', this.form )
                .then( () => {
                    this.$root.$toast({ color: 'success', text: 'Fiche enregistrée !' })
                    this.$router.push({ path: '/indexation' })
                }).catch( (error) => {
                    this.$root.$toast({ text: 'Erreur lors de l\'enregistrement !', error: error })
                    this.saveProgress = false
                });
            }
        },
        getCacheType: cacheGetters.getTypes,
        getCacheState: cacheGetters.getStates
    },
    components: { }
}
</script>