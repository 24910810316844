<template>
<v-container fluid class="pa-0">
    <v-toolbar class="elevation-1" height="48px">
        <v-toolbar-title >Type de cahier des charges / Plan de restauration</v-toolbar-title>
        <v-spacer></v-spacer>
        <!-- <v-tooltip bottom>
            <template v-slot:activator="{ on }">
                <v-btn rounded outlined depressed text @click="modalUpload = true" color="orange" class="mr-2 white--text" v-on="on">
                    <v-icon dark>mdi-cloud-upload</v-icon><span class="hidden-sm-and-down"></span>
                </v-btn>
            </template>
            <span>Injecter / modifier</span>
        </v-tooltip> -->
        <v-btn color="success" depressed class="white--text" to="/coderef/0">
            <v-icon left dark>mdi-plus-circle</v-icon>
            <span class="hidden-sm-and-down">Créer</span>
        </v-btn>
    </v-toolbar>
    <v-text-field
        append-icon="mdi-magnify" label="Recherche"
        solo clearable single-line hide-details v-model="search" @blur="saveSearchToCache()" class="ma-4 pa-0">
    </v-text-field>
    <v-card class="ma-4">
    <v-data-table v-bind:headers="headers" :items="items" item-key="sta_id" :search="search"
        items-per-page-text="Lignes par page" :items-per-page-options="pagination.rowsPerItem">
        <template v-slot:[`item.cdr_typ_id_sit`]="{ item }">
            <v-chip small outlined label :color="getCacheType('SIT', item.cdr_typ_id_sit).typ_color">{{ getCacheType('SIT', item.cdr_typ_id_sit).typ_name }}</v-chip>
        </template>
        <template v-slot:[`item.cdr_typ_id`]="{ item }">
            <v-chip small outlined label :color="getCacheType('SPM', item.cdr_typ_id).typ_color">{{ getCacheType('SPM', item.cdr_typ_id).typ_name }}</v-chip>
        </template>
        <template v-slot:[`item.cdr_updated_at`]="{ item }">
            <span v-show="(item.cdr_updated_at)" class="grey--text font-italic">{{ (item.cdr_updated_at == undefined) ? '-' : locDateFormat.formatLocale(item.cdr_updated_at, 'fr') }}</span>
            <v-btn outlined small color="grey" class="ml-2 elevation-1" :href="'/#/coderef/' + item.cdr_id + ''">
                <v-icon left class="mr-2">mdi-pencil</v-icon>Modifier
            </v-btn>
        </template>
        <template slot="pageText" slot-scope="{ pageStart, pageStop }">
        De {{ pageStart }} à {{ pageStop }}
        </template>
    </v-data-table>
    </v-card>

    <v-dialog v-model="modalUpload" max-width="900" >
        <v-card color="grey lighten-4" flat>
            <v-toolbar color="primary" dark class="elevation-2" height="50px">
                <v-icon class="mr-3">mdi-chevron-down</v-icon>
                <v-toolbar-title>Importer des références</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon color="" @click.native="modalUpload = false"><v-icon>mdi-close</v-icon></v-btn>
            </v-toolbar>
            <v-card-text>
                <v-layout row wrap>
                <v-flex xs10 class="pa-2">
                    <v-file-input ref="coderef" color="orange"
                        @change="getFile($event, 'coderef')" label="Selectionner un fichier"
                        v-model="form.file_coderef">
                    </v-file-input>
                </v-flex>
                <v-flex xs2 class="pa-2">
                    <v-btn color="green" class="mt-3" @click="sendCoderefFile('coderef')" :disabled="(!validUplodCoderef || saveProgress)">Envoyer</v-btn>
                </v-flex>
                </v-layout>
            </v-card-text>
        </v-card>
    </v-dialog>
</v-container>
</template>

<script>
import dateLocale from '../services/dateLocale';
import { cacheGetters, cacheMutations } from "../store/cache.module"

export default {
    name: 'coderef',
    props: [],
    data: function () {
        return {
            saveProgress: false,         // Enregistrement en cours...
            search: '',
            pagination: { totalItems: 0, rowsPerPage: 10, rowsPerItem: [15,25,35,{text:'Tout',value:-1}] },
            headers: [
            { text: 'Id',           align: 'left', value: 'cdr_id'},
            { text: 'Cible',        align: 'left', value: 'cdr_typ_id_sit'},
            { text: 'Type',         align: 'left', value: 'cdr_typ_id'},
            { text: 'Code',         value: 'cdr_code' },
            { text: 'Mesure',       value: 'cdr_description' },
            { text: 'Modification', align: 'right', value: 'cdr_updated_at' }
            ],
            items: [

            ],
            locDateFormat: dateLocale,
            cacheTab: this.$storage.localStorage.get( this.$APP_COD + "_cache" ),
            enableAdd: true,
            modalEvent : false,
            modalUpload: false,
            form: { 
                file_coderef: null, file_coderef_name: '', file_coderef_source: '',
                ctt_typ_id: 'CTT01'
            }
        }
    },
    computed: {
        validUplodCoderef(){
            if( this.form.file_coderef_name.length > 0 ){
                return true
            } else {
                return false
            }
        },
        pages () {
            if (this.pagination.rowsPerPage == null || this.pagination.totalItems == null ) return 0
            return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
        }
    },
    mounted: function (){
        this.search = cacheGetters.getSearchBarItem('coderef') || ''
        this.getCoderefs()
    },
    methods: {
        getCoderefs(){
            this.$http.get( '/coderef/?per_page=false' ).then( (response) => {
                this.items = response.data.data
            })  
        },
        handlePage(index){
            this.pagination.limit = this.pagination.rowsPerPage * (index - 1)
            //this.pagination.page  = (index - 1)
        },
        saveSearchToCache(){
            cacheMutations.setSearchBarItem('coderef', this.search)
        },
        getFile(file, scope){
            if( file !== undefined && this.form['file_' + scope + '_name'].length == 0 ){
                //let nameTab = file.name.split(".")
                //this.form['file_' + scope + '_name'] = nameTab[0]
                this.form['file_' + scope + '_name'] = file.name
            } else {
                this.form['file_' + scope + '_name'] = ''
            }
        },
        sendCoderefFile(scope) {
            // Enregistrement du formulaire
            this.saveProgress = true
            var formFile = new FormData()
            for (var prop in this.form) {
                if( prop == scope ){
                    formFile.append(prop, JSON.stringify( this.form[prop] ))
                } else {
                    formFile.append(prop, this.form[prop])
                }
            }
            if( this.form['file_' + scope] ) {
                formFile.append('file_' + scope, this.form['file_' + scope], this.form['file_' + scope].name)
            }
            this.$http.post( '/coderef/upload/', formFile )
            .then( (response) => {
                this.$root.$toast({ color: 'success', text: 'Données enregistrée !' })
                this.saveProgress = false
                this.modalUpload  = false
                for( let prop in response.data ){
                    if( Array.isArray( response.data[prop].error ) ){
                        this.processInfo = response.data[prop].error
                    }
                }
            }).catch( (error) => {
                this.$root.$toast({ text: 'Erreur lors de l\'enregistrement !', error: error })
                this.saveProgress = false
                this.modalUpload  = false
            })

        },
        getCacheType: cacheGetters.getTypes,
        getCacheState: cacheGetters.getStates
    },
    components: {
        
    }
}
</script>