<template>
<v-container fluid class="pa-0">
    <v-toolbar class="elevation-1" height="48px">
        <v-toolbar-title >Gestion des indexations</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="success" depressed class="white--text" to="/indexation/0">
            <v-icon left dark>mdi-plus-circle</v-icon>
            <span class="hidden-sm-and-down">Créer</span>
        </v-btn>
    </v-toolbar>
    <v-text-field
        append-icon="mdi-magnify" label="Recherche"
        solo clearable single-line hide-details v-model="search" @blur="saveSearchToCache()" class="ma-4 pa-0">
    </v-text-field>
    <v-card class="ma-4">
    <v-data-table v-bind:headers="headers" :items="items" item-key="sta_id" :search="search"
        items-per-page-text="Lignes par page" 
        :items-per-page-options="pagination.rowsPerItem">
        <template v-slot:item.inr_date="{ item }">
            <v-chip label outlined >{{ locDateFormat.formatLocale( item.inr_date, 'fr' ) }}</v-chip>
        </template>
        <template v-slot:item.inr_typ_id="{ item }">
            <v-chip label outlined>{{ typeInr[item.inr_typ_id.substring(0, 3)][item.inr_typ_id].typ_name }}</v-chip>
        </template>
        <template v-slot:item.inr_created_at="{ item }">
            <v-chip label outlined >{{ locDateFormat.formatLocale( item.inr_created_at, 'fr' ) }}</v-chip>
        </template>
        <template v-slot:item.inr_id="{ item }">
                <v-btn outlined small color="grey" class="ml-2 elevation-1" :href="'/#/indexation/' + item.inr_id + ''">
                    <v-icon left class="mr-2">mdi-pencil</v-icon>Modifier
                </v-btn>
        </template>
        <template slot="pageText" slot-scope="{ pageStart, pageStop }">
        De {{ pageStart }} à {{ pageStop }}
        </template>
    </v-data-table>
    </v-card>
</v-container>
</template>

<script>
import dateLocale from '../services/dateLocale';
import { cacheGetters, cacheMutations } from "../store/cache.module"

export default {
    name: 'states',
    props: [],
    data: function () {
        return {
            saveProgress: false,         // Enregistrement en cours...
            search: '',
            pagination: { totalItems: 0, rowsPerPage: 10, rowsPerItem: [15,25,35,{text:'Tout',value:-1}] },
            headers: [
            { text: 'Date', align: 'left', value: 'inr_date'},
            { text: 'Type',  value: 'inr_typ_id' },
            { text: 'Index', value: 'inr_rate' },
            { text: 'Création', align: 'right', value: 'inr_created_at' },
            { text: 'Modification',   align: 'right', value: 'inr_id'},
            ],
            items: [],
            locDateFormat: dateLocale,
            enableAdd: true,
            modalEvent : false,
            typeInr: cacheGetters.getTypes(),
        }
    },
    computed: {
        pages () {
            if (this.pagination.rowsPerPage == null || this.pagination.totalItems == null ) return 0
            return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
        }
    },
    mounted: function (){
        this.search = cacheGetters.getSearchBarItem('indexation') || ''
        this.getTypes()
    },
    methods: {
        getTypes(){
            this.$http.get( '/indexationrate?per_page=false' ).then( (response) => {
                this.items = response.data.data
            })  
        },
        saveSearchToCache(){
            cacheMutations.setSearchBarItem('indexation', this.search)
        },
    },
    components: {
        
    }
}
</script>