<template>
<v-container fluid class="pa-0">
    <v-toolbar class="elevation-2" height="48px">
        <v-btn icon href="/#/compensationsar/"><v-icon>mdi-arrow-left-thick</v-icon></v-btn>
        <v-toolbar-title >Objectif espèces PRA</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-chip outlined color="blue" v-if="form.csa_updated_at">
            <v-icon left>mdi-information-outline</v-icon>Modification: {{ locDateFormat.formatLocale(form.csa_updated_at, 'fr') }}
        </v-chip>
    </v-toolbar>
<!-- Formulaire -->
    <v-card fluid class="ma-2 pa-5 elevation-2">
        <v-layout row wrap>
        <v-flex xs12 class="pa-2">
            <auto-multi v-if="!loading.getItem" ressource="species" :title="'Espèce'" :listObject.sync="autoComplete.csa_spe_id"
                :listParent.sync="autoCompleteSource.spe" 
                :fields="{ index: 'spe_id', text:'spe_name', search:'spe_name', multiSelect:false, preprendIcon:'mdi-ladybug' }">
            </auto-multi>
        </v-flex>
        <v-flex xs2 class="pa-2">
            <v-select 
                :items="stateCsa" v-model="form.csa_sta_id" label="Type"
                item-text="sta_label" item-value="sta_id" >
            </v-select>
        </v-flex>
        <v-flex xs5 class="pa-2">
            <auto-multi v-if="!loading.getItem" ressource="smallagrregion" :title="'Petite région agricole'" :listObject.sync="autoComplete.csa_sar_id"
                :listParent.sync="autoCompleteSource.sar" 
                :fields="{ index: 'sar_id', text:'sar_name', search:'sar_name', multiSelect:false, preprendIcon:'mdi-group' }">
            </auto-multi>
        </v-flex>
        <v-flex xs5 class="pa-2">
            <auto-multi v-if="!loading.getItem" ressource="watershed" :title="'Bassin versant'" :listObject.sync="autoComplete.csa_was_id"
                :listParent.sync="autoCompleteSource.was" 
                :fields="{ index: 'was_id', text:'was_name', search:'was_name', multiSelect:false, preprendIcon:'mdi-group' }">
            </auto-multi>
        </v-flex>
        <v-flex xs4 class="pa-2">
            <v-text-field label="Superficie exigible"  type="number"  v-model="form.csa_area_due" ></v-text-field>
        </v-flex>
        <v-flex xs4 class="pa-2">
            <v-text-field label="Linéaire exigible"  type="number"  v-model="form.csa_linear_due" ></v-text-field>
        </v-flex>
        <v-flex xs4 class="pa-2">
            <v-text-field label="Pied exigible"  type="number"  v-model="form.csa_feet_due" ></v-text-field>
        </v-flex>
        </v-layout>
    </v-card>
    <v-toolbar extended flat color="transparent" class="elevation-0" >
        <v-btn color="red" @click.native="deleteItem()"><v-icon left>mdi-delete</v-icon></v-btn>
        <v-spacer></v-spacer>
        <v-btn color="success" class="mr-3" :loading="saveProgress" @click.native="saveItem()" :disabled="!validate">Enregistrer</v-btn>
        <v-btn color="primmary" href="/#/compensationsar/">Retour</v-btn>
    </v-toolbar>
</v-container>
</template>

<script>
import dateLocale from '../services/dateLocale';
import { cacheGetters } from "../store/cache.module"
import autoCompMulti    from '../components/Autocomplete_multi.vue'

export default {
    name: 'speciesbysaredit',
    props: [],
    data: function () {
        return {
            locDateFormat: dateLocale, // Format de date
            saveProgress: false,         // Enregistrement en cours...
            loading: { getItem: true },
            stateCsa : cacheGetters.getStates('GES'),
            form:{
                csa_id : 0, csa_sar_id : null, csa_was_id : null, csa_spe_id : null, csa_csa_id : null, csa_sta_id : 'GES01', 
                csa_area_due : null, csa_linear_due : null, csa_feet_due : null, csa_created_at : '', csa_updated_at : '', 
            },
            autoComplete: {
                csa_spe_id: null,
                csa_sar_id: null,
                csa_was_id: null,
            },
            autoCompleteSource:{
                spe: [],
                sar: [],
                was: []
            },
        }
    },
    computed: {
        validate () {
            if( !this.saveProgress && this.autoComplete.csa_spe_id > 0 ){
                return true
            } else {
                return false
            }
            
        },
    },
    mounted:function (){
        if( parseInt( this.$route.params.csa_id ) > 0 && this.$route.params.csa_id !== undefined ){
            this.getItem()
        } else {
            this.loading.getItem = false
        }
    },
    methods: {
        getItem() {
            this.$http.get( '/compensationsar/' + this.$route.params.csa_id + '/?include=species,smallagrregion,watershed' )
            .then( (response) => {
                var prop
                for( prop in response.data.data ){
                    if( this.form[prop] !== undefined ){
                        this.form[prop] = response.data.data[prop] 
                    }
                }
                if( response.data.data.species && typeof response.data.data.species.data === 'object' && response.data.data.species.data !== null ){
                    this.autoCompleteSource.spe.push(response.data.data.species.data)
                }
                if( response.data.data.watershed && typeof response.data.data.watershed.data === 'object' && response.data.data.watershed.data !== null ){
                    this.autoCompleteSource.was.push(response.data.data.watershed.data)
                }
                if( response.data.data.smallagrregion && typeof response.data.data.smallagrregion.data === 'object' && response.data.data.smallagrregion.data !== null ){
                    this.autoCompleteSource.sar.push(response.data.data.smallagrregion.data)
                }
                this.autoComplete.csa_spe_id = this.form.csa_spe_id
                this.autoComplete.csa_was_id = this.form.csa_was_id
                this.autoComplete.csa_sar_id = this.form.csa_sar_id
                this.loading.getItem = false
            })
            .catch( (error) => {
                this.$root.$toast({ color: 'warning', text: error })
            })
        },
        saveItem () {
            // Enregistrement du formulaire
            this.saveProgress    = true
            this.form.csa_spe_id = this.autoComplete.csa_spe_id
            this.form.csa_sar_id = this.autoComplete.csa_sar_id
            this.form.csa_was_id = this.autoComplete.csa_was_id
            if( parseInt( this.$route.params.csa_id ) > 0 ){
                this.$http.put( '/compensationsar/' + this.$route.params.csa_id, this.form )
                .then( () => {
                    this.$root.$toast({ color: 'success', text: 'Fiche enregistrée !' })
                    this.$router.push({ path: '/compensationsar' })
                }).catch( (error) => {
                    this.$root.$toast({ text: 'Erreur lors de l\'enregistrement !', error: error })
                    this.saveProgress = false
                });
            } else {
                this.$http.post( '/compensationsar/', this.form )
                .then( () => {
                    this.$root.$toast({ color: 'success', text: 'Fiche enregistrée !' })
                    this.$router.push({ path: '/compensationsar' })
                }).catch( (error) => {
                    this.$root.$toast({ text: 'Erreur lors de l\'enregistrement !', error: error })
                });
            }
        },
        deleteItem(){
            this.$root.$confirm('Supprimer', 'Confirmer la suppression de cette fiche', { color: 'red', width: 500 }).then((confirm) => {
                if( confirm ){
                    this.$http.delete( '/compensationsar/' + this.form.csa_id ).then( () => {
                        this.$root.$toast({ color: 'success', top:false, bottom:true, right:false, left:true, text:'Fiche supprimée !' })
                        this.$router.push({ path: '/compensationsar' })
                    })
                }
            })
        },
    },
    components: {
        'auto-multi': autoCompMulti
    }
}
</script>