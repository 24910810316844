<template>
<v-container fluid class="pa-0">
    <v-toolbar class="elevation-1" height="48px">
        <v-toolbar-title >Historique des actions utilisateur</v-toolbar-title>
        <v-spacer></v-spacer>
    </v-toolbar>
    <v-text-field
        append-icon="mdi-magnify" label="Recherche"
        solo clearable single-line hide-details v-model="search" @blur="saveSearchToCache()" class="ma-4 pa-0">
    </v-text-field>
    <v-card class="ma-4">
    <v-data-table v-bind:headers="headers" :items="items" item-key="sta_id" :search="search"
        items-per-page-text="Lignes par page" :items-per-page-options="pagination.rowsPerItem">
        <template v-slot:[`item.ach_typ_id`]="{ item }">
            <v-chip small outlined label :color="getCacheType('HIS', item.ach_typ_id).typ_color">{{ getCacheType('HIS', item.ach_typ_id).typ_name }}</v-chip>
        </template>
        <template v-slot:[`item.usr_middleName`]="{ item }">
            <v-chip small outlined label color="primary">{{ item.usr_middleName }}</v-chip>
        </template>
        <template v-slot:[`item.ach_table_id`]="{ item }">
            <v-btn text small v-if="tableName[item.ach_table_id]['route']" :href="'#/' + tableName[item.ach_table_id]['route'] + '/' + item.ach_xxx_id" target="new">
            {{ (tableName[item.ach_table_id]['label']) ? tableName[item.ach_table_id]['label'] : item.ach_table_id }}
            </v-btn>
            <v-btn text small v-else>
            {{ (tableName[item.ach_table_id]['label']) ? tableName[item.ach_table_id]['label'] : item.ach_table_id }}
            </v-btn>
        </template>
        <template v-slot:[`item.ach_created_at`]="{ item }">
            <v-chip outlined small label color="green" :title="'Dernière modification: ' + item.ach_created_at">
                {{locDateFormat.toLocale(item.ach_created_at, { year: 'numeric', month: 'long', day: 'numeric' })}}
            </v-chip>
        </template>
        <template v-slot:[`item.ach_id`]="{ item }">
            <v-icon v-if="item.ach_data_json" :title="item.ach_id" @click="openMerge(item)">mdi-eye-arrow-right-outline</v-icon>
        </template>
        <template v-slot:[`item.ach_xxx_id`]="{ item }">
            <v-chip v-if="tableName[item.ach_table_id]['route']" :href="'#/' + tableName[item.ach_table_id]['route'] + '/' + item.ach_xxx_id" target="new" small outlined label color="blue">
                #{{ item.ach_xxx_id }}
            </v-chip>
            <v-chip v-else small outlined label color="grey">#{{ item.ach_xxx_id }}</v-chip>
        </template>
        <template slot="pageText" slot-scope="{ pageStart, pageStop }">
        De {{ pageStart }} à {{ pageStop }}
        </template>
    </v-data-table>
    </v-card>

    <v-dialog v-model="modaldiff" max-width="900" >
        <v-card color="grey lighten-4" min-width="350px" flat >
        <v-toolbar color="primary" dark class="elevation-1" height="50px">
            <v-btn icon><v-icon>mdi-chevron-down</v-icon></v-btn>
            <v-toolbar-title>Différence</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon color="" @click.native="switchModal('modaldiff', 0)"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text class="mt-2">
            <v-layout row wrap>
            <v-flex xs6 class="pa-2">
                <v-list class="pb-0" two-line>
                    <template v-for="(item, index) in mergeData.before">
                        <v-list-item :key="index" class="pb-0">
                            <span v-if="index.includes('typ_id')">
                                {{ index }} : {{ typeState[item.substring(0, 3)][item].typ_name }}
                            </span>
                            <span v-else-if="index.includes('sta_id')">
                                {{ index }} : {{ stateState[item.substring(0, 3)][item].sta_label }}
                            </span>
                            <span v-else>
                                {{ index }} : {{ item }}
                            </span>
                        </v-list-item>
                    </template>
                </v-list>
            </v-flex>
            <v-flex xs6 class="pa-2">
                <v-list class="pb-0" two-line>
                    <template v-for="(item, index) in mergeData.after">
                        <v-list-item :key="index" class="pb-0" :style="(mergeData.before[index] && mergeData.before[index] !== item) ? 'color:red;' : ''">
                            <span v-if="index.includes('typ_id')">
                                {{ index }} : {{ typeState[item.substring(0, 3)][item].typ_name }}
                            </span>
                            <span v-else-if="index.includes('sta_id')">
                                {{ index }} : {{ stateState[item.substring(0, 3)][item].sta_label }}
                            </span>
                            <span v-else>
                                {{ index }} : {{ item }}
                            </span>
                        </v-list-item>
                    </template>
                </v-list>
            </v-flex>
            </v-layout>
        </v-card-text>
        </v-card>
    </v-dialog>
</v-container>
</template>

<script>
import dateLocale from '../services/dateLocale';
import { cacheGetters, cacheMutations } from "../store/cache.module"

export default {
    name: 'history',
    props: [],
    data: function () {
        return {
            saveProgress: false,         // Enregistrement en cours...
            search: '',
            pagination: { totalItems: 0, rowsPerPage: 10, rowsPerItem: [15,25,35,{text:'Tout',value:-1}] },
            headers: [
            { text: 'Type',            align: 'left',  value: 'ach_typ_id'},
            { text: 'Utilisateur',     align: 'left',  value: 'usr_middleName'},
            { text: 'Donée',           align: 'left',  value: 'ach_table_id'},
            { text: 'Date',            align: 'right', value: 'ach_created_at'},
            { text: 'Différentiel',    align: 'left',  value: 'ach_id'},
            { text: 'Action',          align: 'right', value: 'ach_xxx_id'},
            ],
            items: [],
            locDateFormat: dateLocale,
            tableName: { 
                act: {label: 'action',              dialog: false, route: '', endpoint: ''},
                chh: {label: 'habitat',             dialog: false, route: 'habitats', endpoint: 'habitats'},
                cha: {label: 'compensation habitat',dialog: true, route: '', endpoint: 'compensationhabitat'},
                chd: {label: 'compensation habitat',dialog: false, route: '', endpoint: 'compensationhabitatdet'},
                csa: {label: 'compensation pra',    dialog: false, route: '', endpoint: 'compensationsar'},
                csp: {label: 'compensation espèce', dialog: false, route: '', endpoint: 'compensationspecies'},
                cwa: {label: 'compensation eau',    dialog: false, route: '', endpoint: ''},
                ctt: {label: 'contact',             dialog: false, route: 'contacts', endpoint: 'contacts'},
                dia: {label: 'diagnostique',        dialog: false, route: '', endpoint: ''},
                doc: {label: 'document',            dialog: false, route: '', endpoint: ''},
                eve: {label: 'évènement',           dialog: false, route: '', endpoint: 'events'},
                ini: {label: 'Impact initial',      dialog: false, route: '', endpoint: ''},
                inv: {label: 'Facture',             dialog: false, route: '', endpoint: ''},
                lay: {label: 'Layer',               dialog: false, route: '', endpoint: ''},
                sit: {label: 'Site',                dialog: false, route: '', endpoint: ''},
                spf: {label: 'Cahier des charges',  dialog: false, route: '', endpoint: ''},
                tcv: {label: 'Convention',          dialog: false, route: '', endpoint: 'trackingconvention'},
                trp: {label: 'Parcelle',            dialog: false, route: '', endpoint: ''},
                tsa: {label: 'Compensation PRA',    dialog: false, route: '', endpoint: ''},
                trs: {label: 'Fiche site',          dialog: false, route: '', endpoint: ''},
                tst: {label: 'Info site',           dialog: false, route: '', endpoint: ''},
                tzo: {label: 'Zone',                dialog: false, route: '', endpoint: ''},
                usr: {label: 'Utilisateur',         dialog: false, route: '', endpoint: ''},
                wam: {label: 'Masse d eau',         dialog: false, route: '', endpoint: ''},
                sar: {label: 'PRA',                 dialog: false, route: '', endpoint: 'smallagrregion'},
                spe: {label: 'Espèces',             dialog: false, route: '', endpoint: ''},
                gdo: {label: 'Gan document',        dialog: false, route: '', endpoint: ''},
                gpo: {label: 'Gan Parcelle',        dialog: false, route: '', endpoint: ''},
            },
            mergeData: { before: {}, after:{} },
            enableAdd: true,
            modalEvent : false,
            modaldiff  : false,
            typeState: cacheGetters.getTypes(),
            stateState: cacheGetters.getStates(),
        }
    },
    computed: {
        pages () {
            if (this.pagination.rowsPerPage == null || this.pagination.totalItems == null ) return 0
            return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
        }
    },
    mounted: function (){
        this.search = cacheGetters.getSearchBarItem('history') || ''
        this.getItems()
    },
    methods: {
        getItems(){
            this.$http.get( '/actionhistory/?per_page=false&extended=withuser&order_by=ach_id&order_direction=desc' ).then( (response) => {
                this.items = response.data.data
            })
        },
        handlePage(index){
            this.pagination.limit = this.pagination.rowsPerPage * (index - 1)
        },
        saveSearchToCache(){
            cacheMutations.setSearchBarItem('history', this.search)
        },
        switchModal(name, mode){
            name    = name || 'modalDiff'
            mode    = mode || 0
            this[name] = mode
        },
        openMerge(item){
            this.mergeData.after  = {}
            this.mergeData.before = {}
            this.mergeData.before = JSON.parse(item.ach_data_json)
            if( this.tableName[item.ach_table_id]['endpoint'].length ){
                this.$http.get( '/' + this.tableName[item.ach_table_id]['endpoint'] + '/' + item.ach_xxx_id ).then( (response) => {
                    this.mergeData.after = response.data.data
                })
            }
            this.modaldiff = true
        },
        getCacheType: cacheGetters.getTypes,
        getCacheState: cacheGetters.getStates
    },
    components: {
        
    }
}
</script>