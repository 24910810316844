<template>
<v-card color="grey lighten-4" flat>
    <v-toolbar color="primary" dark class="elevation-2" height="50px">
        <v-icon class="mr-3">mdi-chevron-down</v-icon>
        <v-toolbar-title>Génération de factures</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon color="" @click.native="switchModal('-', 0)"><v-icon>mdi-close</v-icon></v-btn>
    </v-toolbar>
    <v-card-text>
        <v-form ref="userForm" v-model="validForm" lazy-validation>
            <v-layout row wrap>
            <v-flex xs12 class="pa-2">
                <!-- <auto-multi v-if="!loading.getItem" :title="'Code cahier des charges'" :ressource="false" :listObject.sync="autoComplete.spf_cdr_id"
                    :listParent.sync="autoCompleteSource.cdr" :fields="{ index: 'cdr_id', text:'cdr_code', search:'cdr_code', multiSelect:true }"
                    class="ml-2 mr-2">
                </auto-multi> -->
                <auto-multi v-if="!loading.getItem" :title="'Convention'" ressource="trackingconvention" :listObject.sync="autoComplete.inv_tcv_id"
                    :listParent.sync="autoCompleteSource.tcv" 
                    :fields="conventionFields">
                </auto-multi>
            </v-flex>
            <v-flex xs6 class="pa-2">
                <v-menu ref="datestart" v-model="dateMenu.datestart"
                    :return-value.sync="genSelector.date_start"
                    :close-on-content-click="false" :nudge-right="40" transition="scale-transition" 
                    offset-y min-width="290px" style="z-index:1500">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field 
                            v-bind="attrs" :value="locDateFormat.formatLocale( genSelector.date_start, 'fr' )"
                            label="Date début" prepend-icon="mdi-calendar-today" readonly v-on="on" >
                        </v-text-field>
                    </template>
                    <v-date-picker locale="fr-fr" no-title scrollable v-model="genSelector.date_start">
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="dateMenu.datestart = false">Annuler</v-btn>
                        <v-btn color="primary" @click="$refs.datestart.save( genSelector.date_start )">OK</v-btn>
                    </v-date-picker>
                </v-menu>
            </v-flex>
            <v-flex xs6 class="pa-2">
                <v-menu ref="dateexpiration" v-model="dateMenu.dateexpiration"
                    :return-value.sync="genSelector.date_end"
                    :close-on-content-click="false" :nudge-right="40" transition="scale-transition" 
                    offset-y min-width="290px" style="z-index:1500">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field 
                            v-bind="attrs" :value="locDateFormat.formatLocale( genSelector.date_end, 'fr' )"
                            label="Date fin" prepend-icon="mdi-calendar-today" readonly v-on="on" >
                        </v-text-field>
                    </template>
                    <v-date-picker locale="fr-fr" no-title scrollable v-model="genSelector.date_end">
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="dateMenu.dateexpiration = false">Annuler</v-btn>
                        <v-btn color="primary" @click="$refs.dateexpiration.save( genSelector.date_end )">OK</v-btn>
                    </v-date-picker>
                </v-menu>
            </v-flex>
            </v-layout>
        </v-form>
    </v-card-text>
    <!-- <event-tab :table_id="'adu'" :usr_type="'control'" ></event-tab> -->
    <v-card-actions style="background-color:white;">
        <v-checkbox :true-value="1" :false-value="0" v-model="form.generate" label="Générer directement"></v-checkbox>
        <v-spacer></v-spacer>
        <v-btn color="success" :loading="saveProgress" @click.native="saveForm()" :disabled="false">Traiement</v-btn>
        <v-btn color="primmary" @click.native="switchModal('-', 0)">Retour</v-btn>
    </v-card-actions>
</v-card>
</template>

<script>
import dateLocale from '../services/dateLocale';
import { cacheGetters } from "../store/cache.module"
import autoCompMulti    from './Autocomplete_multi.vue'

export default {
    name: 'edit_specification',
    props: ['switchModal', 'genInvoice', 'genSelector'],
    data: function () {
        return {
            dateMenu : {},
            saveProgress: false,         // Enregistrement en cours...
            loading: { getItem: false },
            validForm   : false,
            typeSpec: cacheGetters.getTypes('SPM'),
            objSpecfication: {
                
            },
            form:{
                generate: 0
            },
            nameRules: [
                v => !!v || 'Ce champ est obligatoire',
                v => v.length <= 250 || 'Le champ doit avoir moins de 255 charactères'
            ],
            locDateFormat: dateLocale, // Format de date
            autoComplete: {
                spf_cdr_id: [],
                inv_tcv_id: [],
            },
            autoCompleteSource:{
                cdr: cacheGetters.getCoderef(),
                tcv: [],
            },
            conventionFields: { 
                index: 'tcv_id', text: 'tcv_code,trs_coddt,ctt_middlename_contact', search:'tcv_code', 
                multiSelect:true, autoselectfirst: true, params: 'extended=withcontact,withtrackingsheet'
            }
        }
    },
    mounted:function (){

    },
    methods: {
        saveForm() {
            var filter = {
                inv_tcv_id     : this.autoComplete.inv_tcv_id,
                spf_date_start : this.genSelector.date_start,
                spf_date_end   : this.genSelector.date_end,

            }
            this.genInvoice( filter )
        },
    },
    components: {
        'auto-multi': autoCompMulti
    }
}
</script>